import { useSanityContentLocalizer } from '@ssfrepo/ssf-react-components';
import {
  TopicLinksBigLinkStyles,
  TopicLinksRichTextStyles,
} from '/src/components/content/big-links/topic-links';
import { MaxWidthSection } from '/src/components/styled/max-width-section';
import React, { createContext, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTemplateAgnosticCache } from './use-template-agnostic-cache';
import {
  triggerAnalyticsEvent,
  APP_INSTALLATION_ACCEPTED,
  APP_INSTALLATION_DISMISSED,
} from '../utils/analytics-utils';

export interface AppInstallationContextType {
  deferredPrompt?: any;
  isPromptPresentable: boolean;
  dismiss?: () => void;
}

export const AppInstallationContext = createContext<AppInstallationContextType>(
  { isPromptPresentable: false }
);

export const AppInstallationContextProvider = ({ children }) => {
  const [appInstallState, setAppInstallState] =
    useState<AppInstallationContextType>({
      isPromptPresentable: false,
    });

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }

    const func = (event) => {
      event.preventDefault();
      setAppInstallState({
        deferredPrompt: event,
        isPromptPresentable: true,
        dismiss: () =>
          setAppInstallState((_e) => ({
            deferredPrompt: _e?.deferredPrompt,
            isPromptPresentable: false,
          })),
      });
    };

    window.addEventListener('beforeinstallprompt', func);

    return () => window.removeEventListener('beforeinstallprompt', func);
  }, []);

  return (
    <AppInstallationContext.Provider value={appInstallState}>
      {children}
    </AppInstallationContext.Provider>
  );
};

const useAppInstallationContext = () => useContext(AppInstallationContext);

const InstallButton = styled.button`
  border: none;
  cursor: pointer;
  text-decoration: none;
  box-sizing: border-box;
  outline: none;
  ${TopicLinksBigLinkStyles}
  width: 100%;
`;

const AppImage = styled.img`
  border-radius: 20px;
  height: 80px;
`;

const TextContainer = styled.div`
  ${TopicLinksRichTextStyles}
`;
export const AppInstallationSection = () => {
  const appInstall = useAppInstallationContext();
  const localize = useSanityContentLocalizer();
  const cache = useTemplateAgnosticCache();
  const { isPromptPresentable, deferredPrompt, dismiss } = appInstall;
  if (!isPromptPresentable) {
    return null;
  }

  return (
    <MaxWidthSection>
      <InstallButton
        onClick={() => {
          deferredPrompt?.prompt();
          deferredPrompt?.userChoice.then((choiceResult) => {
            if (choiceResult.outcome === 'accepted') {
              triggerAnalyticsEvent(APP_INSTALLATION_ACCEPTED);
            } else {
              triggerAnalyticsEvent(APP_INSTALLATION_DISMISSED);
            }
            dismiss?.();
          });
        }}
        $styleVariant={'topicLinks'}
      >
        <AppImage src={'/images/App_icon_192x192.png'} />
        <TextContainer $styleVariant={'topicLinks'}>
          <h3>{localize(cache.translate('appInstall.header'))}</h3>
          <span>{localize(cache.translate('appInstall.text'))}</span>
        </TextContainer>
      </InstallButton>
    </MaxWidthSection>
  );
};
