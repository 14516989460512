import React, { useMemo } from 'react';
import {
  BlockContentOptionsProvider,
  BlockContentSerializerProvider,
  useLanguage,
} from '@ssfrepo/ssf-react-components';
import { useSiteMetaData } from '/src/hooks/use-site-metadata';
import { defaultGatsbySerializers } from './block-content-wrapper';

export const GatsbyBlockContentProviders = ({ children }) => {
  const siteMetadata = useSiteMetaData();
  const [language] = useLanguage();

  const options = useMemo(() => {
    return {
      config: siteMetadata,
      language,
      prependOnlineBankHostnameInLink: siteMetadata.onlineBankUrl,
      useDashedStyles: true,
    };
  }, [siteMetadata, language]);

  return (
    <BlockContentOptionsProvider options={options}>
      <BlockContentSerializerProvider serializers={defaultGatsbySerializers}>
        {children}
      </BlockContentSerializerProvider>
    </BlockContentOptionsProvider>
  );
};
