import {
  isWeekend,
  add,
  parseISO,
  isBefore,
  isAfter,
  set,
  isEqual,
} from 'date-fns';
import { SanityScheduledPieceSectionItem } from '/src/types/sanity/content/pieces/scheduled-piece-section';

const satisfiesRepeat = (
  item: SanityScheduledPieceSectionItem,
  d: Date
): boolean => {
  const _isWeekend = isWeekend(d);
  if (item.repeatDays === 'weekends') {
    return _isWeekend;
  }
  if (item.repeatDays === 'weekDays') {
    return !_isWeekend;
  }
  return true;
};

const hoursStringSplit = (s: string): number[] => {
  return s.split(':').map((hm) => {
    let n: number;
    try {
      n = Number(hm);
    } catch (e) {
      return 0;
    }
    return n;
  });
};

export const showScheduledItem = (
  currentTimeTZ: Date,
  item: SanityScheduledPieceSectionItem
): boolean => {
  const offSetHours = currentTimeTZ.getTimezoneOffset() / 60;
  const currentUTC = add(currentTimeTZ, { hours: offSetHours });
  const { startingHours, endingHours, endDate, startDate } = item;

  // Start and end dates are at 4 hours after the designated UTC midnight, so it is consistent across timezones
  if (startDate) {
    const startUTC = parseISO(startDate);
    const startUTC4 = add(startUTC, { hours: 4 });
    if (isEqual(currentUTC, startUTC4) || isBefore(currentUTC, startUTC4)) {
      return false;
    }
  }

  if (endDate) {
    const endsUTC = parseISO(endDate);
    const endsUTC4 = add(endsUTC, { hours: 4 });
    if (isAfter(currentUTC, endsUTC4)) {
      return false;
    }
  }
  const _satisfiesRepeat = satisfiesRepeat(item, currentTimeTZ);
  const [startH, startMin] = hoursStringSplit(startingHours);
  const startingTodayUTC = add(
    set(currentTimeTZ, { hours: startH, minutes: startMin }),
    {
      hours: offSetHours,
    }
  );

  const [endH, endMin] = hoursStringSplit(endingHours);
  let endTodayTZ = set(currentTimeTZ, { hours: endH, minutes: endMin });
  if (endH === 0) {
    endTodayTZ = add(endTodayTZ, { days: 1 });
  }
  const endTodayUTC = add(endTodayTZ, { hours: offSetHours });

  return (
    (isEqual(currentUTC, startingTodayUTC) ||
      isAfter(currentUTC, startingTodayUTC)) &&
    isBefore(currentUTC, endTodayUTC) &&
    _satisfiesRepeat
  );
};
