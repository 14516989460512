import { PieceSectionClusterTheme } from 'styled-components';
import { COLORS } from '@ssfrepo/ssf-react-components';

export const darkPieceSectionClusterThemeImpl: PieceSectionClusterTheme = {
  alternateWhiteBg: COLORS.ssfBlue900,
  alternateSandyBg: COLORS.ssfBlue900,
  alternateGreenBg: COLORS.kongleriketDarkGreen,
  alternateBlueBg: COLORS.ssfBlue100,
  pieceSectionWhiteBg: COLORS.ssfBlue900,
  pieceSectionSandyBg: COLORS.ssfBlue900,
  pieceSectionGreenBg: COLORS.kongleriketDarkGreen,
  pieceSectionBlueBg: COLORS.ssfBlue100,
};
