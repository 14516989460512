import React, { useState } from 'react';
import styled from 'styled-components';
import Menu from '../menu/menu';
import OpenMenuButton from '../menu/open-menu-button/open-menu-button';
import { useOnClickOutside } from '@ssfrepo/ssf-react-components';
import {
  TOP_BAR_HEIGHT,
  MAIN_MAX_WIDTH,
  MAIN_PADDING,
  mediaBreakPoints,
} from '/src/sass/styled-constants';
import { useSanityContentLocalizer } from '@ssfrepo/ssf-react-components';
import {
  LocationType,
  useLocationType,
} from '../../../hooks/use-location-type';
import { useTemplateAgnosticCache } from '/src/hooks/use-template-agnostic-cache';
import ConditionalLink from '/src/components/core/conditional-link';
import SearchIcon from '../../../domains/search/components/search-icon';
import MarketLinks from '../menu/market-links';
import { TopBarButtons } from './topbar-buttons';

const LogoImage = styled.img`
  width: 176px;
  height: 45px;
  @media (max-width: ${mediaBreakPoints.md}) {
    width: 146px;
  }
  @media (max-width: ${mediaBreakPoints.iPhonePlus}) {
    width: 107px;
  }
  content: var(--common-layout-logo-image);
`;

const TopBarWrappper = styled.header`
  background-color: var(--common-layout-topbar-bg);
  height: ${TOP_BAR_HEIGHT}px;
  z-index: 10;
  display: flex;
  justify-content: center;
  box-shadow: var(--common-layout-topbar-box-shadow);
  border-bottom: var(--common-layout-topbar-border-bottom);
`;

const TopBarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding: 0 ${MAIN_PADDING};
  flex-grow: 1;
  max-width: ${MAIN_MAX_WIDTH};
`;

const TopBarLeft = styled.div`
  display: flex;
  align-items: center;
  flex: 1 0 0;
`;

const TopBarRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1 0 0;
`;

const MenuWrapper = styled.div`
  margin-right: 20px;
  @media (max-width: ${mediaBreakPoints.sideMenu}) {
    margin: 0;
  }
`;

const StyledMarketLinks = styled(MarketLinks)`
  @media (max-width: ${mediaBreakPoints.sideMenu}) {
    display: none !important;
  }
`;

const StyledSearchTopBar = styled(SearchIcon)`
  @media (max-width: ${mediaBreakPoints.sideMenu}) {
    display: none !important;
  }
`;

const MenuOverlay = styled.div`
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.5);
`;

export const TopBar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const localize = useSanityContentLocalizer();
  const customerType = useLocationType();
  const cache = useTemplateAgnosticCache();

  const menuWrapperElementRef = useOnClickOutside(() => {
    setIsMenuOpen(false);
  }, []);
  const topBar = cache.topBar();
  const singletonMenugroupings = cache.menuGroupings();

  if (!topBar || !singletonMenugroupings) {
    return null;
  }
  const onNavigation = () => {
    setIsMenuOpen((_visibility) => !_visibility);
  };
  const { privateBankingRoute, businessBankingRoute } = topBar;

  const currentContext =
    customerType === LocationType.Private
      ? privateBankingRoute
      : businessBankingRoute;

  const menuGroupings =
    customerType === LocationType.Private
      ? singletonMenugroupings.privateMenuGroupings
      : singletonMenugroupings.businessMenuGroupings;

  return (
    <TopBarWrappper>
      <TopBarContainer>
        <TopBarLeft>
          {isMenuOpen && <MenuOverlay />}
          <MenuWrapper ref={menuWrapperElementRef}>
            <OpenMenuButton
              isMenuOpen={isMenuOpen}
              menuLabel={localize(cache.translate('menu.menuLabel'))}
              onClick={onNavigation}
            />
            {isMenuOpen && (
              <Menu
                onNavigation={onNavigation}
                menuGroupings={menuGroupings}
                topBar={topBar}
              />
            )}
          </MenuWrapper>
          <StyledMarketLinks topBar={topBar} styleType={'topbar'} />
        </TopBarLeft>
        <ConditionalLink link={currentContext}>
          <LogoImage alt="Logo" />
        </ConditionalLink>
        <TopBarRight>
          <StyledSearchTopBar asModalButton />
          <TopBarButtons topBar={topBar} />
        </TopBarRight>
      </TopBarContainer>
    </TopBarWrappper>
  );
};
