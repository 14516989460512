import React, { createContext, useContext, useState } from 'react';
import get from 'lodash.get';

export type LayoutVariant = 'standard' | 'crystal' | 'profileManual';

type LayoutVariantKV = {
  [key: string]: LayoutVariant;
};

export type LayoutVariantTuple = [
  LayoutVariant,
  (layoutVariant: LayoutVariant) => void
];

export const LayoutVariantContext = createContext<LayoutVariantTuple>([
  'standard',
  (v) => {
    return;
  },
]);

const onChange =
  (
    variantMap: LayoutVariantKV,
    setVariantMap: React.Dispatch<React.SetStateAction<LayoutVariantKV>>,
    path: string
  ) =>
  (layoutVariant: LayoutVariant) => {
    setVariantMap({ ...variantMap, [path]: layoutVariant });
  };

export const LayoutVariantProvider = ({ location, children }) => {
  const [variantMap, setVariantMap] = useState<LayoutVariantKV>({});
  const path = get(location, 'pathname', '/');
  const variant: LayoutVariant = get(variantMap, `${path}`, 'standard');

  return (
    <LayoutVariantContext.Provider
      value={[variant, onChange(variantMap, setVariantMap, path)]}
    >
      {children}
    </LayoutVariantContext.Provider>
  );
};

export const useLayoutVariant = () => useContext(LayoutVariantContext);
