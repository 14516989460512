import React from 'react';
import { useLanguage } from '@ssfrepo/ssf-react-components';
import {
  formatAnnuityLoanPrice,
  formatAccountPrice,
  formatCreditCardPrice,
  formatAnnuityLoanCostOptionsPrice,
  SanityProduct,
} from '@ssfrepo/ssf-sanity-utils';

type Props = {
  className?: string | undefined;
  product?: SanityProduct | undefined;
};

const PriceLine = (props: Props) => {
  const { product, className } = props;
  if (!product) {
    return <span className={className}>--</span>;
  }

  const [language] = useLanguage();
  let price = '--';

  switch (product.priceType) {
    case 'annuityLoanPrice':
      price = formatAnnuityLoanPrice(product, language);
      break;
    case 'accountPrice':
      price = formatAccountPrice(product, language);
      break;
    case 'creditCardPrice':
      price = formatCreditCardPrice(product, language);
      break;
    case 'annuityLoanCostOptionsPrice':
      price = formatAnnuityLoanCostOptionsPrice(product, language);
      break;
    default:
      break;
  }

  return <span className={className}>{price}</span>;
};

export default PriceLine;
