import { COLORS } from '@ssfrepo/ssf-react-components';
import { css } from 'styled-components';
import { LocationType } from '/src/hooks/use-location-type';

export const SSFScroll = css`
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 11px;
  }
  -webkit-overflow-scrolling: auto;
  scrollbar-width: thin;
  scrollbar-color: ${(p) => p.theme.ssfScroll.fg} ${(p) => p.theme.ssfScroll.bg};

  ::-webkit-scrollbar-track {
    background: ${(p) => p.theme.ssfScroll.bgTrack};
    border: 2px solid ${(p) => p.theme.ssfScroll.border};
    border-radius: 8px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${(p) => p.theme.ssfScroll.bgThumb};
    border-radius: 8px;
    border: 2px solid ${(p) => p.theme.ssfScroll.border};
  }
`;

export const SSFScrollSecondary = css`
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 11px;
  }
  -webkit-overflow-scrolling: auto;
  scrollbar-width: thin;
  scrollbar-color: ${(p) => p.theme.ssfScroll.fg} ${(p) => p.theme.ssfScroll.bg};

  ::-webkit-scrollbar-track {
    background: transparent;
    border: 2px solid transparent;
    border-radius: 8px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${(p) => p.theme.ssfScroll.bgTrack};
    border-radius: 8px;
    border: 2px solid ${(p) => p.theme.ssfScroll.border};
  }
`;

export const SSFScrollTertiary = css`
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 11px;
  }
  -webkit-overflow-scrolling: auto;
  scrollbar-width: thin;

  ::-webkit-scrollbar-track {
    border: 2px solid ${(p) => p.theme.ssfScrollTertiary.border};
    background: ${(p) => p.theme.ssfScrollTertiary.bg};
    border-radius: 8px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${(p) => p.theme.ssfScrollTertiary.bgTrack};
    border-radius: 8px;
    border: 2px solid ${(p) => p.theme.ssfScrollTertiary.border};
  }
`;

export const SSFScrollProfileManual = css<{ $pmType }>`
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 11px;
  }
  -webkit-overflow-scrolling: auto;
  scrollbar-width: thin;

  ::-webkit-scrollbar-track {
    background: ${COLORS.ssfWhite};
    border-radius: 8px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid ${COLORS.ssfWhite};
    ${(props) =>
      (props.$pmType === LocationType.Ssf &&
        `background-color: ${COLORS.ssfBlue400};`) ||
      (props.$pmType === LocationType.Kongleriket &&
        `background-color: ${COLORS.kongleriketMiddleGreen}`) ||
      (props.$pmType === LocationType.Sparebankstiftinga &&
        `background-color: ${COLORS.kongleriketMiddleGreen}`) ||
      (props.$pmType === LocationType.Eigedomsmekling &&
        `background-color: ${COLORS.ssfRedBRonse}`)}
  }
`;
