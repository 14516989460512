import React, { useEffect, useState } from 'react';
import { mediaBreakPoints } from '@ssfrepo/ssf-react-components';
import styled from 'styled-components';
import { shadows } from '/src/sass/styled-constants';
import FontIcon from '@ssfrepo/ssf-react-components/lib/buttons/font-icon';
import { showScheduledItem } from '/src/components/content/pieces/schedule-piece';
import { map, timer } from 'rxjs';
import { useLocationType } from '/src/hooks/use-location-type';
import { useTemplateAgnosticCache } from '/src/hooks/use-template-agnostic-cache';
import ConditionalLink from '/src/components/core/conditional-link';
import { SanityModal } from '/src/types/sanity/core/sanity-modal';

const ChatButton = styled(ConditionalLink)`
  display: flex;
  position: fixed;
  bottom: 50px;
  right: 30px;
  justify-content: center;
  align-items: center;
  height: 70px;
  width: 70px;
  background-color: ${({ theme }) => theme.sideButton.bg};
  border: 2px solid ${({ theme }) => theme.sideButton.border};
  border-radius: 50%;
  cursor: pointer;
  box-shadow: ${shadows.ssfButtonShadow};

  @media (max-width: ${mediaBreakPoints.sm}) {
    height: 50px;
    width: 50px;
    bottom: 20px;
    right: 20px;
  }
`;

const ChatButtonIcon = styled(FontIcon)`
  color: ${({ theme }) => theme.sideButton.fg};
  font-size: 50px;

  @media (max-width: ${mediaBreakPoints.sm}) {
    font-size: 35px;
  }
`;

type Props = {
  className?: string;
};

/**
 * The chat component.
 * If you wish to open chat from elsewhere, send the ssfChatOpen event on window.
 * */
const Chatbutton = (props: Props) => {
  const locationType = useLocationType();
  const [modal, setModal] = useState<SanityModal | undefined>();
  const cache = useTemplateAgnosticCache();

  useEffect(() => {
    const sub = timer(25, 10000)
      .pipe(
        map(() => {
          const customerSegment =
            locationType === 'privat'
              ? cache.Chatbutton()?.chatModalLinksPm
              : cache.Chatbutton()?.chatModalLinksBm;
          const currentTime = new Date();

          const scheduledModal = customerSegment?.find((s) => {
            return showScheduledItem(currentTime, s);
          });
          return scheduledModal?.modalLinks;
        })
      )
      .subscribe((_modal) => {
        setModal(_modal);
      });
    return () => sub.unsubscribe();
  }, [cache, locationType]);

  return (
    <>
      {modal && (
        <ChatButton modal={modal}>
          <ChatButtonIcon iconClassName={'ssf-comment_chat_message'} />
        </ChatButton>
      )}
    </>
  );
};

export default Chatbutton;
