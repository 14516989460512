import React, { useEffect, useState } from 'react';
import { useLocationType } from '/src/hooks/use-location-type';
import ChatPuzzle from './chat-puzzle';

const scripts = [
  'https://chat.puzzel.com/Content/Client/js/jquery-latest.min.js',
  'https://chat.puzzel.com/Content/Client/js/jquery-intelecomchat.libs.latest.min.js',
  'https://chat.puzzel.com/Content/Client/js/jquery-intelecomchat.latest.min.js',
];

// HACK: Iteratively loading the puzzle scripts, coz they are sensitive individuals that need each other.
const loadScript = (src: string): Promise<true> => {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = src;
    script.addEventListener('load', function handler() {
      this.removeEventListener('load', handler);
      resolve(true);
    });
    document.head.appendChild(script);
  });
};

type Props = {
  isChatOpen: boolean;
  onClose: () => void;
};

const ChatLoader = (props: Props) => {
  const { isChatOpen, onClose } = props;
  const [isScriptLoaded, setIsScriptLoaded] = useState<boolean>(false);
  useEffect(() => {
    loadScript(scripts[0])
      .then((y) => {
        return loadScript(scripts[1]);
      })
      .then((y) => {
        return loadScript(scripts[2]);
      })
      .then((y) => {
        setIsScriptLoaded(y);
      });
  }, []);

  const customerType = useLocationType();
  const [variables, setVariables] = useState<any>(null);
  useEffect(() => {
    setVariables({
      filter: 'Filter.produksjon', // Value must start with "Filter."
      url: window.location.href,
      customerType: customerType.toString(),
    });
  }, [customerType]);

  if (isScriptLoaded && variables) {
    return (
      <>
        <script
          rel='stylesheet'
          src='https://chat.puzzel.com/Content/Client/css/intelecom-light.css'
        />
        <ChatPuzzle
          variables={variables}
          onClose={onClose}
          isChatOpen={isChatOpen}
        />
      </>
    );
  }

  return null;
};

export default ChatLoader;
