import React from 'react';
import styled from 'styled-components';
import 'plyr/dist/plyr.css';
import SanityGatsbyImage from '/src/components/core/images/sanity-gatsby-image';
import { MediaProps } from '../media/media';
import { COLORS, mediaBreakPoints } from '/src/sass/styled-constants';
import {
  useSanityContentLocalizer,
} from '@ssfrepo/ssf-react-components';
const Plyr = React.lazy(() => import('plyr-react'));

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${COLORS.kongleriketDarkGreen};
  padding-bottom: 20px;
`;

const DisplayImage = styled(SanityGatsbyImage)`
  max-width: 940px;
  height: auto;

  border: 2px;
  border-radius: 8px 8px 8px 8px;
`;

const Title = styled.p`
  color: ${({ theme }) => theme.blockContent.header}
  text-align: center;
  font-size: 24px;
`;

const SETTINGS = {
  controls: [
    'play-large', // The large play button in the center
    //'restart', // Restart playback
    'rewind', // Rewind by the seek time (default 10 seconds)
    'play', // Play/pause playback
    'fast-forward', // Fast forward by the seek time (default 10 seconds)
    'progress', // The progress bar and scrubber for playback and buffering
    'current-time', // The current time of playback
    'duration', // The full duration of the media
    'mute', // Toggle mute
    'volume', // Volume control
    //'captions', // Toggle captions
    //'settings', // Settings menu
    //'pip', // Picture-in-picture (currently Safari only)
    //'airplay', // Airplay (currently Safari only)
    //'download', // Show a download button with a link to either the current source or a custom URL you specify in your options
    // 'fullscreen', // Toggle fullscreen
  ],
};

const Wrapper = styled.div`
  .plyr {
    border-radius: 8px;
    --plyr-color-main: ${({ theme }) =>
    theme.audioPlayer.progressBg}; //volume and progress bar?

    --plyr-control-icon-size: 30px; //size of icons used in the control (default:18px)
    --plyr-control-spacing: 20px; //The padding inside controls (default 10px, can can be used in a multiple: 10px / 2 = 5px)

    --plyr-audio-controls-background: ${({ theme }) => theme.audioPlayer.bg};
    --plyr-audio-control-color: ${({ theme }) =>
    theme.audioPlayer
      .controlFg}; //ALSO targets text color, maybe change text with specific css?
    --plyr-audio-control-color-hover: ${({ theme }) =>
    theme.audioPlayer.controlFg};
    --plyr-audio-control-background-hover: ${({ theme }) =>
    theme.audioPlayer.bg}; //button wrapper hover
    --plyr-audio-progress-buffered-background: ${({ theme }) =>
    theme.audioPlayer.progressBg}; //slider background
    --plyr-range-thumb-background: ${({ theme }) =>
    theme.audioPlayer.controlFg}; //color of circle inside slider
    --plyr-range-thumb-height: 15px; //circle inside slider

    @media (max-width: ${mediaBreakPoints.sm}) {
      --plyr-control-icon-size: 20px;
      --plyr-control-spacing: 10px;
    }
  }
`;

const AudioPlayer = (props: MediaProps) => {
  const { media } = props;
  const { rawFile } = media;

  const localize = useSanityContentLocalizer();

  const sources: Plyr.Source[] = rawFile
    ? [{ src: rawFile.asset.url, type: 'audio/mp3', provider: 'html5' }]
    : [];

  if (!media) {
    return null;
  }

  return (
    <Container>
      <DisplayImage imageExtras={media.displayImage}></DisplayImage>
      <Title>{localize(media.audioTitle)}</Title>
      <Wrapper>
        <Plyr
          source={{
            type: 'audio',
            sources: sources,
          }}
          options={SETTINGS}
        ></Plyr>
      </Wrapper>
    </Container>
  );
};

export default AudioPlayer;
