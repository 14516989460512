import React from 'react';
import { BigLinkIcon, BigLinkSetContainer, HeaderStyles } from './styled';
import {
  fontFamilies,
  fontHeaderThreeish,
  fontIngressAsRegular,
} from '/src/sass/typography/typography-styled';
import { useSanityContentLocalizer } from '@ssfrepo/ssf-react-components';
import BlockContentWrapper from '/src/components/core/block-content/block-content-wrapper';
import ConditionalLink from '/src/components/core/conditional-link';
import { shadows } from '/src/sass/styled-constants';
import styled, { css } from 'styled-components';
import {
  SanityBigLinkSet,
  SanityBigLinkSetLayout,
} from '/src/types/sanity/core/sanity-big-link';
import { getMappedComponentKey } from '/src/utils/gatsby-utils';
import {
  BIG_LINK_TOPIC_LINK,
  triggerAnalyticsEvent,
} from '/src/utils/analytics-utils';

const TopicLinksHeaderStyles = css<{
  $styleVariant: SanityBigLinkSetLayout;
}>`
  font-family: ${fontFamilies.medium};
  font-size: 28px;
  line-height: 28px;
  ${fontHeaderThreeish}
  ${HeaderStyles}
  text-align: start;
  margin: 0;
`;

const BigLinksContainer = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  width: 100%;
`;

export const TopicLinksBigLinkStyles = css<{
  $styleVariant: SanityBigLinkSetLayout;
  $deactivated?: boolean;
}>`
  position: relative;
  display: flex;
  background-color: ${(p) => p.theme.bigLink[p.$styleVariant].bg};
  border-radius: 8px;
  text-decoration: none;
  text-align: start;
  box-sizing: border-box;
  justify-content: flex-start;
  flex-direction: row;
  padding: 15px;
  box-shadow: ${shadows.ssfVerticalBigLinkProductShadow};
  transition: all 0.2s ease;

  ${(p) =>
    // eslint-disable-next-line quotes
    p.$deactivated ? `filter: grayscale(100%); pointer-events: none;` : ``}

  :not(:last-child) {
    margin-bottom: 20px;
  }

  &:before {
    content: '';
    position: absolute;
    top: -2px;
    left: -3px;
    right: -3px;
    bottom: -3px;
    border-radius: 13px;
    border: 2px solid ${(p) => p.theme.bigLink[p.$styleVariant].border};
    transition: border-color 0.3s ease;
  }

  &:hover:before {
    border: 3px solid ${(p) => p.theme.bigLink[p.$styleVariant].hover};
  }
`;

export const TopicLinksRichTextStyles = css<{
  $styleVariant: SanityBigLinkSetLayout;
}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  color: ${(p) => p.theme.bigLink[p.$styleVariant].label};
  margin-left: 30px;
  margin-right: 10px;
  h1,
  h2,
  h3,
  h4 {
    ${TopicLinksHeaderStyles};
  }
  div.block-paragraph {
    margin: 0;
    ${fontIngressAsRegular}
  }
`;

export const BigLinkRichText = styled(BlockContentWrapper)`
  .block-container {
    height: 100%;
    ${TopicLinksRichTextStyles}
  }
`;
const TopicLinksBigLink = styled(ConditionalLink)<{
  $styleVariant: SanityBigLinkSetLayout;
  $deactivated: boolean;
}>`
  ${TopicLinksBigLinkStyles}
`;

const BigLinkFontIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledBigLinkIcon = styled(BigLinkIcon)`
  margin-left: 15px;
`;

type Props = {
  bigLinkSet: SanityBigLinkSet;
  className?: string;
};

const TopicLinksBigLinkSet = (props: Props) => {
  const { bigLinkSet, className } = props;
  const { bigLinks } = bigLinkSet;
  const localize = useSanityContentLocalizer();

  return (
    <BigLinkSetContainer className={className}>
      <BigLinksContainer>
        {bigLinks.map((bigLink, idx) => {
          const icon = bigLink?.icon?.icon || bigLink?.link?.icon?.icon;
          return (
            <TopicLinksBigLink
              $deactivated={!!bigLink.deactivated}
              $styleVariant={'topicLinks'}
              key={getMappedComponentKey(bigLink, idx)}
              link={bigLink.link}
              modal={bigLink.modal}
              onClick={() => {
                triggerAnalyticsEvent(BIG_LINK_TOPIC_LINK, {
                  TopicLink: bigLink.link.title.nn,
                });
              }}
            >
              {icon && (
                <BigLinkFontIconContainer>
                  <StyledBigLinkIcon
                    iconClassName={icon}
                    $styleVariant={'topicLinks'}
                  />
                </BigLinkFontIconContainer>
              )}
              {bigLink.richText && (
                <BigLinkRichText
                  blockContent={localize(bigLink.richText)}
                  $styleVariant={'topicLinks'}
                />
              )}
            </TopicLinksBigLink>
          );
        })}
      </BigLinksContainer>
    </BigLinkSetContainer>
  );
};

export default TopicLinksBigLinkSet;
