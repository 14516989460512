/* eslint-disable react/display-name */
import React from 'react';
import SanityGatsbyImage from '../images/sanity-gatsby-image';
import { PriceReference } from './price-reference';
import LinkReference from './link-reference';
import { InlineImage } from './inline-image';
import { Collapsible } from './collapsible';
import { StepByStep } from '/src/components/content/step-by-step/step-by-step';
import { SSFBlockContentSerializers } from '@ssfrepo/ssf-sanity-utils';
import { SsfBlockContent } from '@ssfrepo/ssf-react-components';
import FormWrapper from '../../content/form-wrapper/form-wrapper';
import Media from '../../content/media/media';

export const defaultGatsbySerializers: SSFBlockContentSerializers = {
  types: {
    imageExtras: (props) => {
      return <SanityGatsbyImage imageExtras={props.node} />;
    },
    stepByStep: (props) => {
      return <StepByStep stepByStep={props.node} />;
    },
    form: (props) => {
      return <FormWrapper form={props.node} />;
    },
    video: (props) => {
      return <Media media={props.node} />;
    },
    collapsibleMark: () => null,
  },
  marks: {
    priceReference: PriceReference,
    linkReference: LinkReference,
    inlineImage: InlineImage,
  },
};

type Props = {
  blockContent: any;
  className?: string;
};

/**
 * Gatsby block-content renderer.
 * Small wrapper around ssf-react-components,
 * but with special serializers.
 * */
const BlockContentWrapper = (props: Props) => {
  const { blockContent, className } = props;

  if (!blockContent) {
    return null;
  }

  const collapsibleMarkIndex = blockContent.findIndex(
    (block) => block._type === 'collapsibleMark' && block.enabled
  );

  if (collapsibleMarkIndex === -1) {
    return <SsfBlockContent className={className} blocks={blockContent} />;
  }

  const visibleBlocks = blockContent.slice(0, collapsibleMarkIndex);
  const collapsibleBlocks = blockContent
    .slice(collapsibleMarkIndex + 1)
    .filter((block) => block._type !== 'collapsibleMark');

  return (
    <>
      <SsfBlockContent className={className} blocks={visibleBlocks} />
      {collapsibleBlocks.length > 0 && (
        <Collapsible>
          <SsfBlockContent className={className} blocks={collapsibleBlocks} />
        </Collapsible>
      )}
    </>
  );
};
export default BlockContentWrapper;
