/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// smoothscroll for safari
import smoothscroll from 'smoothscroll-polyfill';
smoothscroll.polyfill();

import wrapPageElement from './src/components/layout/wrap-page-browser';
import wrapRootElement from './src/components/layout/wrap-root-browser';
import { shouldUpdateScroll } from './src/hooks/gatsby-route-update-event';
import { onRouteUpdate } from './src/lifecycle-apis/index';

const _wrapRootElement = wrapRootElement;
export { _wrapRootElement as wrapRootElement };
const _wrapPageElement = wrapPageElement;
export { _wrapPageElement as wrapPageElement };

const _shouldUpdateScroll = shouldUpdateScroll;
export { _shouldUpdateScroll as shouldUpdateScroll };
const _onRouteUpdate = onRouteUpdate;
export { _onRouteUpdate as onRouteUpdate };
