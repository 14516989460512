import { WindowLocation } from '@reach/router';

export const getScrollPosition = (key: string): number => {
  if (window && window.sessionStorage) {
    const scrollY = window.sessionStorage.getItem(key);
    return Number(scrollY || '0');
  }
  return 0;
};

export const saveScrollPosition = (key: string, scrollY: number): void => {
  if (window && window.sessionStorage) {
    window.sessionStorage.setItem(key, scrollY.toString());
  }
};

export const getLocationKey = (location: WindowLocation): string => {
  const locationKey = `ssf-scroll|${location.pathname}`;

  return locationKey;
};
