import styled from 'styled-components';
import React, { ReactNode } from 'react';
import { Link } from 'gatsby';
import { SanityLink } from '@ssfrepo/ssf-sanity-utils';
import get from 'lodash.get';
import { useSiteMetaData } from '/src/hooks/use-site-metadata';
import {
  appendPrefillDataToSlug,
  mediaBreakPoints,
} from '@ssfrepo/ssf-react-components';
import { SanityModal } from '/src/types/sanity/core/sanity-modal';
import LazyClientComponent from './lazy-client-component';
import { triggerAnalyticsEvent } from '/src/utils/analytics-utils';

// workaround for circular dependency
const ModalLink = React.lazy(() => import('./modal-link'));

type Props = {
  onIsCurrent?: (v: boolean) => void;
  className?: string;
  activeClassName?: string;
  link?: SanityLink | string;
  modal?: SanityModal;
  children?: ReactNode;
  onClick?: (event: any) => void;
  openInNewTab?: boolean;
};

const StyledLink = styled(Link)`
  display: inline-flex;
  text-decoration: none;
`;

const StyledAnchor = styled.a`
  text-decoration: none;
  @media (max-width: ${mediaBreakPoints.sm}) {
    margin: 0px 0px 5px 0px;
  }
`;

export const ConditionalLink = (props: Props) => {
  const {
    onIsCurrent,
    className,
    activeClassName,
    link,
    modal,
    children,
    onClick,
    openInNewTab = false,
  } = props;
  const { onlineBankUrl } = useSiteMetaData();

  if (!link && !modal) {
    return null;
  }

  if (link) {
    if (typeof link === 'string') {
      return (
        <StyledAnchor key={link} className={className} href={link}>
          {children}
        </StyledAnchor>
      );
    }

    let url = link.url || get(link, 'servedFile.file.asset.url');
    if (link.bankSlug) {
      url = `${onlineBankUrl}${link.bankSlug?.current}`;
    }
    const { customAnalyticsEvent } = link;
    const prioritizeOnClickEvents = customAnalyticsEvent
      ? (event: any) => {
          triggerAnalyticsEvent(customAnalyticsEvent, window.location.pathname);
          onClick?.(event);
        }
      : onClick;

    if (url) {
      const newTabProp =
        openInNewTab || link.newTab ? { target: '_blank' } : {};
      return (
        <StyledAnchor
          key={url}
          className={className}
          href={url}
          onClick={prioritizeOnClickEvents}
          {...newTabProp}
        >
          {children}
        </StyledAnchor>
      );
    }

    if (link.slug && openInNewTab) {
      const _url = link.slug.current;
      const newTabProp = openInNewTab ? { target: '_blank' } : {};
      return (
        <StyledAnchor
          key={_url}
          className={className}
          href={_url}
          onClick={prioritizeOnClickEvents}
          {...newTabProp}
        >
          {children}
        </StyledAnchor>
      );
    }

    if (link.slug) {
      let _current = link.slug.current;
      if (link.formData && link.formData.length) {
        const withParamsLink = appendPrefillDataToSlug(
          link,
          link.formData.reduce((accu, i) => ({ ...accu, [i.key]: i.value }), {})
        );
        _current = withParamsLink?.slug?.current || '';
      }

      return (
        <StyledLink
          key={_current}
          activeClassName={activeClassName}
          className={className}
          getProps={({ isCurrent }) => {
            if (onIsCurrent) {
              onIsCurrent(isCurrent);
            }

            return {};
          }}
          onClick={prioritizeOnClickEvents}
          to={_current}
        >
          {children}
        </StyledLink>
      );
    }
  }

  if (modal) {
    return (
      <LazyClientComponent fallback={<div />}>
        <ModalLink className={className} modal={modal}>
          {children}
        </ModalLink>
      </LazyClientComponent>
    );
  }

  return null;
};

export default ConditionalLink;
