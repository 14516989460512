export const isRunningInApp = (): boolean => {
  if (typeof window !== 'undefined') {
    const _window = window as any;
    return (
      _window.Android ||
      (_window.webkit &&
        _window.webkit.messageHandlers &&
        _window.webkit.messageHandlers.bridge)
    );
  }
  return true;
};
