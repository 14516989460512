import { LocationType } from '/src/hooks/use-location-type';
import React from 'react';
import { COLORS } from '@ssfrepo/ssf-react-components';
import styled from 'styled-components';

const Footer = styled.footer<{
  $pmType: LocationType;
}>`
  display: block;
  position: relative;
  width: 100%;
  height: 20px;
  flex-shrink: 0;
  ${(props) =>
    props.$pmType === LocationType.Ssf
      ? `background-color: ${COLORS.ssfBlue500};`
      : props.$pmType === LocationType.Sparebankstiftinga
      ? `background-color: ${COLORS.ssfGreen500};`
      : props.$pmType === LocationType.Eigedomsmekling
      ? `background-color: ${COLORS.ssfRedA700}`
      : props.$pmType === LocationType.ProfileManual
      ? `background-color: ${COLORS.ssfSandyBackground}`
      : 'background-color: none;'};
  &:after {
    display: block;
    position: absolute;
    bottom: 100%;
    left: 0;
    right: 0;
    content: '';
    width: 100%;
    height: 100px;
    border-left: calc(100vw - 20px) solid transparent;
    box-sizing: border-box;
    pointer-events: none;
    user-select: none;
    ${(props) =>
      props.$pmType === LocationType.Ssf
        ? `border-bottom: 5vw solid ${COLORS.ssfBlue500};`
        : props.$pmType === LocationType.Sparebankstiftinga
        ? `border-bottom: 5vw solid ${COLORS.ssfGreen500};`
        : props.$pmType === LocationType.Eigedomsmekling
        ? `border-bottom: 5vw solid ${COLORS.ssfRedA700}`
        : props.$pmType === LocationType.ProfileManual
        ? `border-bottom: 5vW solid ${COLORS.ssfSandyBackground}`
        : 'border-bottom: none;'};
  }
`;

type Props = {
  pmType: LocationType;
};

export const FooterProfileManual = (props: Props) => {
  const { pmType } = props;

  if (!pmType) {
    return null;
  }

  return <Footer $pmType={pmType} />;
};
