import {
  SanityBlockContent,
  SanityClientConfig,
  SanityLinkCaption,
  SanityLocaleBlockContent,
  SanityLocaleString,
} from '@ssfrepo/ssf-sanity-utils';
import groq from 'groq';
import { PageSearchDocImplResult, queryDocs } from '..';
import { PageSearchDocument } from '/src/types/core/search';

const path = (office: Office): string | null => {
  return (
    office?.privateEmployeesButton?.link?.slug?.current ||
    office?.businessEmployeesButton?.link?.slug?.current ||
    null
  );
};

const extractBlockContent = (blocks: SanityBlockContent): string => {
  const fullText: string[] = [];
  for (let idx = 0; idx < blocks.length; idx++) {
    const block = blocks[idx];
    const children = block?.children || [];
    for (let _idx = 0; _idx < children.length; _idx++) {
      const childText = children[_idx].text;
      fullText.push(childText);
    }
  }

  return `${fullText.join('')}`;
};

const description = (office: Office, language: string): string => {
  const blocks = office?.richText;
  if (!blocks || !blocks[language] || !blocks[language].length) {
    return '';
  }
  return extractBlockContent(blocks[language]);
};

const GROQ_QUERY_OFFICES = groq`
*[_type == 'office']{
  title,
  businessEmployeesButton {
   ...,
   link->
  },
  privateEmployeesButton {
    ...,
    link->
  },
  mapButton {
    ...,
    link->
  },
  richText
}
`;

interface Office {
  title: SanityLocaleString;
  privateEmployeesButton: SanityLinkCaption | null;
  businessEmployeesButton: SanityLinkCaption | null;
  mapButton: SanityLinkCaption | null;
  richText: SanityLocaleBlockContent | null;
}

export const searchableOffice = async (
  sanityConfig: SanityClientConfig
): Promise<PageSearchDocImplResult> => {
  const queryResult = await queryDocs<Office[]>(
    sanityConfig,
    GROQ_QUERY_OFFICES
  );

  if (queryResult === 'error') {
    console.error('Query failed for searchableOffice');
    return 'error';
  }

  const nnDocuments: PageSearchDocument[] = [];
  const enDocuments: PageSearchDocument[] = [];
  queryResult.forEach((office) => {
    const _path = path(office);

    if (!_path) {
      return;
    }

    const nn: PageSearchDocument = {
      title: office.title.nn || '',
      path: _path,
      subtitles: [],
      description: description(office, 'nn'),
      searchKeywords: [],
      hideInternalSearch: false,
      findEmployeeButton: office.privateEmployeesButton || undefined,
      findBMEmployeeButton: office.businessEmployeesButton || undefined,
      findLocationButton: office.mapButton || undefined,
    };

    const en = {
      ...nn,
      title: office.title.en || nn.title,
      description: description(office, 'en'),
    };

    nnDocuments.push(nn);
    enDocuments.push(en);
  });

  return {
    nnDocuments,
    enDocuments,
  };
};
