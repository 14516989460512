import {
  SanityClientConfig,
  SanityLocaleBlockContent,
} from '@ssfrepo/ssf-sanity-utils';
import groq from 'groq';
import {
  GROQ_FRAGMENT_DEFAULT_PAGE,
  getGenericPageSearchDoc,
} from './searchable-generic-page';
import { PageSearchDocument } from '/src/types/core/search';
import {
  extractBlockContentBodySummary,
  extractBlockContentHeaders,
} from './block-content-extractors';
import { PageSearchDocImplResult, queryDocs } from '..';
import { SanityPage } from '/src/types/sanity/core/sanity-page';

type PieceArticle = {
  pieces: ({
    _type: string;
    summaryText: SanityLocaleBlockContent | null | undefined;
  } | null)[];
};

type PieceSectionPage = SanityPage & {
  pieceSections: (PieceArticle | null)[];
};

const validSummaryTextStrict = (text: string): boolean =>
  text.length > 35 && text.includes(' ') && text.includes('.');

const description = (doc: PieceSectionPage, language: string): string[] => {
  const ret: string[] = [];
  let found = false;
  doc.pieceSections?.forEach((ps) => {
    !found &&
      ps?.pieces?.forEach((piece) => {
        if (
          found ||
          !piece ||
          piece._type !== 'imageSection' ||
          !piece.summaryText
        ) {
          return;
        }

        const blocks = piece.summaryText?.[language] || [];
        const hit = extractBlockContentBodySummary(
          blocks,
          validSummaryTextStrict
        );
        if (hit && hit.length) {
          ret.push(...hit);
          found = true;
        }
      });
  });
  return ret.slice(0, 1);
};

const subtitles = (doc: PieceSectionPage, language: string): string[] => {
  const _subtitles: string[] = [];

  doc.pieceSections?.forEach((pieceSection) => {
    pieceSection?.pieces?.forEach((piece) => {
      if (!piece || piece._type !== 'imageSection' || !piece.summaryText) {
        return;
      }
      const blocks = piece.summaryText?.[language] || [];
      _subtitles.push(...extractBlockContentHeaders(blocks));
    });
  });
  return _subtitles;
};

const GROQ_QUERY_PIECE_SECTION_PAGES = groq`
*[_type =='pieceSectionPage']{
    pieceSections[]{
        pieces[]->{
            _type,
            _type == 'imageSection' => {
                summaryText
            }
        }
    },
    ${GROQ_FRAGMENT_DEFAULT_PAGE}
}
`;

const pieceSectionPageSearchDocs = (
  page: PieceSectionPage,
  language: string
): PageSearchDocument | null => {
  const doc = getGenericPageSearchDoc(page, language);
  if (!doc) return null;

  doc.subtitles = subtitles(page, language);
  doc.description =
    doc.description && doc.description.length
      ? doc.description
      : description(page, language);
  return doc;
};

export const searchablePieceSectionPage = async (
  sanityConfig: SanityClientConfig
): Promise<PageSearchDocImplResult> => {
  const queryResult = await queryDocs<PieceSectionPage[]>(
    sanityConfig,
    GROQ_QUERY_PIECE_SECTION_PAGES
  );

  if (queryResult === 'error') {
    console.error('Query failed for searchablePieceSectionPage');
    return 'error';
  }
  const nnDocuments: PageSearchDocument[] = [];
  const enDocuments: PageSearchDocument[] = [];

  queryResult.forEach((doc) => {
    const nn = pieceSectionPageSearchDocs(doc, 'nn');

    if (nn) nnDocuments.push(nn);
    const en = pieceSectionPageSearchDocs(doc, 'en');
    if (en) enDocuments.push(en);
  });

  return {
    nnDocuments,
    enDocuments,
  };
};
