import get from 'lodash.get';

export const extractBlockContentHeaders = (blocks: any[]) => {
  const headers: string[] = [];

  for (let __idx = 0; __idx < blocks.length; __idx++) {
    const block = blocks[__idx];
    if (
      block._type === 'block' &&
      ['h1', 'h2', 'h3', 'h4'].includes(block.style)
    ) {
      const firstChild = get(block, 'children[0]');
      if (firstChild.text) {
        headers.push(firstChild.text);
      }
    }
  }
  return headers;
};

export const extractBlockContentBodySummary = (
  blocks: any[],
  validSummaryText: (_txt: string) => boolean
) => {
  const description: string[] = [];

  for (let __idx = 0; __idx < blocks.length; __idx++) {
    const block = blocks[__idx];
    if (block._type === 'block' && ['ingress'].includes(block.style)) {
      const text: string = get(block, 'children[0].text', '');
      if (validSummaryText(text)) {
        const sentences = text.split('.');
        let desc = `${sentences.slice(0, 2).join('.')}`;
        if (desc.length > 140) {
          desc = `${desc.substring(0, 140)}`;
        }
        desc = `${desc}...`;
        description.push(desc);
        return description;
      }
    }
  }
  return description;
};

export const extractIngresslessBlockContentBodySummary = (blocks: any[]) => {
  const fullText: string[] = [];
  for (let idx = 0; idx < blocks.length; idx++) {
    const block = blocks[idx];
    const children = get(block, 'children', []);
    for (let _idx = 0; _idx < children.length; _idx++) {
      const childText = children[_idx].text;
      fullText.push(childText);
    }
  }
  const description: string[] = [];
  let desc = `${fullText.slice(0, 2).join('. ')}`;
  if (desc.length > 140) {
    desc = `${desc.substring(0, 140)}`;
  }
  desc = `${desc}...`;
  description.push(desc);
  return description;
};
