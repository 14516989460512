import { CalculatorStyledTheme } from 'styled-components';
import { COLORS } from '@ssfrepo/ssf-react-components';

export const CalculatorStyledThemeDarkImpl: CalculatorStyledTheme = {
  CalcOuterContainerBg: COLORS.ssfBlue900,
  calculatorMainTitleColor: COLORS.ssfBlue100,
  toolTipBg: COLORS.ssfBlue900,
  iconColor: COLORS.ssfBlue100,
  tooltipTextColor: COLORS.ssfBlue100,
  commonInputWrapperBg: 'transparent',
  commonInputWrapperBorder: COLORS.ssfBlue100,
  commonInputColor: COLORS.ssfBlue100,
  commonInputSuffixColor: COLORS.ssfBlue100,
  subTextcolor: COLORS.ssfBlue100,
  labelColor: COLORS.ssfBlue100,
  calculationResultContainerBg: COLORS.ssfBlue1000,
  calculationResultTitleColor: COLORS.ssfBlue100,
  calculationResultColor: COLORS.ssfBlue100,
  calculatorNoteColor: COLORS.ssfBlue100,
  ellipsisLoaderDotColor: COLORS.ssfBlue500,
  progressBg: COLORS.ssfBlue300,
  ibanCalcInputPlaceholder: COLORS.ssfGrey300,
  ibaNumberDisplayBackground: COLORS.ssfBlue1000,
  ibanLabel: COLORS.ssfBlue100,
  ibanNumber: COLORS.ssfBlue100,
  tipText: COLORS.ssfBlue300,
  disbaledIbanNumberCopierBg: COLORS.ssfBlue900,
  disbaledIbanNumberFg: COLORS.ssfBlue700,
  disbaledIbanNumberCopierBorder: COLORS.ssfBlue700,
};
