import { StepByStepTheme } from 'styled-components';
import { COLORS } from '@ssfrepo/ssf-react-components';

export const stepByStepProfileThemeImpl: StepByStepTheme = {
  fg: COLORS.ssfBlue500,
  stepNumberBg: 'transparent',
  stepContentBg: COLORS.ssfBlue300,
  stepNumberBorder: COLORS.ssfBlue300,
  stepNumberAfterBorder: COLORS.ssfBlue300,
};
