import { COLORS as monoRepoCOLORS } from '@ssfrepo/ssf-react-components';
export const mediaBreakPoints = {
  None: '0',
  iPhone5: '320px',
  galaxy4: '359px',
  iPhone8: '375px',
  iPhonePlus: '414px',
  sm: '600px',
  ipad: '760px',
  sideMenu: '800px',
  kongleriketPicture: '800px',
  md: '960px',
  lg: '1280px',
  profileManualMenu: '1600px',
  xl: '1920px',
  widescreen: '2560px',
};
export const pixelToNumber = (str: string): number =>
  Number(str.replace('px', ''));

export const shadows = {
  ssfProductShadow: '0px 4px 4px rgba(0, 0, 0, 0.08)',
  ssfVerticalBigLinkProductShadow: '0px 4px 4px 0px #00000040',
  ssfContainerShadow: '1px 5px 10px rgba(0, 0, 0, 0.08)',
  ssfButtonShadow: '0px 2px 2px rgba(0, 0, 0, 0.15)',
  ssfRoundedImageShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
};

export const COLORS = monoRepoCOLORS;

// Supposed to take whole screen width,
// but some panorama screens can look ridiculous if not for a max-width
export const BANNER_MAX_WIDTH = '2048px';
// Main width everywhere considering 20px left and right paddings
export const MAIN_MAX_WIDTH = '980px';
export const MAIN_PADDING = '20px';
export const TOP_BAR_HEIGHT = 82;
export const SIDE_BUTTON_TOP = 20 + TOP_BAR_HEIGHT;
export const IMAGE_MAX_WIDTH = 940;
