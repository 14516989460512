import { CalculatorStyledTheme } from 'styled-components';
import { COLORS } from '@ssfrepo/ssf-react-components';

export const CalculatorStyledThemeProfileImpl: CalculatorStyledTheme = {
  CalcOuterContainerBg: COLORS.ssfWhite,
  calculatorMainTitleColor: COLORS.ssfBlue500,
  toolTipBg: 'rgb(239, 239, 239), rgb(59, 59, 59)',
  iconColor: COLORS.ssfGrey400,
  tooltipTextColor: COLORS.ssfGrey400,
  commonInputWrapperBg: COLORS.ssfGrey100,
  commonInputWrapperBorder: COLORS.ssfGrey500,
  commonInputColor: COLORS.ssfBlue500,
  commonInputSuffixColor: COLORS.ssfGrey400,
  subTextcolor: COLORS.ssfGrey400,
  labelColor: COLORS.ssfGrey500,
  calculationResultContainerBg: COLORS.ssfBlue100,
  calculationResultTitleColor: COLORS.ssfBlue500,
  calculationResultColor: COLORS.ssfBlue500,
  calculatorNoteColor: COLORS.ssfGrey500,
  ellipsisLoaderDotColor: COLORS.ssfBlue500,
  progressBg: COLORS.ssfBlue400,
  ibanCalcInputPlaceholder: COLORS.ssfGrey300,
  ibaNumberDisplayBackground: COLORS.ssfBlue200,
  ibanLabel: COLORS.ssfDarkBlue,
  ibanNumber: COLORS.ssfBlack,
  tipText: COLORS.ssfGrey400,
  disbaledIbanNumberCopierBg: COLORS.ssfGrey50,
  disbaledIbanNumberFg: COLORS.ssfGrey300,
  disbaledIbanNumberCopierBorder: COLORS.ssfGrey300,
};
