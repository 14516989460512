import { COLORS } from '@ssfrepo/ssf-react-components';
import { FrendeTheme } from 'styled-components';

export const frendeWebshopDarkThemeImpl: FrendeTheme = {
  bg: COLORS.ssfBlue100,
  borderColor: COLORS.ssfBlue400,
};
export const frendeWebshopLightThemeImpl: FrendeTheme = {
  bg: 'transparent',
  borderColor: COLORS.ssfSandyBackground,
};
