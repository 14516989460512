import React from 'react';
import styled from 'styled-components';
import { COLORS } from '@ssfrepo/ssf-react-components';
import { fontHeaderFourish } from '/src/sass/typography/typography-styled';
import get from 'lodash.get';
import { SanityImageExtras } from '@ssfrepo/ssf-sanity-utils';

export type RibbonLayout = Required<SanityImageExtras>['ribbonLayout'];

interface RibbonColors {
  fg: string;
  bg: string;
  text: string;
}

type RibbonColorsType = {
  [key in RibbonLayout]: RibbonColors;
};

const ribbonColors: RibbonColorsType = {
  turquoise: {
    fg: '#c0e7ef',
    bg: COLORS.ssfBlue400,
    text: COLORS.ssfBlack,
  },
  deepRed: {
    fg: COLORS.ssfRedA700,
    bg: COLORS.ssfRedA700,
    text: COLORS.ssfWhite,
  },
  orange: {
    fg: COLORS.ssfOrange200,
    bg: COLORS.ssfOrange500,
    text: COLORS.ssfBlack,
  },
  green: {
    fg: COLORS.ssfGreen200,
    bg: COLORS.ssfGreen500,
    text: COLORS.ssfBlack,
  },
};

const RibbonFront = styled.div.attrs(
  ({ ribboncolors }: { ribboncolors: RibbonColors }) => ({ ribboncolors })
)`
  background: ${(props) => props.ribboncolors.fg};
  border-radius: 5px;
  position: absolute;
  bottom: 10%;
  right: -12px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  z-index: 2;
`;

const RibbonBack = styled.div.attrs(
  ({ ribboncolors }: { ribboncolors: RibbonColors }) => ({ ribboncolors })
)`
  position: absolute;
  bottom: 10%;
  right: -11px;
  background: ${(props) => props.ribboncolors.bg};
  box-shadow: -4px 2px 5px rgba(0, 0, 0, 0.12), -3px 3px 2px rgba(0, 0, 0, 0.14),
    0px 3px 1px rgba(0, 0, 0, 0.2);
  transform: skew(0deg, -40deg) translateY(2px);
  height: 10px;
  width: 12px;
  z-index: -1;
`;

const Text = styled.span.attrs(
  ({ ribboncolors }: { ribboncolors: RibbonColors }) => ({ ribboncolors })
)`
  ${fontHeaderFourish}
  color: ${(props) => props.ribboncolors.text};
`;

type Props = {
  text: string;
  ribbonLayout?: RibbonLayout;
};

const RibbonText = (props: Props) => {
  const { text, ribbonLayout } = props;
  const ribboncolors = get(ribbonColors, `${ribbonLayout || 'turquoise'}`);

  return (
    <>
      <RibbonFront ribboncolors={ribboncolors}>
        <Text ribboncolors={ribboncolors}>{text}</Text>
      </RibbonFront>
      <RibbonBack ribboncolors={ribboncolors} />
    </>
  );
};

export default RibbonText;
