import React from 'react';
import styled, { keyframes } from 'styled-components';
import { COLORS } from '@ssfrepo/ssf-react-components';
import { SanityMenuGrouping } from '/src/types/sanity/menu/menu-grouping';
import { FlexStartContainer } from '@ssfrepo/ssf-react-components';
import MarketLinks from './market-links';
import { SanityLink } from '@ssfrepo/ssf-sanity-utils';
import SearchIcon from '../../../domains/search/components/search-icon';
import ConditionalLink from '/src/components/core/conditional-link';
import {
  ButtonContainer,
  ButtonText,
  useSanityContentLocalizer,
} from '@ssfrepo/ssf-react-components';
import { SSFScrollSecondary } from '/src/components/styled/ssf-scroll/ssf-scroll';
import ModalCloseButton from '@ssfrepo/ssf-react-components/lib/buttons/modal-close';
import MenuGroupExpander from './menu-group-expander/menu-group-expander';
import { SanityTopBar } from '/src/types/sanity/menu/topbar';
import { LocationType, useLocationType } from '/src/hooks/use-location-type';
import { useOnlineLogin } from '/src/hooks/use-online-login';
import { isRunningInApp } from '/src/utils/mobile-utils';
import { MENU_CLICK, triggerAnalyticsEvent } from '/src/utils/analytics-utils';

export const slideRight = keyframes`
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
`;

const MainMenu = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.common.layout.menu.desktopMenuBg};
  z-index: 1000;
  animation: ${slideRight} 0.3s ease-out;
  width: 300px;
  ${SSFScrollSecondary}
`;

const RealmSelectContainer = styled(FlexStartContainer)`
  padding: 15px 15px 15px 20px;
  border-bottom: 1px solid ${COLORS.ssfBlue400};
`;

const ActionsContainer = styled(FlexStartContainer)`
  margin: 20px 15px 15px 20px;
`;

const CloseButton = styled(ModalCloseButton)`
  position: absolute !important;
  top: 20px;
  right: 20px;
  color: ${COLORS.ssfWhite} !important;
`;

const StyledMarketLinks = styled(MarketLinks)`
  margin-left: 20px;
`;

const MobileButtonContainer = styled.div.attrs(
  ({ bgcolor, color }: { bgcolor: string; color: string }) => ({
    bgcolor: bgcolor,
    color: color,
  })
)`
  ${ButtonContainer}
  color: ${({ color }) => color};
  border: 1px solid ${COLORS.ssfBlue100};
  background: ${({ bgcolor }) => bgcolor};
  border-radius: 25px;
  :first-child {
    margin-right: 10px;
  }
`;

const StyledButtonText = styled(ButtonText)`
  padding: 5px 10px 5px 10px;
`;

const MobileButtonLink = ({
  text,
  link,
  onNavigation,
  backgroundColor = 'transparent',
  color = COLORS.ssfWhite,
}: {
  text: string;
  link: SanityLink;
  onNavigation: () => void;
  backgroundColor?: string;
  color?: string;
}) => {
  return (
    <ConditionalLink
      link={link}
      onClick={() => {
        onNavigation;
        triggerAnalyticsEvent(MENU_CLICK, {
          Menu: link.title.nn,
        });
      }}
    >
      <MobileButtonContainer color={color} bgcolor={backgroundColor}>
        <StyledButtonText>{text}</StyledButtonText>
      </MobileButtonContainer>
    </ConditionalLink>
  );
};

type Props = {
  onNavigation: () => void;
  menuGroupings: SanityMenuGrouping[];
  topBar: SanityTopBar;
};

const MobileMenu = (props: Props) => {
  const { onNavigation, menuGroupings, topBar } = props;
  const localize = useSanityContentLocalizer();
  const customerType = useLocationType();
  const onlineLogin = useOnlineLogin();

  const { privateLogin, businessLogin, privateSignUp, businessSignUp } = topBar;
  const loginLink =
    customerType === LocationType.Private ? privateLogin : businessLogin;

  const signUpLink =
    customerType === LocationType.Private ? privateSignUp : businessSignUp;

  const showMenuLoggedOutActions =
    !isRunningInApp() &&
    (customerType === LocationType.Business || onlineLogin === 'out');

  return (
    <MainMenu>
      <RealmSelectContainer>
        <SearchIcon onNavigation={onNavigation} />
        <StyledMarketLinks topBar={topBar} styleType={'menu'} />
        <CloseButton onClick={onNavigation} />
      </RealmSelectContainer>
      <ActionsContainer>
        {showMenuLoggedOutActions && (
          <MobileButtonLink
            onNavigation={onNavigation}
            key="mobile-button-signup"
            link={signUpLink}
            text={localize(signUpLink.title)}
          />
        )}
        {showMenuLoggedOutActions && (
          <MobileButtonLink
            onNavigation={onNavigation}
            key="mobile-button-login"
            backgroundColor={COLORS.ssfWhite}
            color={COLORS.ssfBlue500}
            link={loginLink}
            text={localize(loginLink.title)}
          />
        )}
      </ActionsContainer>
      <MenuGroupExpander
        onNavigation={onNavigation}
        menuGroupings={menuGroupings}
      />
    </MainMenu>
  );
};

export default MobileMenu;
