import styled from 'styled-components';
import { SIDE_BUTTON_TOP, mediaBreakPoints } from '/src/sass/styled-constants';

export const SideButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  position: fixed;
  right: 15px;
  z-index: 10;
  outline: none;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;

  top: ${SIDE_BUTTON_TOP}px;
  @media (max-width: ${mediaBreakPoints.sm}) {
    top: unset;
    bottom: 80px;
    right: 0px;
  }
`;
