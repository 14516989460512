import {
  SSFScrollSecondary,
  SSFScroll,
} from '/src/components/styled/ssf-scroll/ssf-scroll';
import { COLORS } from '@ssfrepo/ssf-react-components';
import { ChatBoxStyle, ChatBoxTheme, css } from 'styled-components';

const AdminUserLinearBlueLight = css`
  text-decoration: underline;
  font-style: italic;
  color: ${COLORS.ssfRedA700};
`;

const AdminUserSandyLight = css`
  text-decoration: underline;
  font-style: italic;
  color: ${COLORS.ssfRedA700};
`;

const blue: ChatBoxStyle = {
  bg: COLORS.ssfLinearGradientBlue,
  fg: COLORS.ssfWhite,
  border: COLORS.ssfTurquoise200,
  label: COLORS.ssfGrey1337,
  scroll: SSFScrollSecondary,
  adminTextStyles: AdminUserLinearBlueLight,
};

const sandy: ChatBoxStyle = {
  bg: COLORS.ssfSandyBackground,
  fg: COLORS.ssfBlackForm,
  border: COLORS.ssfBlackForm,
  label: COLORS.ssfBlackForm,
  scroll: SSFScroll,
  adminTextStyles: AdminUserSandyLight,
};

export const lightChatBoxThemeImpl: ChatBoxTheme = {
  blue,
  sandy,
};
