import React, { useCallback, useEffect, useRef, useState } from 'react';
import { TopBar } from './topbar/topbar';
import styled, { css } from 'styled-components';
import favicon from '/src/assets/images/favicon.ico';
import '/src/sass/main.scss';
import { Footer } from './footer/footer';
import { SideButtonContainer } from './side-buttons/styled';
import crystal from '/src/assets/images/crystal-right.svg';
import { useLayoutVariant } from '/src/hooks/layout-variant-provider';
import {
  getLocationKey,
  getScrollPosition,
  saveScrollPosition,
} from '../../utils/scroll-utils';
import { LocationType, useLocationType } from '/src/hooks/use-location-type';
import { useFeatureFlag } from '/src/hooks/use-feature-flags';
import { WindowLocation } from '@reach/router';
import {
  mediaBreakPoints,
  pixelToNumber,
  TOP_BAR_HEIGHT,
} from '/src/sass/styled-constants';
import { useWindowDimensions } from '@ssfrepo/ssf-react-components';
import SsfButton from '@ssfrepo/ssf-react-components/lib/buttons/button';
import get from 'lodash.get';
import { FooterProfileManual } from './profile-manual/footer-profile-manual';
import { useCookieTerminator } from '/src/hooks/use-cookie-terminator';
import SsfChat from '/src/domains/chat/chat';
import ChatLoaderLoader from '/src/domains/chat/chat-loader-loader';

const ScreenContainer = styled.div`
  height: 100%;
  width: 100%;
`;

const Crystal = css`
  background: url(${crystal}) no-repeat 100% 100%;
`;

const Main = styled.main.attrs(
  ({ $showCrystal }: { $showCrystal: boolean }) => ({
    $showCrystal: $showCrystal,
  })
)`
  width: 100%;
  flex: 1 0 auto;
  display: block;
  /* to avoid overlapping with footer trapezoidal detail */
  padding-bottom: 80px;
  ${(props) => (props.$showCrystal ? Crystal : '')}
`;

const ScrollPortalDiv = styled.div<{ $isLayoutProfileManual: boolean }>`
  height: calc(100% - ${TOP_BAR_HEIGHT}px);
  overflow-y: scroll;
  overflow-x: hidden;
  will-change: scroll-position;
  display: flex;
  flex-direction: column;
  ${(p) =>
    p.$isLayoutProfileManual
      ? `
    margin-top: ${TOP_BAR_HEIGHT}px;
  `
      : ''}
  background: var(--common-layout-main-bg);
`;

const ToTopMobileButton = styled(SsfButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  outline: none;
  position: fixed;
  margin: 15px;
  bottom: 23px;
  position: sticky;
  z-index: 97;
  left: calc(50% - 20px);
  min-height: 40px !important;
  padding: 5px;
  i {
    font-size: 20px !important;
    padding: 5px;
  }
`;

const StyledFooterProfileManual = styled(FooterProfileManual)`
  position: relative;
  bottom: 0px;
`;

export const ShowBelow = 120;

export type LayoutProps = {
  children: React.ReactNode;
  location: WindowLocation;
  data: any;
  getScroll: (
    scrollPos: number,
    scrollRef: React.RefObject<HTMLDivElement> | null
  ) => void;
};

const scrollToTop = (scrollRef: React.RefObject<HTMLDivElement> | null) => {
  scrollRef?.current?.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth',
  });
};

/**
 * Component for shared layout.
 */
const Layout = (props: LayoutProps) => {
  const { children, location, data } = props;
  const [layoutVariant] = useLayoutVariant();
  const locationType = useLocationType();
  const businessChatFlag = useFeatureFlag('businessChatBox');
  const privateChatFlag = useFeatureFlag('privateChatBox');
  const [showMobileToTopButton, setShowMobileToTopButton] = useState(false);
  const scrollRef = useRef<HTMLDivElement>(null);
  const dims = useWindowDimensions();
  const isTabletOrMobile = dims.width <= pixelToNumber(mediaBreakPoints.lg);
  const frendeWebshopPage = data?.frendeWebshopPage;

  const onScroll = useCallback(
    (e) => {
      const pos = get(e, 'target.scrollTop') || 0;
      saveScrollPosition(getLocationKey(location), pos);
      if (showMobileToTopButton) {
        if (pos < ShowBelow) {
          setShowMobileToTopButton(false);
        }
      } else {
        if (pos >= ShowBelow) {
          setShowMobileToTopButton(true);
        }
      }
    },
    [location, showMobileToTopButton]
  );

  useEffect(() => {
    if (scrollRef && scrollRef.current) {
      if (frendeWebshopPage) {
        return;
      }
      const scrollY = getScrollPosition(getLocationKey(location));
      scrollRef.current.scrollTop = scrollY;
    }
  }, [scrollRef, location, frendeWebshopPage]);
  useCookieTerminator();
  const showChatButton =
    (privateChatFlag && locationType === LocationType.Private) ||
    (businessChatFlag && locationType === LocationType.Business);

  const isLocationTypePM =
    locationType !== LocationType.Private &&
    locationType !== LocationType.Business;

  return (
    <>
      <ScreenContainer>
        <link rel='icon' type='image/x-icon' href={favicon}
        />
        {!isLocationTypePM && <TopBar />}
        {!isLocationTypePM && (
          <SideButtonContainer>
            {showChatButton && (
              <>
                <SsfChat />
                <ChatLoaderLoader />
              </>
            )}
          </SideButtonContainer>
        )}
        <ScrollPortalDiv
          ref={scrollRef}
          onScroll={onScroll}
          $isLayoutProfileManual={isLocationTypePM}
        >
          <Main role="main" $showCrystal={layoutVariant === 'crystal'}>
            {children}
            {showMobileToTopButton && isTabletOrMobile && (
              <ToTopMobileButton
                type="button"
                arityScheme="tertiary"
                iconClassName={'ssf-arrow-up'}
                onClick={() => scrollToTop(scrollRef)}
              />
            )}
          </Main>
          {!isLocationTypePM && (
            <Footer
              isDesktop={!isTabletOrMobile}
              onClick={() => scrollToTop(scrollRef)}
            />
          )}
          {isLocationTypePM && (
            <StyledFooterProfileManual pmType={locationType} />
          )}
        </ScrollPortalDiv>
      </ScreenContainer>
    </>
  );
};

export default Layout;
