import React, { useState, useEffect } from 'react';
import { mediaBreakPoints, useLanguage } from '@ssfrepo/ssf-react-components';
import styled from 'styled-components';
import norskflag from '/src/assets/images/norsk-flag.svg';
import britishflag from '/src/assets/images/british-flag.svg';

const LanguagesList = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 40px;

  @media (max-width: ${mediaBreakPoints.sideMenu}) {
  }
  @media (max-width: 280px) {
    font-size: 14px;
  }
`;

const FlagAndTextWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0px;
  cursor: pointer;
`;

const Flag = styled.img<{ $currentLanguage: string }>`
  content: url(${({ $currentLanguage }) =>
    $currentLanguage === 'nn' ? britishflag : norskflag});
`;

const LanguageOptionText = styled.span`
  min-width: 57px;
  text-align: left;
  margin-left: 10px;
  color: ${(p) => p.theme.common.layout.footer.languagePicker};
  &:hover {
    text-decoration: underline;
  }
`;

const LanguagePicker = () => {
  const [language, setLanguage] = useLanguage();
  const [isClientSide, setIsClientSide] = useState(false);
  const animationClass = `animated ${
    language === 'nn' ? 'rotateInRight' : 'rotateInLeft'
  }`;

  useEffect(() => {
    setIsClientSide(true);
  }, []);

  if (!isClientSide) return null;

  return (
    <LanguagesList>
      <FlagAndTextWrapper
        tabIndex={0}
        className="no-flash"
        onClick={() => setLanguage(language === 'nn' ? 'en' : 'nn')}
        onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
          if (event.key === 'Enter') {
            setLanguage(language === 'nn' ? 'en' : 'nn');
          }
        }}
      >
        <Flag className={animationClass} $currentLanguage={language} />
        <LanguageOptionText key={language} className="animated fadeIn">
          {language === 'nn' ? 'English' : 'Norsk'}
        </LanguageOptionText>
      </FlagAndTextWrapper>
    </LanguagesList>
  );
};

export default LanguagePicker;
