import { COLORS, lightCommonThemeImpl } from '@ssfrepo/ssf-react-components';

import { ReactCompCommonTheme } from 'styled-components';

export const pmCommonThemeGatsbyImpl: ReactCompCommonTheme = {
  ...lightCommonThemeImpl,
  layout: {
    activeFg: COLORS.ssfBlue500,
    activeBorder: COLORS.ssfBlue400,
    fg: COLORS.ssfGrey400,
    linkReferenceFg: COLORS.ssfDarkBlue,
    menu: {
      desktopMenuBg: COLORS.ssfMenuBackgroundDesktop,
      menuGroupTitleColor: COLORS.ssfGrey50,
      menuLinkColor: COLORS.ssfGrey50,
    },
    footer: {
      footerWrapperBg: COLORS.ssfBlue500,
      languagePicker: COLORS.ssfGrey50,
      footerLinkColor: COLORS.ssfGrey50,
    },
    modal: {
      bg: COLORS.ssfBlue100,
      calcModalBg: COLORS.ssfGrey50,
      calcModalBorder: COLORS.ssfGrey200,
      closeBtnColorAndBorder: COLORS.ssfBlue500,
      header: COLORS.ssfBlue500,
      portalBg: COLORS.ssfGrey200,
    },
  },
};
