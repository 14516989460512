import React, { useState } from 'react';
import styled from 'styled-components';
import { COLORS, mediaBreakPoints } from '/src/sass/styled-constants';
import ConditionalLink from '/src/components/core/conditional-link';
import FontIcon from '@ssfrepo/ssf-react-components/lib/buttons/font-icon';
import SearchModal, { searchPageLink } from './search-modal';

const Container = styled.div``;

const IconContainer = styled.div`
  height: 45px;
  width: 45px;
  border-radius: 50%;
  ${({ theme }) => theme.buttons.searchButton.stylingWithMediaBreakPoints};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-sizing: border-box;
  :hover {
    background-color: ${({ theme }) => theme.buttons.searchButton.hoverBg};
  }
`;

const StyledFontIcon = styled(FontIcon)`
  color: ${({ theme }) => theme.buttons.searchButton.fg};
  font-size: 25px;
  @media (max-width: ${mediaBreakPoints.sideMenu}) {
    color: ${COLORS.ssfBlue100};
  }
`;

const NoStyleButton = styled.button`
  margin: 0;
  padding: 0;
  background: 0;
  border: 0;
  outline: 0;

  :focus-visible {
    outline: 2px solid orange;
    border-radius: 2.5px;
  }
  :hover {
    border-radius: 50%;
    overflow: hidden;
  }
`;

type Props = {
  className?: string;
  onNavigation?: () => void;
  asModalButton?: boolean;
};

const SearchIcon = (props: Props) => {
  const { className, onNavigation, asModalButton = false } = props;
  const [showModal, setShowModal] = useState(false);

  const innards = (
    <IconContainer>
      <StyledFontIcon className={'ssf-zoom_find_search_magnifying_glass'} />
    </IconContainer>
  );

  return (
    <Container className={className}>
      {!asModalButton && (
        <ConditionalLink link={searchPageLink} onClick={onNavigation}>
          {innards}
        </ConditionalLink>
      )}
      {asModalButton && !showModal && (
        <NoStyleButton onClick={() => setShowModal(true)}>
          {innards}
        </NoStyleButton>
      )}
      {asModalButton && showModal && <NoStyleButton>{innards}</NoStyleButton>}
      {showModal && (
        <SearchModal
          onNavigation={() => {
            setShowModal(false);
          }}
        />
      )}
    </Container>
  );
};

export default SearchIcon;
