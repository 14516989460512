import React from 'react';
import styled from 'styled-components';
import { COLORS } from '@ssfrepo/ssf-react-components';

const Container = styled.div<{ $size: number; $centerContainer: boolean }>`
  display: ${(props) => (props.$centerContainer ? 'block' : 'inline-block')};
  margin: ${(props) => (props.$centerContainer ? 'auto' : 0)};
  position: relative;
  width: ${(props) => props.$size}px;
  height: ${(props) => props.$size}px;
`;

const EllipsisDot = styled.div<{
  $dotColor: string;
  $size: number;
}>`
  color: ${(props) =>
    props.$dotColor !== ''
      ? props.$dotColor
      : props.theme.calculatorStyled.commonInputColor};
  position: absolute;
  top: ${(props) => Math.round(props.$size / 2 - props.$size * 0.2)}px;
  width: ${(props) => Math.round(props.$size * 0.2)}px;
  height: ${(props) => Math.round(props.$size * 0.2)}px;
  border-radius: 50%;
  background: ${(props) =>
    props.$dotColor !== ''
      ? props.$dotColor
      : props.theme.calculatorStyled.commonInputColor};
  animation-timing-function: cubic-bezier(0, 1, 1, 0);

  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(${(props) => Math.round(props.$size * 0.3)}px, 0);
    }
  }

  :nth-child(1) {
    left: ${(props) => Math.round(props.$size * 0.1)}px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  :nth-child(2) {
    left: ${(props) => Math.round(props.$size * 0.1)}px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  :nth-child(3) {
    left: ${(props) => Math.round(props.$size * 0.4)}px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  :nth-child(4) {
    left: ${(props) => Math.round(props.$size * 0.6)}px;
    animation: lds-ellipsis3 0.6s infinite;
  }
`;

type Props = {
  dotColor?: string;
  pixelSize?: number;
  centerContainer?: boolean;
};

/**
 * Ported to styled-components from https://loading.io/css/
 */
const EllipsisLoader = (props: Props) => {
  const { dotColor, pixelSize = 55, centerContainer = false } = props;

  const dotProps = {
    $dotColor: dotColor ? dotColor : '',
    $size: pixelSize,
    $centerContainer: centerContainer,
  };

  return (
    <Container $size={pixelSize} $centerContainer={centerContainer}>
      <EllipsisDot {...dotProps} />
      <EllipsisDot {...dotProps} />
      <EllipsisDot {...dotProps} />
      <EllipsisDot {...dotProps} />
    </Container>
  );
};

export default EllipsisLoader;
