import React, { useContext } from 'react';
import { SanityLanguage } from '@ssfrepo/ssf-sanity-utils';
import Fuse from 'fuse.js';
import { useState, useEffect, createContext } from 'react';
import { constructSearchIndex, fuseOptions } from '.';
import { SsfFuseSearchIndex, PageSearchDocument } from '/src/types/core/search';
import { SiteMetaData } from '/src/types/core/site-metadata';

export type SearchContextData =
  | {
    fuse: Fuse<PageSearchDocument>;
    languageUsed: string;
  }
  | undefined
  | 'error';

export const SearchDataHookTypeContext = createContext<
  [SearchContextData, (s: SearchContextData) => void]
>([undefined, () => { }]);

export const SearchDataHookTypeProvider = ({ children }) => {
  const [fuseSearchIndex, setFuseSearchIndex] =
    useState<SearchContextData>(undefined);

  return (
    <SearchDataHookTypeContext.Provider
      value={[fuseSearchIndex, setFuseSearchIndex]}
    >
      {children}
    </SearchDataHookTypeContext.Provider>
  );
};

/**
 * Downloads base data, constructs and returns the fuse search index.
 * This is only done once and stored in a context hook.
 * The download has two paths:
 * - when NODE_ENV = development (ie gatsby develop)
 *    Does groq queries for all the searchable documents
 *    and maps them to the appropriate _SsfFuseSearchIndex_
 * - otherwise when not in development (ie gatsby build)
 *    downloads ./openpages-search-index.json which is just a
 *    preconstructed and serialized _SsfFuseSearchIndex_
 *    See search-index-dump run during onPostBuild.
 * This hook is one and done, it does not listen on base data.
 * This should not matter for prod builds as the json is the same anyway,
 * but for develop, it means you have to refresh the page when you expect changes.
 * */
export const useSearchData = (
  language: SanityLanguage,
  siteMetaData: SiteMetaData
): SearchContextData => {
  const [searchData, setSearchData] = useContext(SearchDataHookTypeContext);

  useEffect(() => {
    if (
      typeof searchData !== 'undefined' &&
      searchData !== 'error' &&
      searchData.fuse &&
      searchData.languageUsed === language
    ) {
      return;
    }

    const controller = new AbortController();

    let fuseSearchIndexPromise:
      | Promise<SsfFuseSearchIndex | 'error'>
      | undefined = undefined;

    if (siteMetaData.nodeEnv === 'development') {
      fuseSearchIndexPromise = constructSearchIndex({
        ...siteMetaData,
      });
    } else {
      setTimeout(() => controller.abort(), 6000);
      fuseSearchIndexPromise = fetch('/openpages-search-index.json', {
        signal: controller.signal,
      })
        .catch<'error'>((err) => {
          console.error(
            'Error loading fetch API for /openpages-search-index.json',
            err
          );
          return 'error';
        })
        .then<SsfFuseSearchIndex | 'error'>((resp) => {
          return resp === 'error' ? resp : resp.json();
        })
        .catch<'error'>((err) => {
          console.error(
            'Error loading json data in openpages-search-index.json',
            err
          );
          return 'error';
        });
    }

    fuseSearchIndexPromise.then((_data) => {
      if (_data === 'error') {
        setSearchData(_data);
        return;
      }

      const idx = Fuse.parseIndex<PageSearchDocument>(
        _data.fuse[language].index
      );

      const _fuse = new Fuse<PageSearchDocument>(
        _data.fuse[language].documents,
        fuseOptions,
        idx
      );

      setSearchData({ fuse: _fuse, languageUsed: language });
    });

    return () => controller.abort();
  }, [language, siteMetaData, searchData, setSearchData]);

  return searchData;
};
