declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
    plausible: any;
  }
}

export type FrendeWebshopEvent =
  | 'new_offer_created'
  | 'order_completed'
  | 'order_completed_to_manual'
  | 'order_completed_with_customer_check_failed';

export const FRENDE_FORWARD_EVENTS_INCLUDING: string[] = [
  'order_',
  'new_offer_created',
];

export const frendifyEvent = (event: FrendeWebshopEvent) =>
  `frende_${event}` as `frende_${FrendeWebshopEvent}`;

export const UPDATE_GATSBY_ROUTE_EVENT = 'SSF_GATSBY_ROUTE_UPDATE';
export const FORM_SUBMISSION = 'formSubmission';
export const CHAT = 'chat';
export const APP_INSTALLATION_ACCEPTED = 'appInstallationAccepted';
export const APP_INSTALLATION_DISMISSED = 'appInstallationDismissed';
export const FAQ_ITEM_OPENED = 'faqItemOpened';
export const OFFICE_VISIT = 'office_visit';
export const BIG_LINK_TOP_TASK = 'bigLinkTopTask';
export const BIG_LINK_PRODUCT_LINK = 'bigLinkProductLink';
export const BIG_LINK_TOPIC_LINK = 'bigLinkTopicLink';
export const MENU_CLICK = 'menuClick';
export const SEARCH_MODAL = 'searchModal';
export const SEARCH_PAGE_CLICK = 'searchPageClick';
export const WHOLE_LINK_IMAGE_CLICK = 'wholeLinkImageClick';
export const FORM_EXIT_STEP = 'formExitStep';

// This function takes in the raw string from the customAnalyticsEvent field in SanityLink and formats it to match that of the other constants (if needed).
// export const dynamicallyCreateCustomAnalyticsEventString = (str: string): string => {
//   return str
//     .trim()
//     .toLowerCase()
//     .split(' ')
//     .map((item, i) => (i === 0 ? item : item[0].toUpperCase() + item.slice(1)))
//     .join('');
// };

export const triggerAnalyticsEvent = (_event: string, _details?: any) => {
  if (!process.env.GATSBY_ENABLE_PLAUSIBLE) return;

  if (_details)
    window && window.plausible && window.plausible(_event, { props: _details });
  else window && window.plausible && window.plausible(_event);
};

export const triggerPageView = () => {
  if (!process.env.GATSBY_ENABLE_PLAUSIBLE) return;
  //validatePlausibleInit();
  window && window.plausible && window.plausible('pageview');
};
