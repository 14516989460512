import { createGlobalStyle } from 'styled-components';
import { DarkBodyStyles } from './dark-impl/dark-theme';
import { LightBodyStyles } from './light-impl/light-theme';
import { ProfileBodyStyles } from './profile-impl/profile-manual-theme';

export const GlobalBodyStyles = createGlobalStyle`
  ${LightBodyStyles}
  ${DarkBodyStyles}
  ${ProfileBodyStyles}
`;
