import { useStaticQuery, graphql } from 'gatsby';
import get from 'lodash.get';
import { SiteMetaData } from '/src/types/core/site-metadata';

export const GRAPHQL_SITE_METADATA = graphql`
  {
    site {
      siteMetadata {
        nodeEnv
        siteUrl
        projectId
        dataset
        useCdn
        featureFlagEnvironment
        onlineBankUrl
        externalBackendUrl
        frendeJsUrl
        frendeCssUrl
      }
    }
  }
`;

//const _theQ = graphql(GRAPHQL_SITE_METADATA)

export const useSiteMetaData = (): SiteMetaData => {
  const data = useStaticQuery(GRAPHQL_SITE_METADATA);

  return get(data, 'site.siteMetadata');
};
