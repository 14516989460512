import { mediaBreakPoints } from '/src/sass/styled-constants';
import { ButtonTheme, css } from 'styled-components';
import { COLORS, lightButtonThemeImpl } from '@ssfrepo/ssf-react-components';

export const lightButtonThemeGatsbyImpl: ButtonTheme = {
  ...lightButtonThemeImpl,
  searchButton: {
    fg: COLORS.ssfBlue500,
    hoverBg: COLORS.ssfBlue100,
    stylingWithMediaBreakPoints: css`
      background: none;
      border: 1px solid ${COLORS.ssfBlue500};
      @media (max-width: ${mediaBreakPoints.sideMenu}) {
        border: 1px solid ${COLORS.ssfWhite};
      }
    `,
  },
  tabButton: {
    fg: COLORS.ssfBlue500,
    borderButtonSelected: COLORS.ssfBlue400,
    borderButtonNonSelected: COLORS.ssfBlue400,
  },
  readMoreButton: {
    spanColor: COLORS.ssfBlue400,
    fg: COLORS.ssfBlue400,
  },
  collapsible: {
    divider: '#b0c5d6',
    text: COLORS.ssfBlue400,
  },
};
