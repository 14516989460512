import { ExpendableStepByStepTheme } from 'styled-components';
import { COLORS } from '@ssfrepo/ssf-react-components';

export const lightExpandableStepByStepThemeImpl: ExpendableStepByStepTheme = {
  bg: COLORS.ssfWhite,
  iconBg: COLORS.ssfBlue100,
  iconColor: COLORS.ssfBlue500,
  hoverEffect: COLORS.ssfBlue400,
  text: COLORS.ssfBlue500,
  closeButton: COLORS.ssfBlue500,
};
