import 'styled-components';

import { CampaignArticleTheme, css, DefaultTheme } from 'styled-components';
import { pmBigLinkThemeImpl } from './big-link-profile-impl';
import { pmBlockContentThemeImpl } from './block-content-profile-impl';
import { pmThemeImpl } from './button-profile-impl';
import { pmChatBoxThemeImpl } from './chat-profile-impl';
import { pmCommonThemeGatsbyImpl } from './common-profile-impl';
import { pmBreadcrumbThemeImpl } from './breadcrumb-profile-impl';
import { pmOpenMenuButtonThemeImpl } from '../../../components/layout/menu/open-menu-button/theme/open-menu-button-profile-impl';
import { pmMenuGroupExpanderThemeImpl } from '../../../components/layout/menu/menu-group-expander/theme/menu-group-expander-profile-impl';
import { pmWpPostFrontSectionThemeImpl } from '../../../domains/meirennbank/meirennbank-post-picker/theme/wp-post-front-section-profile-impl';
import { pmWpPostCardThemeImpl } from '../../../domains/meirennbank/wp-post-card/theme/wp-post-card-profile-impl';
import {
  pmSSFScrollTertiaryThemeImpl,
  pmSSFScrollThemeImpl,
} from './ssf-scroll-profile-impl';
import { profilePieceSectionClusterThemeImpl } from '/src/components/content/sections/theme/piece-section-cluster-profile-impl';
import { profileFactsBoxThemeImpl } from '/src/components/content/facts-box/theme/facts-box-profile-impl';
import { CalculatorStyledThemeProfileImpl } from '/src/components/content/calculators/theme/calculator-styled-profile-impl';
import { faqThemeProfileImpl } from '/src/domains/faq-page/theme/faq-profile.impl';
import { linkListThemeProfileImpl } from '/src/components/content/link-list/theme/link-list-profile-impl';
import { COLORS, lightFormsThemeImpl } from '@ssfrepo/ssf-react-components';
import { SideButtonProfileThemeImpl } from '/src/components/layout/side-buttons/theme/side-button-profile-theme-impl';
import { employeeSetProfileThemeImpl } from '/src/components/content/employee-set/theme/employee-set-profile-theme-impl';
import { productPriceProfileThemeImpl } from '/src/domains/pricing/theme/product-price-profile-theme-impl';
import { stepByStepProfileThemeImpl } from '/src/components/content/step-by-step/theme/step-by-step-profile-theme-impl';
import { contactUsProfileThemeImpl } from '/src/domains/contact-us/components/theme/contact-us-profile-theme-impl';
import { searchProfileThemeImpl } from '/src/domains/search/components/theme/search-profile.impl';
import { richTableProfileThemeImpl } from '/src/components/content/rich-table/theme/rich-table-profile-theme-impl';
import { audioPlayerProfileThemeImpl } from '/src/components/content/kongleriket/theme/audio-player-profile-theme-impl';
import { frendeWebshopLightThemeImpl } from '/src/templates/template-theming/frende/frende-theme';
import { lightExpandableStepByStepThemeImpl } from '../../../components/content/expandable-step-by-step/theme/expandable-step-by-step-light-impl';

const themeId = 'profile';

export const ProfileBodyStyles = css`
  .${themeId} {
    --common-layout-main-bg: ${COLORS.ssfBackground};
    --common-layout-topbar-bg: ${COLORS.ssfGrey50};
    --common-layout-logo-image: url('/images/logo-light-mode.svg');
    --article-block-paragraph-color: ${COLORS.ssfGrey500};
    --common-layout-topbar-box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1);
    --common-layout-topbar-border-bottom: 1px solid ${COLORS.ssfGreyLight};
  }
`;

export const profileManualTheme: DefaultTheme = {
  themeId,
  buttons: pmThemeImpl,
  blockContent: pmBlockContentThemeImpl,
  common: pmCommonThemeGatsbyImpl,
  forms: lightFormsThemeImpl,
  chat: pmChatBoxThemeImpl,
  bigLink: pmBigLinkThemeImpl,
  breadcrumb: pmBreadcrumbThemeImpl,
  openMenuButton: pmOpenMenuButtonThemeImpl,
  menuGroupExpander: pmMenuGroupExpanderThemeImpl,
  wpPostFrontSection: pmWpPostFrontSectionThemeImpl,
  wpPostCard: pmWpPostCardThemeImpl,
  ssfScroll: pmSSFScrollThemeImpl,
  ssfScrollTertiary: pmSSFScrollTertiaryThemeImpl,
  campaignArticle: {} as CampaignArticleTheme,
  pieceSectionCluster: profilePieceSectionClusterThemeImpl,
  factsBox: profileFactsBoxThemeImpl,
  calculatorStyled: CalculatorStyledThemeProfileImpl,
  faq: faqThemeProfileImpl,
  linkList: linkListThemeProfileImpl,
  sideButton: SideButtonProfileThemeImpl,
  employeeSet: employeeSetProfileThemeImpl,
  productPrice: productPriceProfileThemeImpl,
  stepByStep: stepByStepProfileThemeImpl,
  contactUs: contactUsProfileThemeImpl,
  search: searchProfileThemeImpl,
  richTable: richTableProfileThemeImpl,
  audioPlayer: audioPlayerProfileThemeImpl,
  frendeWebshop: frendeWebshopLightThemeImpl,
  expandableStepByStep: lightExpandableStepByStepThemeImpl,
};
