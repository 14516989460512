import { PieceSectionClusterTheme } from 'styled-components';
import { COLORS } from '@ssfrepo/ssf-react-components';

export const lightPieceSectionClusterThemeImpl: PieceSectionClusterTheme = {
  alternateWhiteBg: COLORS.ssfWhite,
  alternateSandyBg: COLORS.ssfSandyBackground,
  alternateGreenBg: COLORS.kongleriketLightGreen,
  alternateBlueBg: COLORS.ssfBlue100,
  pieceSectionWhiteBg: COLORS.ssfWhite,
  pieceSectionSandyBg: COLORS.ssfSandyBackground,
  pieceSectionGreenBg: COLORS.kongleriketLightGreen,
  pieceSectionBlueBg: COLORS.ssfBlue100,
};
