import React from 'react';
import get from 'lodash.get';
import ConditionalLink from '../conditional-link';

import styled from 'styled-components';
import { COLORS } from '@ssfrepo/ssf-react-components';
import { SanityLink } from '@ssfrepo/ssf-sanity-utils';

type Props = {
  children: React.ReactNode;
  openInNewTab?: boolean;
};

const LinkText = styled.span`
  text-decoration: underline;
  color: ${({ theme }) => theme.common.layout.linkReferenceFg};
`;

export const NewTabLinkReference = (props: Props) => {
  return <LinkReference {...props} openInNewTab={true} />;
};

const LinkReference = (props: Props) => {
  const { children, openInNewTab } = props;
  const link: SanityLink = get(props, 'mark.reference');

  if (!link) {
    return <>{children}</>;
  }

  return (
    <ConditionalLink link={link} openInNewTab={openInNewTab}>
      <LinkText>{children}</LinkText>
    </ConditionalLink>
  );
};

export default LinkReference;
