import React from 'react';
import styled from 'styled-components';
import {
  COLORS,
  MAIN_MAX_WIDTH,
  MAIN_PADDING,
} from '/src/sass/styled-constants';
import { SanityMenuGrouping } from '/src/types/sanity/menu/menu-grouping';
import { getMappedComponentKey } from '/src/utils/gatsby-utils';
import { MenuGroupTitle } from './styled';
import { useSanityContentLocalizer } from '@ssfrepo/ssf-react-components';
import { MenuLink } from '../styled';
import ConditionalLink from '/src/components/core/conditional-link';
import { FlexColStartContainer } from '@ssfrepo/ssf-react-components';
import { fadeIn } from '/src/components/styled/animations';
import { ModalArrowConnector } from '/src/components/styled/decorations';
import { useLocationType } from '/src/hooks/use-location-type';
import { MENU_CLICK, triggerAnalyticsEvent } from '/src/utils/analytics-utils';

const MainMenu = styled.div`
  position: absolute;
  top: 97px;
  left: 0;
  width: 100%;
  height: auto;
  padding: 0;
  background: ${({ theme }) => theme.common.layout.menu.desktopMenuBg};
  overflow-y: visible;
  z-index: 1000;
  animation: ${fadeIn} 0.3s ease-out;
`;

const MenuListContainer = styled.div`
  position: relative;
  max-width: ${MAIN_MAX_WIDTH};
  width: 980px;
  padding: 50px ${MAIN_PADDING};

  &:after {
    left: 41px;
    ${ModalArrowConnector}
  }
`;

const MenuListInnerContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const MenuGroupingContainer = styled.div`
  flex: 1 0 0;
`;

const StyledMenuGroupTitle = styled(MenuGroupTitle)`
  margin: 0px 0px 10px 0px;
`;

const StyledLinkColumn = styled(FlexColStartContainer)`
  border-top: 1px solid ${COLORS.ssfBlue400};
`;

const MenuGroupLink = styled(ConditionalLink)`
  display: block;
  ${MenuLink}
  padding-right: 5px;
  margin: 0px 0px 10px 0px;
  :first-child {
    margin-top: 10px;
  }
`;

const MenuTypeTitle = styled.h4`
  color: ${COLORS.ssfBlue400};
  font-size: 36px;
  text-transform: uppercase;
  writing-mode: vertical-rl;
  transform: rotate(-180deg);
  display: flex;
  margin: ${MAIN_PADDING};
`;

const MenuItemsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

type Props = {
  onNavigation: () => void;
  menuGroupings: SanityMenuGrouping[];
};

const DesktopMenu = (props: Props) => {
  const { onNavigation, menuGroupings } = props;
  const localize = useSanityContentLocalizer();
  const customerType = useLocationType();

  return (
    <MainMenu>
      <MenuItemsContainer>
        <MenuTypeTitle>{customerType}</MenuTypeTitle>
        <MenuListContainer>
          <MenuListInnerContainer>
            {menuGroupings.map((menuGrouping, idx) => (
              <MenuGroupingContainer
                key={getMappedComponentKey(menuGrouping, idx)}
              >
                <StyledMenuGroupTitle>
                  {localize(menuGrouping.title)}
                </StyledMenuGroupTitle>
                <StyledLinkColumn>
                  {menuGrouping.links.map((link, idx) => (
                    <MenuGroupLink
                      key={idx}
                      link={link}
                      onClick={() => {
                        onNavigation();
                        triggerAnalyticsEvent(MENU_CLICK, {
                          Menu: link.title.nn,
                        });
                      }}
                    >
                      {localize(link.title)}
                    </MenuGroupLink>
                  ))}
                </StyledLinkColumn>
              </MenuGroupingContainer>
            ))}
          </MenuListInnerContainer>
        </MenuListContainer>
      </MenuItemsContainer>
    </MainMenu>
  );
};

export default DesktopMenu;
