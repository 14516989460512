import { COLORS } from '@ssfrepo/ssf-react-components';
import { BigLinkStyle, BigLinkTheme } from 'styled-components';

const iconSet: BigLinkStyle = {
  bg: 'transparent',
  fg: COLORS.ssfBlue500,
  border: COLORS.ssfBlue400,
  label: COLORS.ssfGrey500,
  header: COLORS.ssfGrey500,
  iconsize: '55px',
};

const kongleriketIconSet: BigLinkStyle = {
  bg: 'transparent',
  fg: COLORS.ssfBlue500,
  border: COLORS.ssfBlue400,
  label: COLORS.ssfBlack,
  header: COLORS.ssfBlack,
  iconsize: '55px',
};

const productLinks: BigLinkStyle = {
  bg: COLORS.ssfSandyGradient,
  fg: COLORS.ssfRedA700,
  hover: COLORS.ssfRed800,
  label: COLORS.ssfBlack,
  header: COLORS.ssfRedA700,
  border: COLORS.ssfRedA700,
  iconsize: '70px',
};

const topicLinks: BigLinkStyle = {
  bg: COLORS.ssfWhite,
  fg: COLORS.ssfRedBrown,
  border: COLORS.ssfRedBrown,
  label: COLORS.ssfGrey500,
  header: COLORS.ssfRedBrown,
  iconsize: '70px',
};

const topTaskLinks: BigLinkStyle = {
  bg: COLORS.ssfBlueGradient,
  fg: COLORS.ssfWhite,
  border: COLORS.ssfBlue300,
  label: COLORS.ssfWhite,
  header: COLORS.ssfWhite,
  iconsize: '70px',
};

export const pmBigLinkThemeImpl: BigLinkTheme = {
  iconSet,
  productLinks,
  topicLinks,
  topTaskLinks,
  kongleriketIconSet,
};
