import { COLORS } from '@ssfrepo/ssf-react-components';
import { SSFScrollTheme } from 'styled-components';

export const darkSSFScrollThemeImpl: SSFScrollTheme = {
  fg: COLORS.ssfBlue500,
  bg: COLORS.ssfBackground,
  border: COLORS.ssfBlue400,
  bgTrack: COLORS.ssfBlue100,
  bgThumb: COLORS.ssfBlue300,
  bgThumbSecondary: COLORS.ssfBlue100,
};

export const darkSSFScrollTertiaryThemeImpl: SSFScrollTheme = {
  bgTrack: COLORS.ssfBlue300,
  border: COLORS.ssfBlue400,
  bg: COLORS.ssfBlue100,
};
