import { ReactCompCommonTheme } from 'styled-components';
import { COLORS, darkCommonThemeImpl } from '@ssfrepo/ssf-react-components';

export const darkCommonThemeGatsbyImpl: ReactCompCommonTheme = {
  ...darkCommonThemeImpl,
  layout: {
    fg: COLORS.ssfBlue300,
    activeFg: COLORS.ssfBlue100,
    activeBorder: COLORS.ssfBlue400,
    linkReferenceFg: COLORS.ssfBlue100,
    menu: {
      desktopMenuBg: COLORS.ssfBlue900,
      menuGroupTitleColor: COLORS.ssfBlue100,
      menuLinkColor: COLORS.ssfBlue100,
    },
    footer: {
      footerWrapperBg: COLORS.ssfBlue900,
      languagePicker: COLORS.ssfBlue100,
      footerLinkColor: COLORS.ssfBlue100,
    },
    modal: {
      bg: COLORS.ssfBlue1000,
      calcModalBg: COLORS.ssfBlue1000,
      calcModalBorder: COLORS.ssfBlue900,
      closeBtnColorAndBorder: COLORS.ssfBlue100,
      header: COLORS.ssfBlue100,
      portalBg: 'transparent',
    },
  },
};
