import { SanityLink, SanityLocaleString } from '@ssfrepo/ssf-sanity-utils';

export const humanizePhoneNumber = (phone: string): string => {
  const stripped = phone.replace(/\s/g, '');
  if (stripped.length !== 8) {
    return stripped;
  }
  return `${stripped.substring(0, 3)} ${stripped.substring(
    3,
    5
  )} ${stripped.substring(5, 8)}`;
};

export const getPhoneNumberSanityLink = (phone: string): SanityLink => ({
  _id: '',
  title: {},
  url: `tel:${phone.replace(/\s/g, '')}`,
});

export const getEmailSanityLink = (email: string): SanityLink => ({
  _id: '',
  title: {},
  url: `mailto:${email}`,
});

export const shouldUsePrepositionOn = (
  currOfficeName: SanityLocaleString
): boolean =>
  currOfficeName.nn === 'Hardbakke' ||
  currOfficeName.nn === 'Kundesenter' ||
  currOfficeName.nn === 'Sandane';
