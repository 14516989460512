import { RichTableTheme } from 'styled-components';
import { COLORS } from '@ssfrepo/ssf-react-components';

export const richTableLightThemeImpl: RichTableTheme = {
  fg: COLORS.ssfGrey500,
  header: COLORS.ssfGrey50,
  headerBg: COLORS.ssfBlue400,
  rowOddbg: COLORS.ssfGrey50,
  rowEvenBg: COLORS.ssfBlue100,
  border: COLORS.ssfBlue400,
  styledLink: COLORS.ssfBlue500,
};
