import React from 'react';
import styled from 'styled-components';
import { MenuMarketLink, TopBarMarketLink } from '../styled';
import { SanityLink } from '@ssfrepo/ssf-sanity-utils';
import { useLocationType, LocationType } from '/src/hooks/use-location-type';
import { useSanityContentLocalizer } from '@ssfrepo/ssf-react-components';
import { useLocation } from '@reach/router';
import { SanityTopBar } from '/src/types/sanity/menu/topbar';

const MarketLinkContainer = styled.div``;
type Props = {
  topBar: SanityTopBar;
  styleType: 'menu' | 'topbar';
  className?: string;
};

const MarketLinks = (props: Props) => {
  const { topBar, styleType, className } = props;
  const {
    privateBankingRoute,
    businessBankingRoute,
    privateContactUs,
    businessContactUs,
    privateOurEmployees,
    businessOurEmployees,
    privateOurOffices,
    businessOurOffices,
    businessLogin,
    privateLogin,
  } = topBar;
  const customerType = useLocationType();
  const localize = useSanityContentLocalizer();
  const currentLocation = useLocation().pathname;

  const currentLocIsLink = (theLink: SanityLink | undefined): boolean =>
    currentLocation === theLink?.slug?.current;

  const correctMarketLinkPm =
    currentLocIsLink(businessLogin) || currentLocIsLink(privateLogin)
      ? privateLogin
      : currentLocIsLink(businessContactUs) ||
        currentLocIsLink(privateContactUs)
      ? privateContactUs
      : currentLocIsLink(businessOurEmployees) ||
        currentLocIsLink(privateOurEmployees)
      ? privateOurEmployees
      : currentLocIsLink(businessOurOffices) ||
        currentLocIsLink(privateOurOffices)
      ? privateOurOffices
      : privateBankingRoute;

  const correctMarketLinkBm =
    currentLocIsLink(businessLogin) || currentLocIsLink(privateLogin)
      ? businessLogin
      : currentLocIsLink(businessContactUs) ||
        currentLocIsLink(privateContactUs)
      ? businessContactUs
      : currentLocIsLink(businessOurEmployees) ||
        currentLocIsLink(privateOurEmployees)
      ? businessOurEmployees
      : currentLocIsLink(businessOurOffices) ||
        currentLocIsLink(privateOurOffices)
      ? businessOurOffices
      : businessBankingRoute;

  const MarketLink = styleType === 'menu' ? MenuMarketLink : TopBarMarketLink;

  return (
    <MarketLinkContainer className={className}>
      {privateBankingRoute && (
        <MarketLink
          link={correctMarketLinkPm}
          isActive={customerType === LocationType.Private}
        >
          {localize(privateBankingRoute.title)}
        </MarketLink>
      )}
      {businessBankingRoute && (
        <MarketLink
          link={correctMarketLinkBm}
          isActive={customerType === LocationType.Business}
        >
          {localize(businessBankingRoute.title)}
        </MarketLink>
      )}
    </MarketLinkContainer>
  );
};

export default MarketLinks;
