import React, { useEffect, useState } from 'react';
import ChatLoader from './chat-loader';

export const EVENT_CHAT_OPEN = 'ssfChatOpen';

const ChatLoaderLoader = (props) => {
  const [state, setState] = useState({
    activated: false,
    open: false,
  });

  const { activated, open } = state;
  useEffect(() => {
    const onChatOpen = () => {
      setState((s) => ({ open: true, activated: true }));
    };

    window.addEventListener(EVENT_CHAT_OPEN, onChatOpen);
    return () => window.removeEventListener(EVENT_CHAT_OPEN, onChatOpen);
  }, []);

  return (
    <>
      {activated && (
        <ChatLoader
          isChatOpen={open}
          onClose={() => setState((s) => ({ ...s, open: false }))}
        />
      )}
    </>
  );
};

export default ChatLoaderLoader;
