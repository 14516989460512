import { ContactUsTheme } from 'styled-components';
import { COLORS } from '@ssfrepo/ssf-react-components';

export const contactUsProfileThemeImpl: ContactUsTheme = {
  officeListHeader: COLORS.ssfGrey500,
  officeButtonFg: COLORS.ssfBlue500,
  searchHeader: COLORS.ssfGrey500,
  searchInputBg: COLORS.ssfGrey100,
  searchInputButtonFg: COLORS.ssfBlue900,
  searchInputButtonBg: COLORS.ssfGrey100,
  searchInputButtonFocusBg: COLORS.ssfBlue100,
  searchResultTitle: COLORS.ssfGrey500,
  employeeRouteButtonFg: COLORS.ssfGrey500,
  employeeContactInfoLink: COLORS.ssfBlue500,
  employeeRouteButtonBg: COLORS.ssfWhite,
  employeeRouteButtonBorder: COLORS.ssfSandyBackground,
  employeeRouteButtonBorderHover: COLORS.ssfRedA700,
  employeeRouteButtonBorderFocus: COLORS.ssfRedA700,
  categoryTitle: COLORS.ssfGrey,
};
