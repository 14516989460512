import { SearchTheme } from 'styled-components';
import { COLORS } from '@ssfrepo/ssf-react-components';

export const searchDarkThemeImpl: SearchTheme = {
  modalBgAndAfterBottomBorder: COLORS.ssfBlue900,
  searchInputAndModalButtonBg: COLORS.ssfBlue1000,
  searchInputAndModalButtonFg: COLORS.ssfBlue100,
  searchInputAndModalButtonBorder: COLORS.ssfBlue400,
  searchInputCaretColor: COLORS.ssfBlue100,
  searchInputPlaceholderColor: COLORS.ssfGrey300,
  searchResultHeader: COLORS.ssfBlue300,
  searchResultParagraph: COLORS.ssfBlue100,
  searchResultLocationParagraph: COLORS.ssfGrey300,
};
