import React from 'react';
import Layout from './layout';
import { LocationTypeProvider } from '/src/hooks/use-location-type';
import { LayoutVariantProvider } from '/src/hooks/layout-variant-provider';
import { OnlineLoginProvider } from '/src/hooks/use-online-login';
import { LanguageProvider } from '@ssfrepo/ssf-react-components';

const wrapPageElement = ({ element, props }) => {
  const { location } = props;

  return (
    <LanguageProvider location={location}>
      <LocationTypeProvider location={location}>
        <LayoutVariantProvider location={location}>
          <OnlineLoginProvider location={location}>
            <Layout {...props}>{element}</Layout>
          </OnlineLoginProvider>
        </LayoutVariantProvider>
      </LocationTypeProvider>
    </LanguageProvider>
  );
};

export default wrapPageElement;
