import { COLORS } from '@ssfrepo/ssf-react-components';
import { SSFScrollTheme } from 'styled-components';

export const lightSSFScrollThemeImpl: SSFScrollTheme = {
  fg: COLORS.ssfBlue500,
  bg: COLORS.ssfBackground,
  border: COLORS.ssfBlue400,
  bgTrack: COLORS.ssfWhite,
  bgThumb: COLORS.ssfBlue300,
  bgThumbSecondary: COLORS.ssfWhite,
};

export const lightSSFScrollTertiaryThemeImpl: SSFScrollTheme = {
  bgTrack: COLORS.ssfBlue300,
  border: COLORS.ssfBlue400,
  bg: COLORS.ssfWhite,
};
