/* eslint-disable no-unused-vars */
import React, { createContext, useContext } from 'react';

// eslint-disable-next-line no-shadow
export enum LocationType {
  Private = 'privat',
  Business = 'bedrift',
  ProfileManual = 'pm',
  Ssf = 'ssf',
  Kongleriket = 'kongleriket',
  Sparebankstiftinga = 'sparebankstiftinga',
  Eigedomsmekling = 'eigedomsmekling',
}

export const LocationTypeContext = createContext(LocationType.Private);

const BUSINESS_ROUTES_PREFIX = '/bedrift';
const PM_ROUTES_PREFIX = '/profilhandbok';
const SSF_ROUTES_PREFIX = '/profilhandbok/sparebanken';
const KONGLERIKET_ROUTES_PREFIX = '/profilhandbok/kongleriket';
const SPAREBANKSTIFTNGA_ROUTES_PREFIX = '/profilhandbok/sparebankstiftinga';
const EIGEDOMSMEKLING_ROUTES_PREFIX = '/profilhandbok/eigedomsmekling';

export const getLocationType = (
  location: string | Location | undefined
): LocationType => {
  let loc = '';
  if (typeof location === 'string') {
    loc = location;
  } else if (location && typeof location.pathname === 'string') {
    loc = location.pathname;
  } else {
    loc = '/';
  }
  return loc.startsWith(BUSINESS_ROUTES_PREFIX)
    ? LocationType.Business
    : loc.startsWith(SSF_ROUTES_PREFIX)
    ? LocationType.Ssf
    : loc.startsWith(EIGEDOMSMEKLING_ROUTES_PREFIX)
    ? LocationType.Eigedomsmekling
    : loc.startsWith(SPAREBANKSTIFTNGA_ROUTES_PREFIX)
    ? LocationType.Sparebankstiftinga
    : loc.startsWith(KONGLERIKET_ROUTES_PREFIX)
    ? LocationType.Kongleriket
    : loc.startsWith(PM_ROUTES_PREFIX)
    ? LocationType.ProfileManual
    : LocationType.Private;
};

export const LocationTypeProvider = ({ location, children }) => (
  <LocationTypeContext.Provider value={getLocationType(location)}>
    {children}
  </LocationTypeContext.Provider>
);

export const useLocationType = () => useContext(LocationTypeContext);
