import React from 'react';
import styled from 'styled-components';
import { COLORS, mediaBreakPoints } from '/src/sass/styled-constants';
import LanguagePicker from './language-picker';
import {
  useSanityContentLocalizer,
  SanityContentLocalize,
} from '@ssfrepo/ssf-react-components';
import FontIcon from '@ssfrepo/ssf-react-components/lib/buttons/font-icon';
import { MaxWidthSection } from '/src/components/styled/max-width-section';
import ConditionalLink from '/src/components/core/conditional-link';
import { MenuLink } from '../styled';
import BlockContentWrapper from '/src/components/core/block-content/block-content-wrapper';

import { getMappedComponentKey } from '/src/utils/gatsby-utils';
import { useTemplateAgnosticCache } from '/src/hooks/use-template-agnostic-cache';
import { fontNormalish } from '/src/sass/typography/typography-styled';
import { ThemeSwitch } from './theme-switch';

const PickContainer = styled.div`
  width: 100%;
  margin-top: 25px;
  display: flex;
  flex-direction: row;

  @media (max-width: ${mediaBreakPoints.sideMenu}) {
    margin-top: 15px;
  }
`;

const FooterWrapper = styled.footer`
  position: relative;
  width: 100%;
  background-color: ${({ theme }) =>
    theme.common.layout.footer.footerWrapperBg};
  display: block;
  flex-shrink: 0;
  &:after {
    display: block;
    position: absolute;
    bottom: 100%;
    left: 0;
    content: '';
    width: 100%;
    height: 100px;
    border-bottom: 4vw solid
      ${({ theme }) => theme.common.layout.footer.footerWrapperBg};
    border-left: calc(100vw - 20px) solid transparent;
    box-sizing: border-box;
    pointer-events: none;
    user-select: none;
  }
`;

const FooterSection = styled(MaxWidthSection)`
  padding: 20px 20px 0px 20px;
`;

const FooterContentMain = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  height: auto;
  margin: 20px 0;
  justify-content: space-between;
  align-items: flex-start;
  color: ${({ theme }) => theme.common.layout.footer.footerLinkColor};

  @media (max-width: ${mediaBreakPoints.sideMenu}) {
    flex-direction: column;
    justify-content: flex-start;
    align-content: flex-start;
  }
`;

const FooterContentMainLeft = styled.div`
  width: 48%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;

  @media (max-width: ${mediaBreakPoints.md}) {
    width: 58%;
  }
  @media (max-width: ${mediaBreakPoints.sm}) {
    width: 80%;
  }
  @media (max-width: ${mediaBreakPoints.iPhonePlus}) {
    width: 100%;
  }
  @media (max-width: 280px) {
    font-size: 14px;
  }
`;

const FooterContentLeftLinks = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  padding-top: 57px;

  @media (max-width: ${mediaBreakPoints.sideMenu}) {
    padding: 40px 0 20px;
  }
`;

const FooterContentLeftLinkCol = styled.div`
  width: 50%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: center;
  :not(:first-child) {
    margin-left: 85px;
    @media (max-width: ${mediaBreakPoints.sideMenu}) {
      margin-left: 0;
    }
  }
  @media (max-width: ${mediaBreakPoints.iPhonePlus}) {
    font-size: 14px;
  }
`;

const FooterContentMainRight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;

  margin-top: -29px;
  @media (max-width: ${mediaBreakPoints.sideMenu}) {
    margin-top: 0px;
  }
`;

const FooterLink = styled(ConditionalLink)`
  ${MenuLink}
  display: inline-block;
  text-align: start;
  margin: 3px 0;
  @media (max-width: ${mediaBreakPoints.sideMenu}) {
    margin: 5px 0;
  }
`;

const RightRichText = styled(BlockContentWrapper)`
  .block-container {
    h1,
    h2,
    h3,
    h4,
    div.block-paragraph,
    span {
      color: ${({ theme }) => theme.common.layout.footer.footerLinkColor};
      text-align: end;
      @media (max-width: ${mediaBreakPoints.sideMenu}) {
        text-align: start;
      }
    }
    .block-paragraph:first-child {
      margin: 15px 0 9px;
    }
    .block-paragraph:nth-child(2) {
      height: 40px;
      display: inline-flex;
      align-items: center;
      margin: 0;
    }
    .block-paragraph:last-child {
      margin: 53px 0 0;
      font-size: 14px;
    }
    .block-paragraph a {
      margin: 0 10px;
    }
    .block-paragraph a:nth-child(2) {
      margin-right: 0;
    }
    .block-paragraph:last-child a {
      margin: 0 0 0 2px;
    }

    @media (max-width: ${mediaBreakPoints.sideMenu}) {
      .block-paragraph:first-child {
        margin: 20px 0 9px;
      }
      .block-paragraph:last-child {
        margin: 40px 0 0;
      }
      .block-paragraph a:first-child {
        margin-left: 0;
      }
    }
    @media (max-width: ${mediaBreakPoints.sm}) {
      .block-paragraph:first-child {
        font-size: 14px;
      }
      .block-paragraph a {
        margin: 0 5px;
      }
    }
    @media (max-width: 280px) {
      .block-paragraph a {
        margin: 5px 0;
      }
    }
  }
`;

const FooterContentSocial = styled.div`
  margin: 20px 0;
  border-top: 1px solid ${COLORS.ssfBlue400};
`;

const StyledFontIcon = styled(FontIcon)`
  display: inline-block;
  vertical-align: middle;
  color: ${({ theme }) =>
    theme.common.layout.footer.footerLinkColor} !important;
  font-size: 45px !important;

  & + span {
    margin-left: 5px;
  }
`;

const SocialLinksList = styled.ul`
  list-style: none;
  padding: 0;
  display: flex;
  flex-flow: row wrap;
  place-content: center space-between;
`;

const SocialLinkContainer = styled.li``;

const SocialLinkText = styled.span`
  @media (max-width: ${mediaBreakPoints.sm}) {
    display: none;
  }
`;

const SocialLink = styled.a`
  color: ${({ theme }) => theme.common.layout.footer.footerLinkColor};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const ToTopDesktopButton = styled.button`
  position: absolute;
  top: -62px;
  left: calc(50% - 75px);
  background: none;
  color: ${({ theme }) => theme.common.layout.footer.footerLinkColor};
  border: none;
  white-space: nowrap;
  display: flex;
  align-items: center;
  &:hover {
    cursor: pointer;
    transition: all 0.2s ease;
  }
`;

const ButtonText = styled.p`
  ${fontNormalish};
  color: ${({ theme }) => theme.common.layout.footer.footerLinkColor};
  text-align: left;
  text-decoration: underline;
`;

const renderFooterLink = (localize: SanityContentLocalize) => {
  const FootLink = (link, idx) => (
    <FooterLink key={getMappedComponentKey(link, idx)} link={link}>
      {localize(link.title)}
    </FooterLink>
  );
  return FootLink;
};

export type FooterProps = {
  isDesktop: boolean;
  onClick: () => void;
};

export const Footer = (props: FooterProps) => {
  const { isDesktop, onClick } = props;
  const localize = useSanityContentLocalizer();
  const cache = useTemplateAgnosticCache();
  const footerData = cache.footer();
  if (!footerData) {
    return null;
  }
  const { footerLinksLeft, footerLinksRight, socialLinks, rightRichText } =
    footerData;

  return (
    <FooterWrapper>
      <FooterSection>
        <FooterContentMain>
          <FooterContentMainLeft>
            <PickContainer>
              <LanguagePicker />
              <ThemeSwitch />
            </PickContainer>

            <FooterContentLeftLinks>
              <FooterContentLeftLinkCol>
                {footerLinksLeft.map(renderFooterLink(localize))}
              </FooterContentLeftLinkCol>
              <FooterContentLeftLinkCol>
                {footerLinksRight.map(renderFooterLink(localize))}
              </FooterContentLeftLinkCol>
            </FooterContentLeftLinks>
          </FooterContentMainLeft>
          {isDesktop && (
            <ToTopDesktopButton onClick={onClick}>
              <ButtonText>
                {localize(cache.translate('common.toTop'))}
              </ButtonText>
            </ToTopDesktopButton>
          )}
          <FooterContentMainRight>
            <RightRichText blockContent={localize(rightRichText)} />
          </FooterContentMainRight>
        </FooterContentMain>

        <FooterContentSocial>
          <SocialLinksList>
            {socialLinks.map((link) => (
              <SocialLinkContainer key={localize(link.title)}>
                <SocialLink href={link.url} target="_blank">
                  {link.icon && (
                    <StyledFontIcon iconClassName={link.icon.icon} />
                  )}
                  <SocialLinkText>{localize(link.title)}</SocialLinkText>
                </SocialLink>
              </SocialLinkContainer>
            ))}
          </SocialLinksList>
        </FooterContentSocial>
      </FooterSection>
    </FooterWrapper>
  );
};
