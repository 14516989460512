import { SanityClientConfig } from '@ssfrepo/ssf-sanity-utils';
import groq from 'groq';
import { PageSearchDocImplResult, queryDocs } from '..';
import { PageSearchDocument } from '/src/types/core/search';
import { SanityPage } from '/src/types/sanity/core/sanity-page';

export const GROQ_FRAGMENT_DEFAULT_PAGE = groq`
title,
route-> {
  slug {
    current
  }
},
seo {
  metaDescription,
  noIndex,
  nnSearchKeywords,
  enSearchKeywords,
  hideInternalSearch
}
`;

const GROQ_QUERY_ALL_DEFAULT_PAGES = groq`
{
  'singletonCorporateGovPage': *[_type == 'singletonCorporateGovPage']{
      ${GROQ_FRAGMENT_DEFAULT_PAGE}
  },
  'singletonOpenLoginPage': *[_type == 'singletonOpenLoginPage']{
      ${GROQ_FRAGMENT_DEFAULT_PAGE}
  },
  'singletonOpenLoginPageBusiness': *[_type == 'singletonOpenLoginPageBusiness']{
      ${GROQ_FRAGMENT_DEFAULT_PAGE}
  },
  'singletonFrendeWebshopPage': *[_type == 'singletonFrendeWebshopPage']{
      ${GROQ_FRAGMENT_DEFAULT_PAGE}
  },
  'singletonContactPage': *[_type == 'singletonContactPage']{
      ${GROQ_FRAGMENT_DEFAULT_PAGE}
  },
  'singletonContactBusinessPage': *[_type == 'singletonContactBusinessPage']{
      ${GROQ_FRAGMENT_DEFAULT_PAGE}
  },
  'singletonKongleriketPage': *[_type == 'singletonKongleriketPage']{
      ${GROQ_FRAGMENT_DEFAULT_PAGE}
  },
  'singletonMeirEnnBankPage': *[_type == 'singletonMeirEnnBankPage']{
      ${GROQ_FRAGMENT_DEFAULT_PAGE}
  },
  'meirEnnBankArticlePages': *[_type == 'meirEnnBankArticlePage']{
    ${GROQ_FRAGMENT_DEFAULT_PAGE}
  }
}
`;

type DefaultPagesQueryResult = {
  singletonCorporateGovPage: SanityPage[];
  singletonOpenLoginPage: SanityPage[];
  singletonOpenLoginPageBusiness: SanityPage[];
  singletonFrendeWebshopPage: SanityPage[];
  singletonContactPage: SanityPage[];
  singletonContactBusinessPage: SanityPage[];
  singletonKongleriketPage: SanityPage[];
  singletonMeirEnnBankPage: SanityPage[];
  meirEnnBankArticlePages: SanityPage[];
};

export const getGenericPageSearchDoc = (
  defaultPage: SanityPage,
  language: string
): PageSearchDocument | null => {
  const path = defaultPage.route.slug?.current;
  if (!defaultPage.title[language] || !path) return null;

  const seo = defaultPage?.seo;
  const searchKeywords =
    (language === 'en'
      ? seo?.enSearchKeywords
      : language === 'nn'
      ? seo?.nnSearchKeywords
      : undefined) || [];

  return {
    title: defaultPage.title[language],
    path,
    subtitles: [],
    searchKeywords,
    description: defaultPage?.seo?.metaDescription || '',
    hideInternalSearch: defaultPage?.seo?.hideInternalSearch || false,
  };
};

export const searchableGenericPage = async (
  sanityConfig: SanityClientConfig
): Promise<PageSearchDocImplResult> => {
  const queryResult = await queryDocs<DefaultPagesQueryResult>(
    sanityConfig,
    GROQ_QUERY_ALL_DEFAULT_PAGES
  );

  if (queryResult === 'error') {
    console.error('Query failed for searchableGenericPage');
    return 'error';
  }
  const nnDocuments: PageSearchDocument[] = [];
  const enDocuments: PageSearchDocument[] = [];
  const pushMore = (fromQueryData: SanityPage[]) => {
    fromQueryData.forEach((_data) => {
      const nn = getGenericPageSearchDoc(_data, 'nn');
      if (nn) nnDocuments.push(nn);

      const en = getGenericPageSearchDoc(_data, 'en');
      if (en) enDocuments.push(en);
    });
  };
  Object.values(queryResult).forEach(pushMore);

  return {
    nnDocuments,
    enDocuments,
  };
};
