import Form from '@ssfrepo/ssf-react-components/lib/forms/form';
import { SanityForm } from '@ssfrepo/ssf-sanity-utils';
import EmployeeDetails from '/src/domains/contact-us/components/employee-details';
import { useSiteMetaData } from '/src/hooks/use-site-metadata';
import { useTemplateAgnosticCache } from '/src/hooks/use-template-agnostic-cache';
import React, { useCallback, useState } from 'react';
import {
  triggerAnalyticsEvent,
  FORM_SUBMISSION,
  FORM_EXIT_STEP,
  UPDATE_GATSBY_ROUTE_EVENT,
} from '/src/utils/analytics-utils';

type Props = {
  form: SanityForm;
  className?: string;
};

const FormWrapper = (props: Props) => {
  const { form, className } = props;
  const [step, setStep] = useState<number>(0);

  const siteMetadata = useSiteMetaData();
  const cache = useTemplateAgnosticCache();

  const onSubmitResult = useCallback(() => {
    triggerAnalyticsEvent(FORM_SUBMISSION, { title: form.title });
  }, [form]);

  const _translate = useCallback(
    (_key: string) => {
      return cache.translate(_key);
    },
    [cache]
  );

  const onFormStepChange = useCallback(
    (currentFormStep: number) => {
      setStep(currentFormStep);
      const onFormExit = () => {
        if (step > 0) {
          triggerAnalyticsEvent(FORM_EXIT_STEP, {
            form: form.title,
            step: step,
          });
        }
      };
      document.addEventListener(UPDATE_GATSBY_ROUTE_EVENT, onFormExit);
    },
    [form, step]
  );

  const onSubmit = `${siteMetadata.externalBackendUrl}/api/public/form`;
  return (
    <Form
      className={className}
      form={form}
      sanityClientConfig={siteMetadata}
      translate={_translate}
      countries={cache.countries()}
      onSubmit={onSubmit}
      Peep={EmployeeDetails}
      onSubmitResult={onSubmitResult}
      onFormStepChange={onFormStepChange}
    />
  );
};

export default FormWrapper;
