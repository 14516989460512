import React from 'react';
import { SanityOpenPagesFeatureFlags } from '/src/types/sanity/core/sanity-feature-flags';
import { createContext, useContext, useState, useEffect } from 'react';
import { useSiteMetaData } from './use-site-metadata';

import { FeatureFlagEnvironment } from '/src/types/core/site-metadata';
import { querySanity } from '@ssfrepo/ssf-sanity-utils';

export type OpenPagesFeatureFlagsKey =
  | 'publicMail'
  | 'businessChatBox'
  | 'privateChatBox'
  | 'externalFormBackend'
  | 'darkModeHackSwitch';

export interface FeatureFlagContextData {
  openFlags: SanityOpenPagesFeatureFlags | null | undefined;
  env: FeatureFlagEnvironment;
}

export const FeatureFlagContext = createContext<FeatureFlagContextData>({
  openFlags: undefined,
  env: 'live',
});

export const FeatureFlagProvider = ({ children }) => {
  const [openFlags, setFlags] = useState<
    SanityOpenPagesFeatureFlags | null | undefined
  >(undefined);
  const siteMetaData = useSiteMetaData();
  useEffect(() => {
    querySanity<SanityOpenPagesFeatureFlags>(
      "*[_type == 'singletonOpenPagesFeatureFlags'][0]",
      siteMetaData
    ).then((result) => {
      if (setFlags) {
        setFlags(result);
      }
    });
  }, [siteMetaData]);

  return (
    <FeatureFlagContext.Provider
      value={{ openFlags, env: siteMetaData.featureFlagEnvironment }}
    >
      {children}
    </FeatureFlagContext.Provider>
  );
};

export const useFeatureFlag = (key: OpenPagesFeatureFlagsKey) => {
  const ctx = useContext(FeatureFlagContext);
  if (ctx) {
    const { env, openFlags } = ctx;
    if (openFlags && openFlags.flags) {
      const flag = openFlags.flags.find((f) => f.flag === key);
      if (flag) {
        return flag.environments.includes(env);
      }
    }
  }
  return false;
};
