import React, { useState } from 'react';
import styled from 'styled-components';
import { fadeIn } from '/src/components/styled/animations';
import SearchInput from './search-input';
import { ModalArrowConnector } from '/src/components/styled/decorations';
import { useSanityContentLocalizer } from '@ssfrepo/ssf-react-components';
import { useTemplateAgnosticCache } from '/src/hooks/use-template-agnostic-cache';
import { useFocusOnEntry } from '@ssfrepo/ssf-react-components';
import { SanityLink } from '@ssfrepo/ssf-sanity-utils';
import { navigate } from 'gatsby';
import { useOnClickOutside } from '@ssfrepo/ssf-react-components';
import {
  SEARCH_MODAL,
  triggerAnalyticsEvent,
} from '/src/utils/analytics-utils';

const Anchor = styled.div`
  position: relative;
`;

const Container = styled.div`
  position: absolute;
  top: 33px;
  left: -47px;
  padding: 40px 20px;
  border-radius: 0px 0px 8px 8px;
  background: ${({ theme }) => theme.search.modalBgAndAfterBottomBorder};
  z-index: 1000;
  animation: ${fadeIn} 0.3s ease-out;

  &:after {
    left: 70px;
    ${ModalArrowConnector}
  }
`;

const StyledSearchInput = styled(SearchInput)`
  width: 315px;
`;

export const searchPageLink: SanityLink = {
  _id: '',
  title: {},
  slug: { current: '/sok' },
};

const onSearch = (searchQuery: string) => {
  navigate('/sok', { state: { searchQuery } });
};

type Props = {
  onNavigation: () => void;
};

const SearchModal = (props: Props) => {
  const { onNavigation } = props;
  const localize = useSanityContentLocalizer();
  const cache = useTemplateAgnosticCache();
  const inputRef = useFocusOnEntry<HTMLInputElement>();
  const outSideClickRef = useOnClickOutside(() => onNavigation(), []);
  const [query, setQuery] = useState('');
  const path = window.location.pathname;

  return (
    <>
      <Anchor>
        <Container ref={outSideClickRef}>
          <StyledSearchInput
            name="searcher"
            ref={inputRef}
            value={query}
            placeholder={localize(cache.translate('common.writeYourSearch'))}
            suffixText={localize(cache.translate('common.search'))}
            onChange={(newQuery) => {
              setQuery(newQuery);
            }}
            onSearchClick={() => {
              onNavigation();
              onSearch(query);
              triggerAnalyticsEvent(SEARCH_MODAL, {
                searchWord: query,
                path: path,
              });
            }}
          />
        </Container>
      </Anchor>
    </>
  );
};

export default SearchModal;
