import React from 'react';
import get from 'lodash.get';
import { SanityLink, SanityImageExtras } from '@ssfrepo/ssf-sanity-utils';
import SanityGatsbyImage from '../images/sanity-gatsby-image';
import styled from 'styled-components';
import ConditionalLink from '../conditional-link';

const StyledLink = styled(ConditionalLink).attrs(
  ({ desiredwidth }: { desiredwidth: number }) => ({ desiredwidth })
)`
  display: inline-block;
  width: ${(props) => props.desiredwidth}px;
  margin: 0 5px;
`;

const HEIGHT = 40;

export const InlineImage = (props) => {
  const image: SanityImageExtras = get(props, 'mark.image');
  const link: SanityLink = get(props, 'mark.link');
  if (!image) {
    return props.children;
  }
  const ratio = get(image, 'asset.metadata.dimensions.aspectRatio', 3);
  const width = ratio * HEIGHT;

  if (link) {
    return (
      <StyledLink desiredwidth={width} link={link}>
        <SanityGatsbyImage imageExtras={image} />
      </StyledLink>
    );
  }

  return <SanityGatsbyImage imageExtras={image} />;
};
