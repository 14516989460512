import React from 'react';
import styled from 'styled-components';
import slimObjectHash from 'slim-object-hash';
import { useSanityContentLocalizer } from '@ssfrepo/ssf-react-components';
import { COLORS } from '@ssfrepo/ssf-react-components';
import { SanityStepByStep } from '/src/types/sanity/content/step-by-step/step-by-step';
import {
  fontHeaderFourish,
  fontHeaderThreeish,
} from '/src/sass/typography/typography-styled';

const Steps = styled.div`
  margin: 40px 0 20px;
  padding-bottom: 20px;
`;

const Step = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 7px;
`;

const StepNumber = styled.div.attrs(({ hasArrow }: { hasArrow: boolean }) => ({
  hasArrow,
}))`
  display: flex;
  min-width: 156px;
  height: 50px;
  background: ${({ theme }) => theme.stepByStep.stepNumberBg};
  border: 3px solid ${({ theme }) => theme.stepByStep.stepNumberBorder};
  box-sizing: border-box;
  border-radius: 30px;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.stepByStep.fg};
  ${fontHeaderThreeish}
  position: relative;

  &:after {
    position: absolute;
    bottom: -22px;
    content: '';
    display: ${({ hasArrow }) => (hasArrow ? 'block' : 'none')};
    width: 0;
    height: 0;
    border-left: 28px solid transparent;
    border-right: 28px solid transparent;
    border-top: 20px solid
      ${({ theme }) => theme.stepByStep.stepNumberAfterBorder};
  }

  @media (max-width: 700px) {
    min-width: 120px;
  }
`;

const StepContent = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  ${fontHeaderFourish};
  background: ${({ theme }) => theme.stepByStep.stepContentBg};
  color: ${({ theme }) => theme.stepByStep.fg};
  border-radius: 8px;
  padding: 20px;
  margin-left: 30px;
  line-height: 22px;

  @media (max-width: 700px) {
    padding-left: 10px;
    font-size: 13px;
    line-height: 20px;
  }
`;

type Props = {
  stepByStep: SanityStepByStep;
};

export const StepByStep = (props: Props) => {
  const { stepByStep } = props;
  const { steps } = stepByStep;
  const localize = useSanityContentLocalizer();

  return (
    <Steps>
      {steps &&
        steps.map((step, idx) => (
          <Step key={`${slimObjectHash(step)}_${idx}`}>
            <StepNumber hasArrow={idx + 1 !== steps.length}>
              {idx + 1}
            </StepNumber>
            <StepContent>{localize(step)}</StepContent>
          </Step>
        ))}
    </Steps>
  );
};
