import { COLORS } from '@ssfrepo/ssf-react-components';
import { BigLinkStyle, BigLinkTheme } from 'styled-components';

const iconSet: BigLinkStyle = {
  bg: 'transparent',
  fg: COLORS.ssfBlue400,
  border: COLORS.ssfBlue400,
  label: COLORS.ssfBlue100,
  header: COLORS.ssfBlue100,
  iconsize: '55px',
};
const kongleriketIconSet: BigLinkStyle = {
  bg: 'transparent',
  fg: COLORS.kongleriketMiddleGreen,
  border: COLORS.ssfBlue400,
  label: COLORS.ssfGreen50,
  header: COLORS.ssfGreen50,
  iconsize: '55px',
};

const productLinks: BigLinkStyle = {
  bg: COLORS.ssfDarkRedGradiant,
  fg: COLORS.ssfOrange50,
  hover: COLORS.ssfRedBRonse,
  label: COLORS.ssfOrange50,
  header: COLORS.ssfOrange50,
  border: COLORS.ssfRedA700,
  iconsize: '70px',
};
const topicLinks: BigLinkStyle = {
  bg: COLORS.ssfBlue900,
  fg: COLORS.ssfBlue400,
  border: COLORS.ssfBlue400,
  hover: COLORS.ssfBlue300,
  label: COLORS.ssfBlue100,
  header: COLORS.ssfBlue100,
  iconsize: '70px',
};

const topTaskLinks: BigLinkStyle = {
  bg: COLORS.ssfBlueGradient,
  fg: COLORS.ssfGrey50,
  border: COLORS.ssfBlue300,
  label: COLORS.ssfGrey50,
  header: COLORS.ssfGrey50,
  iconsize: '70px',
};

export const darkBigLinkThemeImpl: BigLinkTheme = {
  iconSet,
  kongleriketIconSet,
  productLinks,
  topicLinks,
  topTaskLinks,
};
