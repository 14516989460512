import { mediaBreakPoints } from '@ssfrepo/ssf-react-components';
import React from 'react';
import styled from 'styled-components';
import { SanityVideo } from '/src/types/sanity/content/video';

const IframeWrapper = styled.div`
  position: relative;
  overflow: hidden;
  height: 600px;
  max-width: 100%;
  padding-bottom: 20px;
  @media (max-width: ${mediaBreakPoints.sm}) {
    padding-bottom: 0%;
    height: 300px;
  }
`;

const StyledIframe = styled.iframe`
  border: 0;
  border-radius: 8px;
`;

type Props = {
  className?: string;
  media: SanityVideo;
};

export const CastIFrame = (props: Props) => {
  const { media } = props;
  const { dacastShareLink } = media;

  return (
    <IframeWrapper>
      <StyledIframe
        src={dacastShareLink}
        width="100%"
        height="100%"
        allow="autoplay; fullscreen"
        allowFullScreen
      />
    </IframeWrapper>
  );
};
