import React from 'react';
import styled, { css } from 'styled-components';
import { SanityLink } from '@ssfrepo/ssf-sanity-utils';
import ConditionalLink from '/src/components/core/conditional-link';
import { useSanityContentLocalizer } from '@ssfrepo/ssf-react-components';
import { COLORS } from '@ssfrepo/ssf-react-components';
import { fontHeaderFourish } from '/src/sass/typography/typography-styled';
import FontIcon from '@ssfrepo/ssf-react-components/lib/buttons/font-icon';

export const ArrowIconStyle = css`
  color: ${({ theme }) => theme.buttons.readMoreButton.fg} !important;
  font-size: 20px !important;
  padding: 5px;
  margin-left: 5px;
`;

const Container = styled.div`
  display: flex;
  justify-content: flex-end;

  span {
    color: ${({ theme }) => theme.buttons.readMoreButton.spanColor};
  }
`;

const TheLink = styled(ConditionalLink)`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: ${COLORS.ssfBlue400};
  ${fontHeaderFourish}
`;

const ArrowIcon = styled(FontIcon)`
  ${ArrowIconStyle}
`;

type Props = {
  link: SanityLink;
  className?: string;
};

const ReadMoreArrow = (props: Props) => {
  const { link, className } = props;

  const localize = useSanityContentLocalizer();

  return (
    <Container className={className}>
      <TheLink link={link}>
        <span>{localize(link.title)}</span>
        <ArrowIcon iconClassName={'ssf-arrow-right'}></ArrowIcon>
      </TheLink>
    </Container>
  );
};

export default ReadMoreArrow;
