import React from 'react';
import { FeatureFlagProvider } from '/src/hooks/use-feature-flags';
import { AppInstallationContextProvider } from '/src/hooks/app-installation';
import {
  CookieManagerProvider,
  DeviceContextProvider,
} from '@ssfrepo/ssf-react-components';
import { TemplateAgnosticCacheContextProvider } from '/src/hooks/use-template-agnostic-cache';
import { GatsbyBlockContentProviders } from '../core/block-content/providers';
import { SearchDataHookTypeProvider } from '/src/domains/search/search-data/search-data-hook';
import { SsfStyledThemeProvider } from '/src/hooks/styled-theme';
import { GlobalBodyStyles } from '/src/sass/styled-theme/ssf-global-vars';

const wrapRootElement = (props) => {
  const { element } = props;

  return (
    <TemplateAgnosticCacheContextProvider>
      <CookieManagerProvider>
        <FeatureFlagProvider>
          <DeviceContextProvider>
            <AppInstallationContextProvider>
              <SsfStyledThemeProvider>
                <GatsbyBlockContentProviders>
                  <SearchDataHookTypeProvider>
                    <GlobalBodyStyles />
                    {element}
                  </SearchDataHookTypeProvider>
                </GatsbyBlockContentProviders>
              </SsfStyledThemeProvider>
            </AppInstallationContextProvider>
          </DeviceContextProvider>
        </FeatureFlagProvider>
      </CookieManagerProvider>
    </TemplateAgnosticCacheContextProvider>
  );
};

export default wrapRootElement;
