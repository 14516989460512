import { CampaignArticleTheme, css, DefaultTheme } from 'styled-components';
import { darkBigLinkThemeImpl } from './big-link-dark-impl';
import { darkChatBoxThemeImpl } from './chat-dark-impl';
import { darkBreadcrumbThemeImpl } from './breadcrumb-dark-impl';
import { darkMenuGroupExpanderThemeImpl } from '../../../components/layout/menu/menu-group-expander/theme/menu-group-expander-dark-impl';
import { darkWpPostFrontSectionThemeImpl } from '../../../domains/meirennbank/meirennbank-post-picker/theme/wp-post-front-section-dark-impl';
import { darkWpPostCardThemeImpl } from '../../../domains/meirennbank/wp-post-card/theme/wp-post-card-dark-impl';
import {
  darkSSFScrollTertiaryThemeImpl,
  darkSSFScrollThemeImpl,
} from './ssf-scroll-dark-impl';
import { darkOpenMenuButtonThemeImpl } from '/src/components/layout/menu/open-menu-button/theme/open-menu-button-dark-impl';
import { darkPieceSectionClusterThemeImpl } from '/src/components/content/sections/theme/piece-section-cluster-dark-impl';
import { darkFactsBoxThemeImpl } from '/src/components/content/facts-box/theme/facts-box-dark-impl';
import { CalculatorStyledThemeDarkImpl } from '/src/components/content/calculators/theme/calculator-styled-dark-impl';
import { faqThemeDarkImpl } from '/src/domains/faq-page/theme/faq-dark-impl';
import { linkListThemeDarkImpl } from '/src/components/content/link-list/theme/link-list-dark-impl';
import {
  COLORS,
  darkBlockContentThemeImpl,
  darkFormsThemeImpl,
} from '@ssfrepo/ssf-react-components';
import { darkCommonThemeGatsbyImpl } from './common-dark-impl';
import { darkButtonThemeGatsbyImpl } from './button-dark-impl';
import { SideButtonDarkThemeImpl } from '/src/components/layout/side-buttons/theme/side-button-dark-theme-impl';
import { employeeSetDarkThemeImpl } from '/src/components/content/employee-set/theme/employee-set-dark-theme-impl';
import { productPriceDarkThemeImpl } from '/src/domains/pricing/theme/product-price-dark-theme-impl';
import { stepByStepDarkThemeImpl } from '/src/components/content/step-by-step/theme/step-by-step-dark-theme-impl';
import { contactUsDarkThemeImpl } from '/src/domains/contact-us/components/theme/contact-us-dark-theme-impl';
import { searchDarkThemeImpl } from '/src/domains/search/components/theme/search-dark.impl';
import { richTableDarkThemeImpl } from '/src/components/content/rich-table/theme/rich-table-dark-theme-impl';
import { audioPlayerDarkThemeImpl } from '/src/components/content/kongleriket/theme/audio-player-dark-theme-impl';
import { frendeWebshopDarkThemeImpl } from '/src/templates/template-theming/frende/frende-theme';
import { darkExpandableStepByStepThemeImpl } from '/src/components/content/expandable-step-by-step/theme/expandable-step-by-step-dark-impl';

const themeId = 'dark';

export const DarkBodyStyles = css`
  .${themeId} {
    --common-layout-main-bg: ${COLORS.ssfBlackish};
    --common-layout-topbar-bg: ${COLORS.ssfBlue900};
    --common-layout-logo-image: url('/images/logo-dark-mode.svg');
    --article-block-paragraph-color: ${COLORS.ssfBlue100};
    --common-layout-topbar-box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1);
    --common-layout-topbar-border-bottom: 1px solid ${COLORS.ssfGrey500};
  }
`;

export const darkModeTheme: DefaultTheme = {
  themeId,
  buttons: darkButtonThemeGatsbyImpl,
  blockContent: darkBlockContentThemeImpl,
  common: darkCommonThemeGatsbyImpl,
  forms: darkFormsThemeImpl,
  chat: darkChatBoxThemeImpl,
  bigLink: darkBigLinkThemeImpl,
  breadcrumb: darkBreadcrumbThemeImpl,
  openMenuButton: darkOpenMenuButtonThemeImpl,
  menuGroupExpander: darkMenuGroupExpanderThemeImpl,
  wpPostFrontSection: darkWpPostFrontSectionThemeImpl,
  wpPostCard: darkWpPostCardThemeImpl,
  ssfScroll: darkSSFScrollThemeImpl,
  ssfScrollTertiary: darkSSFScrollTertiaryThemeImpl,
  campaignArticle: {} as CampaignArticleTheme,
  pieceSectionCluster: darkPieceSectionClusterThemeImpl,
  factsBox: darkFactsBoxThemeImpl,
  calculatorStyled: CalculatorStyledThemeDarkImpl,
  faq: faqThemeDarkImpl,
  linkList: linkListThemeDarkImpl,
  sideButton: SideButtonDarkThemeImpl,
  employeeSet: employeeSetDarkThemeImpl,
  productPrice: productPriceDarkThemeImpl,
  stepByStep: stepByStepDarkThemeImpl,
  contactUs: contactUsDarkThemeImpl,
  search: searchDarkThemeImpl,
  richTable: richTableDarkThemeImpl,
  audioPlayer: audioPlayerDarkThemeImpl,
  frendeWebshop: frendeWebshopDarkThemeImpl,
  expandableStepByStep: darkExpandableStepByStepThemeImpl,
};
