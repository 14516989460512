import { BlockContentTheme, css } from 'styled-components';
import {
  fontFamilies,
  fontHeaderFourish,
  fontHeaderOneish,
  fontHeaderThreeish,
  fontHeaderTwoish,
  fontIngressAsRegular,
  fontNormalish,
  fontSmallish,
} from '../../typography/typography-styled';
import bulletpointPM from '/src/assets/images/bulletpoint-pm.svg';

const paragraph = css`
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  ${fontNormalish}
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
`;

const container = css`
  h1 {
    ${fontHeaderOneish}
  }
  h1.block-header {
    margin: 30px 0;
  }
  h2 {
    ${fontHeaderTwoish}
  }
  h3 {
    ${fontHeaderThreeish}
  }
  h4 {
    ${fontHeaderFourish}
  }
  strong {
    font-family: ${fontFamilies.medium};
    font-weight: 500;
  }
  ul {
    list-style-type: none;
    list-style-image: url(${bulletpointPM});
    margin: 0;
    margin-left: 15px;
    padding: 0;
    display: flex;
    flex-direction: column;
    li {
      ${fontNormalish}
      margin: 7px 5px;
      padding-inline-start: 7px;
    }
  }
`;

const header = css`
  margin: 10px 0;
`;

const ingress = css`
  ${fontIngressAsRegular}
`;

const small = css`
  ${fontSmallish}
`;

export const pmBlockContentThemeImpl: BlockContentTheme = {
  container,
  paragraph,
  header,
  ingress,
  small,
};
