import React from 'react';
import styled, { css } from 'styled-components';
import {
  MAIN_MAX_WIDTH,
  MAIN_PADDING,
  COLORS,
  BANNER_MAX_WIDTH,
} from '/src/sass/styled-constants';

export const MaxWidthSection = styled.section`
  width: 100%;
  max-width: ${MAIN_MAX_WIDTH};
  margin: 0 auto;
  padding: ${MAIN_PADDING};
  box-sizing: border-box;
`;

export const BannerOnBreakPointSection = styled.section<{
  $bannerBreakPoint?: string;
}>`
  width: 100%;
  max-width: ${MAIN_MAX_WIDTH};
  margin: 0 auto;
  padding: ${MAIN_PADDING};
  box-sizing: border-box;
  @media (max-width: ${(props) => props.$bannerBreakPoint}) {
    padding: 0;
    max-width: ${BANNER_MAX_WIDTH};
  }
`;

export const BannerWidthSection = styled.section`
  width: 100%;
  max-width: ${BANNER_MAX_WIDTH};
  margin: 0 auto;
  box-sizing: border-box;
`;

export const ResetSectionStyles = css`
  padding: 0;
  margin: 0;
  max-width: 100%;
`;

export const FlexCenterMaxWidthSection = styled(MaxWidthSection)`
  display: flex;
  justify-content: center;
`;

const ColoredSeparator = styled.div`
  border-top: 1px solid ${COLORS.ssfBlue600};
  flex-grow: 1;
  padding: 0px 20px 20px 20px;
  margin-top: 20px;
`;
export const ColoredSeparatorSection = ({
  className,
}: {
  className?: string;
}) => (
  <MaxWidthSection className={className}>
    <ColoredSeparator />
  </MaxWidthSection>
);

export const FlexRowWrapMaxWidthSection = styled(MaxWidthSection)`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
`;
