import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { SanityVideo } from '/src/types/sanity/content/video';
import { useDeviceDetection } from '@ssfrepo/ssf-react-components';
import LazyClientComponent from '/src/components/core/lazy-client-component';
import EllipsisLoader from '/src/components/styled/ellipsis-loader';
import AudioPlayer from '../kongleriket/audio-player';
import { CastIFrame } from './cast-iframe';
import Cast from './cast';
const MediaClientSide = React.lazy(() => import('./media-clientside'));

const NullContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
`;

export type MediaProps = {
  className?: string;
  media: SanityVideo;
};

const Media = (props: MediaProps) => {
  const { media } = props;
  const { dacastContentId, rawFile, dacastShareLink } = media;
  const device = useDeviceDetection();
  const [mediaSupported, setMediaSupported] = useState(false);

  useEffect(() => {
    if (device.browser === 'Evergreen') {
      setMediaSupported(true);
    } else {
      setMediaSupported(false);
    }
  }, [device]);

  if (!mediaSupported) {
    return <NullContainer />;
  }

  //Add support for other audio file types here.
  const isAudio =
    rawFile?.asset.url.includes('.mp3') || rawFile?.asset.url.includes('.wav');

  if (!!rawFile && isAudio) {
    return (
      <LazyClientComponent fallback={<EllipsisLoader />}>
        <AudioPlayer media={media} />
      </LazyClientComponent>
    );
  }

  if (dacastShareLink) {
    return <CastIFrame {...props} />;
  }
  if (dacastContentId) {
    return <Cast {...props} />;
  }

  return (
    <LazyClientComponent fallback={<EllipsisLoader />}>
      <MediaClientSide {...props} />
    </LazyClientComponent>
  );
};

export default Media;
