/* eslint-disable @typescript-eslint/camelcase */

export const edgesToFlatNodeArray = (edgesWrapper: {
  edges: { node: any }[];
}): any[] => {
  if (!(edgesWrapper && edgesWrapper.edges && edgesWrapper.edges.length)) {
    return [];
  }

  return edgesWrapper.edges.map((edge) => edge.node);
};
