/* eslint-disable react/display-name */
import React from 'react';
import styled from 'styled-components';
import { COLORS } from '@ssfrepo/ssf-react-components';
import {
  InputBackgroundCommon,
  InputTextPlaceholderColor,
} from '@ssfrepo/ssf-react-components';
import get from 'lodash.get';
import { fontNormalishAsMedium } from '/src/sass/typography/typography-styled';
import { FlexStartContainer } from '@ssfrepo/ssf-react-components';

const SearchTextInput = styled.input`
  width: 100%;
  min-width: 0;
  ${InputBackgroundCommon}
  border-width: 2px;
  border-radius: 10px 0px 0px 10px;

  background: ${({ theme }) => theme.search.searchInputAndModalButtonBg};
  border: 2px solid ${COLORS.ssfBlue400};

  color: ${({ theme }) => theme.search.searchInputAndModalButtonFg};
  caret-color: ${({ theme }) => theme.search.searchInputCaretColor};
  ::placeholder {
    color: ${({ theme }) => theme.search.searchInputPlaceholderColor};
  }
  &:-webkit-autofill {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-box-shadow: 0 0 0px 1000px
      ${({ theme }) => theme.search.searchInputAndModalButtonBg} inset;
    -webkit-text-fill-color: ${({ theme }) =>
      theme.search.searchInputAndModalButtonFg};
  }
`;

const DummySuffix = styled.button`
  border: 2px solid ${COLORS.ssfBlue400};
  border-left-width: 0px;
  box-sizing: border-box;
  padding: 11px 12px;
  outline: 0;
  border-radius: 0px 10px 10px 0px;
  ${fontNormalishAsMedium}
  user-select: none;
  cursor: pointer;
  color: ${({ theme }) => theme.search.searchInputAndModalButtonFg};
  background-color: ${({ theme }) => theme.search.searchInputAndModalButtonBg};
  text-transform: uppercase;

  :focus {
    background-color: ${({ theme }) =>
      theme.search.searchInputAndModalButtonBg};
  }
`;

type Props = {
  name: string;
  onChange: (txt: string) => void;
  value: string;
  className?: string;
  placeholder?: string;
  suffixText: string;
  onSearchClick?: () => void;
};

const SearchInput = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
  const {
    name,
    value,
    onChange,
    className,
    placeholder,
    suffixText,
    onSearchClick,
  } = props;
  return (
    <FlexStartContainer className={className}>
      <SearchTextInput
        id={name}
        ref={ref}
        name={name}
        placeholder={placeholder}
        value={value}
        onKeyDown={({ key }) => {
          if (key === 'Enter' && onSearchClick) {
            onSearchClick();
          }
        }}
        onChange={(e) => {
          const eventValue = get(e, 'target.value') as string;
          onChange(eventValue);
        }}
      />
      {<DummySuffix onClick={onSearchClick}>{suffixText}</DummySuffix>}
    </FlexStartContainer>
  );
});

export default SearchInput;
