import React from 'react';
import { mediaBreakPoints, pixelToNumber } from '/src/sass/styled-constants';
import { useWindowDimensions } from '@ssfrepo/ssf-react-components';
import DesktopMenu from './desktop-menu';
import MobileMenu from './mobile-menu';
import { SanityMenuGrouping } from '/src/types/sanity/menu/menu-grouping';
import { SanityTopBar } from '/src/types/sanity/menu/topbar';

type Props = {
  onNavigation: () => void;
  menuGroupings: SanityMenuGrouping[];
  topBar: SanityTopBar;
};

const Menu = (props: Props) => {
  const { onNavigation, menuGroupings, topBar } = props;
  const dims = useWindowDimensions();

  return (
    <>
      {dims.width > pixelToNumber(mediaBreakPoints.sideMenu) ? (
        <DesktopMenu
          onNavigation={onNavigation}
          menuGroupings={menuGroupings}
        />
      ) : (
        <MobileMenu
          onNavigation={onNavigation}
          menuGroupings={menuGroupings}
          topBar={topBar}
        />
      )}
    </>
  );
};

export default Menu;
