import {
  SanityClientConfig,
  SanityImageExtras,
  SanityLink,
  SanityLocaleBlockContent,
} from '@ssfrepo/ssf-sanity-utils';
import groq from 'groq';
import { PageSearchDocImplResult, queryDocs } from '..';
import { PageSearchDocument } from '/src/types/core/search';

const GROQ_QUERY_EMPLOYEES = groq`
*[_type == 'employee']{
  name,
  route->{slug},
  image {
    _type,
    asset
  },
  bioRichText
}
`;

interface Employee {
  name: string;
  route: SanityLink;
  image: SanityImageExtras;
  bioRichText: SanityLocaleBlockContent;
}

const extractEmployeeBioHeader = (blocks: any[]) => {
  const headers: string[] = [];

  for (let __idx = 0; __idx < blocks.length; __idx++) {
    const block = blocks[__idx];
    if (block._type === 'block' && ['h3', 'h4'].includes(block.style)) {
      const firstChild = block?.children?.[0];
      if (firstChild?.text) {
        headers.push(`${firstChild.text}`);
      }
    }
  }
  return headers;
};

const extractEmployeeBioText = (blocks: any[]) => {
  const fullText: string[] = [];
  for (let idx = 0; idx < blocks.length; idx++) {
    const block = blocks[idx];
    if (block._type === 'block' && ['normal'].includes(block.style)) {
      const children = block?.children || [];
      for (let _idx = 0; _idx < children.length; _idx++) {
        const childText = children[_idx].text;
        fullText.push(childText);
      }
    }
  }
  const _description: string[] = [];
  let desc = `${fullText.slice(0, 2).join()}`;
  if (desc.length > 140) {
    desc = `${desc.substring(0, 140)}`;
  }
  desc = `${desc}...`;
  _description.push(desc);
  return _description;
};

const description = (employee: Employee, language: string): string[] => {
  const blocks = employee.bioRichText?.[language];
  if (!blocks || !blocks.length) {
    return [];
  }

  const header = extractEmployeeBioHeader(blocks);
  const txt = extractEmployeeBioText(blocks);
  const desc: string[] = [];
  if (header && header.length) {
    desc.push(`${header[0]}\n`);
  }
  if (txt && txt.length) {
    desc.push(...txt);
  }
  return desc;
};

export const searchableEmployee = async (
  sanityConfig: SanityClientConfig
): Promise<PageSearchDocImplResult> => {
  const queryResult = await queryDocs<Employee[]>(
    sanityConfig,
    GROQ_QUERY_EMPLOYEES
  );

  if (queryResult === 'error') {
    console.error('Query failed for searchableEmployee');
    return 'error';
  }

  const nnDocuments: PageSearchDocument[] = [];
  const enDocuments: PageSearchDocument[] = [];
  queryResult.forEach((employee) => {
    const path = employee?.route?.slug?.current;

    if (!path) {
      return;
    }

    const nn: PageSearchDocument = {
      title: employee.name,
      path,
      subtitles: [],
      description: description(employee, 'nn'),
      searchKeywords: [],
      hideInternalSearch: false,
      image: employee.image,
    };

    nnDocuments.push(nn);
    enDocuments.push({ ...nn, description: description(employee, 'en') });
  });

  return {
    nnDocuments,
    enDocuments,
  };
};
