import { SanityLink } from '@ssfrepo/ssf-sanity-utils';
import get from 'lodash.get';

const externalURLsDefinedAsInternal = [
  'www.ssf.no',
  'bank.ssf.no',
  'nettbedriften.evry.com',
  'acc.ssfbank.no',
  'ssfbank.no',
  'www.ssfbank.no',
  'nettbank.edb.com',
  'nettbank.ssf.no',
];

export const sanityLinkType = (
  link: SanityLink
): 'internal' | 'external' | 'file' => {
  if (get(link, 'slug.current') || get(link, 'bankSlug.current')) {
    return 'internal';
  }

  const url = get(link, 'url');
  if (url) {
    const urlObject = new URL(url);

    return !urlObject.protocol.startsWith('mailto') &&
      !urlObject.protocol.startsWith('tel') &&
      !externalURLsDefinedAsInternal.includes(urlObject.hostname)
      ? 'external'
      : 'internal';
  }

  return get(link, 'servedFile.file.asset.url') ? 'file' : 'internal';
};
