import React from 'react';
import styled from 'styled-components';

type Props = {
  children: React.ReactNode;
  fallback: NonNullable<React.ReactNode> | null;
};

/**
 * Wrap whatever thing that should not be SSR'd inside this one.
 *
 * */
const LazyClientComponent = (props: Props) => {
  const { children, fallback } = props;

  if (typeof window === 'undefined') {
    return <>{fallback}</>;
  }

  return (
    <>
      <React.Suspense fallback={fallback}>{children}</React.Suspense>
    </>
  );
};

export default LazyClientComponent;
