import React, { createContext, useContext } from 'react';
import {
  TemplateAgnosticCache,
  useTemplateAgnosticCacheFactory,
} from './template-agnostic-cache';
import { SanityContentLocalize } from '@ssfrepo/ssf-react-components';

export const TemplateAgnosticCacheContext =
  createContext<TemplateAgnosticCache>(new TemplateAgnosticCache());

export const TemplateAgnosticCacheContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const cache = useTemplateAgnosticCacheFactory();

  return (
    <TemplateAgnosticCacheContext.Provider value={cache}>
      {children}
    </TemplateAgnosticCacheContext.Provider>
  );
};

export const useTemplateAgnosticCache = () =>
  useContext(TemplateAgnosticCacheContext);

/**
 * A Localization abstraction.
 *
 * To get translation for key 'mortgageCalc.title' do this in a functional component:
 * const L = useTranslateShortHand(cache, localize, 'mortgageCalc');
 * and use in jsx like this: L`title`
 * If you want to use L for another domain, use an explicit full key like this: L`common.yes`
 * PS: syntax used here is a tagged template literal but that is only to ease typing.
 * So you can't do L`${domain}.key` without it breaking the tagged template parser.
 * If you need to bind stuff into the key just use the full call, not the shorthand.
 * @param cache
 * @param localize
 * @param topLevelKeyDomain
 */
export const useTranslateShortHand =
  (
    cache: TemplateAgnosticCache,
    localize: SanityContentLocalize,
    topLevelKeyDomain: string
  ) =>
  (keyTemplate: TemplateStringsArray): string => {
    const key = keyTemplate[0].includes('.')
      ? keyTemplate[0]
      : `${topLevelKeyDomain}.${keyTemplate[0]}`;

    return localize(cache.translate(key));
  };

export const interpolatePlaceholders = (
  localizedString: string,
  ...replaceArgs: string[]
): string =>
  replaceArgs.reduce(
    (accu, replacer) => accu.replace(/%s/, replacer),
    localizedString
  );
