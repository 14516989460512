/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react';
import { useStyledThemeContext } from '/src/hooks/styled-theme';
import { triggerAnalyticsEvent, CHAT } from '/src/utils/analytics-utils';
import styled from 'styled-components';

const toggleDarkThemeClassNames = (element: string, theme: string): void => {
  document
    .querySelector(`.${element}`)
    ?.classList.toggle(`${element}-dark`, theme === 'dark');
};

const mountPuzzle = (variables: any, onClose: () => void) => {
  // @ts-ignore
  $('#chatContainer').intelecomChat({
    /*
                mandatory parameters
                */
    customerKey: '130652',
    queueKey: 'q_chat_bot',
    variables,
    onChatStart: () => {
      triggerAnalyticsEvent(CHAT);
      // @ts-ignore
      $('#chatContainer').intelecomChat('reloadOptions', {
        variables,
      });
    },
    onChatWindowClose: () => onClose(),
    /*
                non-mandatory parameters
                */
    timeId: '25064_time3', // check opening hours
    timeOpenExits: ['Open', 'open', 'åpent', 'Åpent'], // possible to set other exit names
    msgTimeClosed: 'Chatten er stengt', // text message
    // 		visualQueueId: 40869, //checks queue,closes if no agents are logged on
    // 		visualQueueExpression: function (q) { return (q && q.AgentsLoggedIn > 0); }, // function that returns false when chat should be closed
    // 		msgNoAgents: 'No agents available', //text message
    //      msgClosedHeader: '', // text message
    //      onContactCenterClosed: function(){}, //runs when closed
    // onChatStart: () => onCustomEvent('chat'), //runs when clicking the chat button
    onChatWindowShow: () => {
      document
        .querySelector('.intelecomsubmit')
        ?.classList.add('ssf-plane_paper_send');
      document
        .querySelector('.intelecomchaticon-minus')
        ?.classList.add('ssf-minimize_collapse_window');
      document
        .querySelector('.intelecomchaticon-cancel')
        ?.classList.add('ssf-close');
    }, //runs when chat window is displayed
    //      onChatWindowMinimize: function(){},//runs when chat window's minimized
    showForm: false, // enable user to enter name and e-mail at startup
    showStarter: false, // displays the chat starter circle
    //      delayStarterSeconds: 0, //delays the chat starter circle
    showStarterWhenMinimized: false, // displays the chat starter circle
    //      horizontalStarterPosition: 'right', //position the chat starter circle
    //      verticalStarterPosition: 'middle', // position the chat starter circle
    // chatlink: '._jschat__trigger, .chatstarter', // default class for chat button link
    //      horizontalChatPosition: 'right', // positions the chat window
    //      verticalChatPosition: 'bottom', // positions the chat window
    draggable: false, // enables user to move window
    resizable: false, // enables user to resize window
    //      nickName: '', //can be added from the web page
    //      chatId: '', //can be added from the web page
    //      chatIssue: '', //can be added from the web page
    //      altQueueKey: '', //alternative queue to check for available agents
    languageCode: 'NO', // language settings (NO, SE, DK, EN)
    // 		maxinfoMessageLength: 30, // restirct length of infomessage in the top. Could be adjusted when with of chat is larger than default.
    //      showIntro: false, //to show intro text field at initiation
    //      showAgentLoggedOnInIntro: false, //include "agents logged on" in intro
    //      showAgentActiveInIntro: false, //include "active agents" in intro
    //      showNumberInQueueInIntro: false, //include "number in queue" in intro
    //      showVariablesInIntro: true, //include "variables" in intro
    //      msgWelcomeHeader: '', //text message
    //      msgWelcome: '', // text message
    //      msgWelcomeFull: '', // text message
    //      msgWelcomeEmpty: '', // text message
    //      msgWelcomePause: '', // text message
    //      msgReject: '', // text message
    msgGoodbyeHeader: 'Chatten er avslutta', // text message
    msgGoodbye: 'Takk for at du tok kontakt med oss og velkommen tilbake', // text message
    //      showSendEmail: true, //allows to show email option
    emailSubject: 'Takk for at du tok kontakt med oss og velkommen tilbake', // subject text to show in email
    fromEmailDisplayName: 'Kundeservice Sparebanken Sogn og Fjordane', // display name
    // 		emailPreText: 'Hello.[br][br]Thank you for speaking to us earlier. Here is a transcript of the conversation.',
    // 		emailPostText: 'If you require any further information, please do not hesitate to contact us.[br][br]Kind regards,[br]Intelecom',
    // emailLogoUrl: 'https://www.ssf.no/Images/logo-ssf.png',
    emailLogoLink: 'https://www.ssf.no/',
    emailLogoAlt: 'Sparebanken Sogn og Fjordane',
    emailTopBackgroundColor: '#FFFFFF',
    emailTopLineColor: '#3768a9',
    emailBottomLineColor: '#3768a9',
    // 		emailTextStyle: 'font-size:9.0pt;font-family:\"Calibri\",sans-serif;',
    // onChatEnd: () => {}, // runs when closing chat window #1
    // onChatWindowClose: () => this.props.onClose(), //runs when closing chat window #2
    //      inputFields: {}, // configuration of input fields in starter form
    // 		useTracker: false,
    // 		trackerArea: "",
    // 		trackerMaxTracks: 30,
    enableSurvey: true,
    surveyOnlyWhenConnected: false,
    surveyMaxScore: 5,
    surveyMinScore: 1,
    surveyType: 'star',
    // 		startTextFromBottom: false,
    // 		msgSurveyQuestion: '',
    msgSurveyQuestion: 'Hjelp oss å bli betre ved å vurdere denne samtalen ?',
    msgSurveyHeader: 'Ein ting til…',
    // startChatImmediately: true,
    msgOptionHeader: '',
    // 		msgOptionButton: '',
    msgOptionConfirmed: '',
    msgOptionCancel: '',
    // 		notifyChatterOnNewMessage: true,
    // 		msgNotificationText: '',
    // 		sendEmailAutomatically: false,
  });
  return true;
};

type PuzzleChatProps = {
  variables: any;
  onClose: () => void;
  isChatOpen: boolean;
};

const StyledDiv = styled.div`
  border: 3px solid #009bbc;
  border-radius 8px;
`;

const mountChatBot = async (props: PuzzleChatProps) => {
  const { variables, onClose } = props;
  const isChatMounted = await mountPuzzle(variables, onClose);
  return isChatMounted;
};

const PuzzleChat = (props: PuzzleChatProps) => {
  const [mounted, setMounted] = useState(false);
  const [theme] = useStyledThemeContext();
  const chatBotTopElementClassName = 'intelecomchatdialog';

  useEffect(() => {
    mountChatBot(props).then((isChatMounted) => setMounted(isChatMounted));
  }, [props]);

  useEffect(() => {
    if (props.isChatOpen && mounted) {
      // @ts-ignore
      $('#chatContainer').intelecomChat('startChat');
    }
    toggleDarkThemeClassNames(chatBotTopElementClassName, theme);
  });
  return <StyledDiv id="chatContainer" />;
};

export default PuzzleChat;
