import { useEffect } from 'react';
import Cookies from 'js-cookie';

interface CookieTarget {
  name: string;
  domain?: string;
}

const COOKIES_TO_WIPE: CookieTarget[] = [
  { name: 'usida', domain: '.facebook.com' },
  { name: 'dpr', domain: '.facebook.com' },
  { name: 'wd', domain: '.facebook.com' },
  { name: '_gat_UA-128380497-2' },
  { name: '_gat_UA-128380497-1' },
  { name: '_gid' },
  { name: '_ga' },
  { name: '_fbp' },
  { name: '_utma' },
];

const getRootLevelDomainCookieSyntax = (
  location: Location
): string | undefined => {
  if (!location || !location.hostname) {
    return undefined;
  }
  const { hostname } = location;
  const split = hostname.split('.');
  if (split.length === 2) {
    return `.${hostname}`;
  }
  if (split.length === 1) {
    return hostname;
  }
  const reverse = split.reverse();
  return `.${reverse[1]}.${reverse[0]}`;
};
export const useCookieTerminator = () => {
  useEffect(() => {
    setTimeout(() => {
      const _domain = getRootLevelDomainCookieSyntax(document.location);
      COOKIES_TO_WIPE.forEach((c) => {
        Cookies.remove(c.name);
        Cookies.remove(c.name, { domain: c.domain || _domain });
        Cookies.remove(c.name, { domain: `www.${_domain}` });
      });
    }, 5000);
  }, []);
};
