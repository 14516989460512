import React, { useState } from 'react';
import { useSanityContentLocalizer } from '@ssfrepo/ssf-react-components';
import { useTemplateAgnosticCache } from '/src/hooks/use-template-agnostic-cache';
import styled from 'styled-components';
import FontIcon from '@ssfrepo/ssf-react-components/lib/buttons/font-icon';
import { fontHeaderFourish } from '/src/sass/typography/typography-styled';
import { ArrowIconStyle } from '/src/components/buttons/read-more-arrow';

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  margin-top: 10px;
`;

const Divider = styled.div`
  height: 1px;
  flex: 1;
  background: ${({ theme }) => theme.buttons.collapsible.divider};
`;

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: ${({ theme }) => theme.buttons.collapsible.text};
  ${fontHeaderFourish}
  margin-left: 5px;
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
  z-index: 1;

  :focus-visible {
    outline: 2px solid orange;
    border-radius: 2.5px;
  }
`;

const StyledFontIcon = styled(FontIcon)`
  ${ArrowIconStyle}
`;

type Props = React.PropsWithChildren<{
  className?: string;
  selfEliminating?: boolean;
  openTranslationKey?: string;
  closeTranslationKey?: string;
}>;

export const Collapsible = (props: Props) => {
  const {
    selfEliminating = false,
    openTranslationKey,
    closeTranslationKey,
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const localize = useSanityContentLocalizer();
  const cache = useTemplateAgnosticCache();

  if (selfEliminating && isOpen) {
    return <>{props.children}</>;
  }

  return (
    <div>
      {isOpen && <div>{props.children}</div>}
      <ButtonContainer>
        <Divider />
        <StyledButton
          tabIndex={0}
          type="button"
          onClick={() => setIsOpen(!isOpen)}
        >
          <span>
            {isOpen
              ? localize(
                  cache.translate(closeTranslationKey || 'common.readLess')
                )
              : localize(
                  cache.translate(openTranslationKey || 'common.readMore')
                )}
          </span>
          <StyledFontIcon
            iconClassName={isOpen ? 'ssf-arrow-up' : 'ssf-arrow-down'}
          />
        </StyledButton>
      </ButtonContainer>
    </div>
  );
};
