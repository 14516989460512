import { mediaBreakPoints } from '/src/sass/styled-constants';
import { ButtonTheme, css } from 'styled-components';
import { COLORS, darkButtonThemeImpl } from '@ssfrepo/ssf-react-components';

export const darkButtonThemeGatsbyImpl: ButtonTheme = {
  ...darkButtonThemeImpl,
  searchButton: {
    fg: COLORS.ssfBlue900,
    hoverBg: COLORS.ssfBlue400,
    stylingWithMediaBreakPoints: css`
      background: ${COLORS.ssfBlue300};
      border: 1px solid ${COLORS.ssfDarkBlue};
      @media (max-width: ${mediaBreakPoints.sideMenu}) {
        background: none;
        border: 1px solid ${COLORS.ssfBlue100};
      }
    `,
  },
  tabButton: {
    fg: COLORS.ssfBlue100,
    borderButtonSelected: COLORS.ssfBlue300,
    borderButtonNonSelected: COLORS.ssfBlue300,
  },
  readMoreButton: {
    spanColor: COLORS.ssfBlue100,
    fg: COLORS.ssfBlue100,
  },
  collapsible: {
    divider: '#b0c5d6',
    text: COLORS.ssfWhite,
  },
};
