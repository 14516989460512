import React from 'react';
import {
  mediaBreakPoints,
  useSanityContentLocalizer,
} from '@ssfrepo/ssf-react-components';
import SsfButton from '@ssfrepo/ssf-react-components/lib/buttons/button';
import { SanityLink } from '@ssfrepo/ssf-sanity-utils';
import axios from 'axios';
import { navigate } from 'gatsby';
import styled from 'styled-components';
import DefaultButtonLink from '../../buttons/default-button-link';
import { useLocationType, LocationType } from '/src/hooks/use-location-type';
import { useOnlineLogin } from '/src/hooks/use-online-login';
import { useSiteMetaData } from '/src/hooks/use-site-metadata';
import { useTemplateAgnosticCache } from '/src/hooks/use-template-agnostic-cache';
import { SanityTopBar } from '/src/types/sanity/menu/topbar';
import { isRunningInApp } from '/src/utils/mobile-utils';

const DesktopOnlyButtonLink = styled(DefaultButtonLink)`
  @media (max-width: ${mediaBreakPoints.sideMenu}) {
    display: none !important;
  }
`;

const LogoutButton = styled(SsfButton)`
  margin-left: 10px;
  @media (max-width: ${mediaBreakPoints.sideMenu}) {
    display: none !important;
  }
`;

const PrivateLoggedIn = () => {
  const siteMetaData = useSiteMetaData();
  const cache = useTemplateAgnosticCache();
  const localize = useSanityContentLocalizer();
  return (
    <>
      <DefaultButtonLink
        key="onlinebank-back"
        colorScheme="tertiary"
        link={{
          _id: '',
          title: cache.translate('common.backToOnline'),
          url: `${siteMetaData.onlineBankUrl}/app`,
        }}
        canBecomeTextOnly
      />
      <LogoutButton
        onClick={() => {
          {
            axios
              .post(`${siteMetaData.onlineBankUrl}/api/security/logout`, null, {
                withCredentials: true,
              })
              .then((resp) => {
                navigate('/utlogga');
              })
              .catch(() => {
                // we assume you were actually already logged out if this errors.
                navigate('/utlogga');
              });
          }
        }}
        text={localize(cache.translate('common.logout'))}
      />
    </>
  );
};

const LoggedOut = ({
  signUpLink,
  loginLink,
}: {
  signUpLink: SanityLink;
  loginLink: SanityLink;
}) => {
  return (
    <>
      <DesktopOnlyButtonLink
        key="signup"
        colorScheme={'secondary'}
        link={signUpLink}
      />
      <DefaultButtonLink key="login" link={loginLink} canBecomeTextOnly />
    </>
  );
};
type Props = {
  topBar: SanityTopBar;
};

export const TopBarButtons = (props: Props) => {
  const customerType = useLocationType();
  const onlineLogin = useOnlineLogin();
  const siteMetaData = useSiteMetaData();
  const cache = useTemplateAgnosticCache();
  const isMobileApp = isRunningInApp();

  return (
    <>
      {isMobileApp && onlineLogin === 'in' && (
        <DefaultButtonLink
          key="mobilebank-back"
          colorScheme="tertiary"
          link={{
            _id: '',
            title: cache.translate('common.backToMobileApp'),
            url: `${siteMetaData.onlineBankUrl}/app`,
          }}
        />
      )}
      {!isMobileApp &&
        onlineLogin === 'in' &&
        customerType === LocationType.Private && <PrivateLoggedIn />}
      {onlineLogin === 'out' && customerType === LocationType.Private && (
        <LoggedOut
          loginLink={props.topBar.privateLogin}
          signUpLink={props.topBar.privateSignUp}
        />
      )}
      {onlineLogin === 'out' && customerType === LocationType.Business && (
        <LoggedOut
          loginLink={props.topBar.businessLogin}
          signUpLink={props.topBar.businessSignUp}
        />
      )}
    </>
  );
};
