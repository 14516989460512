import styled, { css, DefaultTheme } from 'styled-components';
import { COLORS } from '@ssfrepo/ssf-react-components';
import ConditionalLink from '/src/components/core/conditional-link';
import { fontNormalishAsMedium } from '/src/sass/typography/typography-styled';
import { RefObject } from 'react';

export const MenuLink = css`
  color: ${({ theme }) => theme.common.layout.menu.menuLinkColor};
  text-decoration: none;
  transition: all 0.3s ease;

  &:hover {
    text-decoration: underline;
  }
`;

export const TopBarMarketLink = styled(ConditionalLink)<{ isActive: boolean }>`
  ${({ isActive, theme }) =>
    isActive
      ? `
    color: ${theme.common.layout.activeFg};
    border-bottom: 1.5px solid ${theme.common.layout.activeBorder};
  `
      : `
    color: ${theme.common.layout.fg};
  `}
  display: inline-block;
  margin-left: 20px;
  ${fontNormalishAsMedium}
  text-decoration: none;
`;

export const MenuMarketLink = styled(ConditionalLink).attrs(
  ({ isActive }: { isActive: boolean }) => ({ isActive })
)`
  display: inline-block;
  color: ${({ isActive }) => (isActive ? COLORS.ssfWhite : COLORS.ssfGrey200)};
  ${fontNormalishAsMedium}
  text-decoration: none;
  border-bottom: ${({ isActive }) =>
    isActive ? `1px solid ${COLORS.ssfBlue100}` : 'none'};
  :nth-child(even) {
    margin-left: 10px;
  }
`;

export const getMainHeight = (
  mainRef: RefObject<HTMLDivElement>
): number | undefined => {
  return mainRef?.current?.offsetHeight;
};
