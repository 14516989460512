import React, { createContext, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { useSiteMetaData } from './use-site-metadata';
import { isBefore, addMinutes } from 'date-fns';

export type OnlineLoginStatus = 'in' | 'out' | 'unchecked';

interface InternalState {
  value: OnlineLoginStatus;
  nextUpdate: Date | undefined;
}

export const OnlineLoginContext = createContext<OnlineLoginStatus>('unchecked');

const initialValue: InternalState = {
  value: 'unchecked',
  nextUpdate: undefined,
};

export const OnlineLoginProvider = ({ location, children }) => {
  const [internalValue, setInternalValue] =
    useState<InternalState>(initialValue);
  const siteMetaData = useSiteMetaData();

  useEffect(() => {
    if (
      internalValue &&
      internalValue.nextUpdate &&
      isBefore(new Date(), internalValue.nextUpdate)
    ) {
      return;
    }
    const { onlineBankUrl } = siteMetaData;
    const url = `${onlineBankUrl}/api/ping`;

    axios
      .get<any>(url, { withCredentials: true, timeout: 3000 })
      .then((resp) => {
        if (setInternalValue) {
          setInternalValue({
            value: 'in',
            nextUpdate: addMinutes(new Date(), 1),
          });
        }
      })
      .catch(() => {
        if (setInternalValue) {
          setInternalValue({
            value: 'out',
            nextUpdate: addMinutes(new Date(), 5),
          });
        }
      });
  }, [siteMetaData, location, internalValue]);
  const { value } = internalValue;
  return (
    <OnlineLoginContext.Provider value={value}>
      {children}
    </OnlineLoginContext.Provider>
  );
};

export const useOnlineLogin = () => useContext(OnlineLoginContext);
