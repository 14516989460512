import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from 'react';
import { lightModeTheme } from '/src/sass/styled-theme/light-impl/light-theme';
import { DefaultTheme, ThemeType, ThemeProvider } from 'styled-components';
import { profileManualTheme } from '/src/sass/styled-theme/profile-impl/profile-manual-theme';
import { darkModeTheme } from '/src/sass/styled-theme/dark-impl/dark-theme';
import Cookies from 'js-cookie';
declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
    __ssf_theme: any;
    __setSsfPreferredTheme: any;
    __onSsfThemeChange: any;
  }
}
const getThemeImpl = (_theme: ThemeType): DefaultTheme => {
  switch (_theme) {
    case 'dark':
      return darkModeTheme;
    case 'profile':
      return profileManualTheme;
    default:
      return lightModeTheme;
  }
};

export const SsfStyledThemeProvider = ({ children }) => {
  const [currentTheme, setCurrentTheme] = useState<ThemeType>('light');

  useEffect(() => {
    global.window.__onSsfThemeChange = setCurrentTheme;
    global.window.__setSsfPreferredTheme(window.__ssf_theme);
  }, []);
  const setTheme = useCallback(
    (_newTheme: ThemeType, overrideCookie?: boolean) => {
      global.window.__setSsfPreferredTheme(_newTheme);
      setCurrentTheme(_newTheme)
      if (overrideCookie) {
        Cookies.set('OVERRIDING_THEME', _newTheme.toUpperCase(), {
          expires: 30,
        });
      }
    },
    []
  );
  return (
    <SsfStyledThemeContext.Provider value={[currentTheme, setTheme]}>
      <ThemeProvider theme={getThemeImpl(currentTheme)}>
        {children}
      </ThemeProvider>
    </SsfStyledThemeContext.Provider>
  );
};

const SsfStyledThemeContext = createContext<
  [ThemeType, (_newTheme: ThemeType, overrideCookie?: boolean) => void]
>(['light', () => { }]);

export const useStyledThemeContext = () => useContext(SsfStyledThemeContext);
