import { SanityLocaleString } from '@ssfrepo/ssf-sanity-utils';
import get from 'lodash.get';

export interface SanityTranslations {
  [key: string]: SanityLocaleString;
}

type GraphQlTransType = { cells: string[] }[];

export const mapTranslations = (translations: any): SanityTranslations => {
  const rows: GraphQlTransType = get(translations, 'translations.rows');
  if (!rows || !(rows.length >= 2)) {
    return {};
  }
  let validHeaderRow = false;
  const mappedTranslations = rows.reduce<SanityTranslations>((accum, row) => {
    const { cells } = row;

    if (!cells || cells.length !== 3) {
      return accum;
    }
    if (!validHeaderRow) {
      if (cells[0] === 'key' && cells[1] === 'nn' && cells[2] === 'en') {
        validHeaderRow = true;
      }

      return accum;
    }
    const translation: SanityLocaleString = { nn: cells[1], en: cells[2] };
    accum[cells[0]] = translation;

    return accum;
  }, {});

  return mappedTranslations;
};
