import {
  COLORS,
  mediaBreakPoints,
  useSanityContentLocalizer,
} from '@ssfrepo/ssf-react-components';
import FontIcon from '@ssfrepo/ssf-react-components/lib/buttons/font-icon';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useStyledThemeContext } from '/src/hooks/styled-theme';
import { useTemplateAgnosticCache } from '/src/hooks/use-template-agnostic-cache';

const Container = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: ${mediaBreakPoints.sideMenu}) {
  }
  @media (max-width: 280px) {
    font-size: 14px;
  }
`;

const IconAndTextWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0px;
  cursor: pointer;
`;

const Icon = styled(FontIcon)<{ $darkModeStyling: boolean }>`
  ${({ $darkModeStyling }) => `
    color: ${$darkModeStyling ? COLORS.ssfGrey50 : COLORS.ssfBlue100};
    background: ${$darkModeStyling ? COLORS.ssfBlue400 : COLORS.ssfBlue900};
    font-size: ${$darkModeStyling ? '21px' : '17px'};
    padding: ${$darkModeStyling ? '3px' : '5px'};
  `}
  border-radius: 50%;
  position: relative;

  :after {
    content: '';
    position: absolute;
    background: none;
    top: 17px;
    left: 9px;
    width: 17px;
    transition-property: box-shadow;
    transition-duration: 0.6s;
    transition-timing-function: linear;
  }
`;

const Text = styled.span`
  text-align: left;
  color: ${(p) => p.theme.common.layout.footer.languagePicker};
  margin-left: 10px;
  &:hover {
    text-decoration: underline;
  }
`;

export const ThemeSwitch = () => {
  const [theme, setTheme] = useStyledThemeContext();
  const cache = useTemplateAgnosticCache();
  const localize = useSanityContentLocalizer();
  const [checked, setChecked] = useState<boolean | 'waiting for useEffect'>(
    'waiting for useEffect'
  );
  const animationClass = `animated ${
    theme === 'light' ? 'rotateInRight' : 'rotateInLeft'
  }`;

  useEffect(() => {
    setChecked(
      theme === 'light'
        ? false
        : theme === 'dark'
        ? true
        : 'waiting for useEffect'
    );
  }, [theme]);

  if (checked === 'waiting for useEffect') {
    return null;
  }

  const icon =
    theme === 'dark' ? 'ssf-sun_brigthness_summer_hot' : 'ssf-moon_no_stars';

  const textKey =
    theme === 'dark'
      ? 'common.themeSwitchTextLight'
      : 'common.themeSwitchTextDark';
  const label = localize(cache.translate(textKey));

  return (
    <Container aria-label={label}>
      <IconAndTextWrapper
        tabIndex={0}
        className="no-flash"
        onClick={() => {
          setTheme(theme === 'light' ? 'dark' : 'light', true);
        }}
        onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
          if (event.key === 'Enter') {
            setTheme(theme === 'light' ? 'dark' : 'light', true);
          }
        }}
      >
        <Icon
          className={animationClass}
          iconClassName={icon}
          $darkModeStyling={theme === 'dark'}
        />
        <Text key={theme} className="animated fadeIn">
          {label}
        </Text>
      </IconAndTextWrapper>
    </Container>
  );
};
