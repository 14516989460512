import ConditionalLink from '/src/components/core/conditional-link';
import SanityGatsbyImage from '/src/components/core/images/sanity-gatsby-image';
import FontIcon from '@ssfrepo/ssf-react-components/lib/buttons/font-icon';
import { useSanityContentLocalizer } from '@ssfrepo/ssf-react-components';
import { useFeatureFlag } from '/src/hooks/use-feature-flags';
import {
  interpolatePlaceholders,
  useTemplateAgnosticCache,
} from '/src/hooks/use-template-agnostic-cache';
import React from 'react';
import { COLORS, MAIN_PADDING } from '/src/sass/styled-constants';
import {
  fontNormalishAsMedium,
  fontFamilies,
  fontSmallish,
} from '/src/sass/typography/typography-styled';
import styled from 'styled-components';
import {
  SanityEmployee,
  appendQueryParamToLinkSlug,
} from '@ssfrepo/ssf-sanity-utils';
import {
  getEmailSanityLink,
  getPhoneNumberSanityLink,
  humanizePhoneNumber,
} from '../contact-us-utils';

const PeepContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 10px 0;
  width: 400px;
`;

const EmployeeImage = styled(SanityGatsbyImage)`
  border-radius: 8px;
`;

const PeepTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${MAIN_PADDING};
`;

const Name = styled.span`
  ${fontNormalishAsMedium};
`;

const JobRole = styled.span`
  ${fontSmallish};
`;

const StyledActionLink = styled(ConditionalLink)`
  display: flex;
  align-items: center;
`;

const SubName = styled.span`
  font-family: ${fontFamilies.regular};
  font-size: 14px;
  line-height: 18px;
  text-decoration: underline;
  color: ${({ theme }) => theme.contactUs.employeeContactInfoLink};
`;

const ActionIcon = styled(FontIcon)`
  color: ${({ theme }) => theme.contactUs.employeeContactInfoLink};
  font-size: 20px;
  margin-right: 5px;
`;

export type EmployeeProps = {
  peep: SanityEmployee;
  className?: string;
  hideEmailInfo?: boolean;
};

const EmployeeDetails = (props: EmployeeProps) => {
  const { peep, className, hideEmailInfo = false } = props;
  const {
    _id,
    image,
    name,
    phone,
    email,
    jobTitle,
    officeCategoryConnections,
    route,
    submissionLink,
  } = peep;
  const localize = useSanityContentLocalizer();
  const cache = useTemplateAgnosticCache();
  const showPublicMail = useFeatureFlag('publicMail') && submissionLink;
  if (!image) {
    return null;
  }

  const officeString = officeCategoryConnections
    .map((officeCatConnection) => {
      const office = localize(officeCatConnection.office.title);
      return ' ' + office;
    })
    .toLocaleString();

  return (
    <PeepContainer className={className}>
      <EmployeeImage imageExtras={image} width={120} height={120} />
      <PeepTextContainer>
        <Name>{name}</Name>
        <JobRole>{localize(jobTitle)}</JobRole>
        <JobRole>{officeString}</JobRole>
        {phone && (
          <StyledActionLink link={getPhoneNumberSanityLink(phone)}>
            <ActionIcon iconClassName={'ssf-iphone_mobile_ringing'} />
            <SubName>{humanizePhoneNumber(phone)}</SubName>
          </StyledActionLink>
        )}
        {!hideEmailInfo && !showPublicMail && email && (
          <StyledActionLink link={getEmailSanityLink(email)}>
            <ActionIcon iconClassName={'ssf-mail_envelope_email_sent'} />
            <SubName>{email}</SubName>
          </StyledActionLink>
        )}
        {!hideEmailInfo && showPublicMail && (
          <StyledActionLink
            link={appendQueryParamToLinkSlug(
              submissionLink,
              'submit_employee',
              _id
            )}
          >
            <ActionIcon iconClassName={'ssf-mail_envelope_email_sent'} />
            <SubName>
              {interpolatePlaceholders(
                localize(cache.translate('hereWeAre.emailEmployee')),
                name
              )}
            </SubName>
          </StyledActionLink>
        )}

        {route && (
          <StyledActionLink link={route}>
            <ActionIcon iconClassName={'ssf-heart_favorite_like'} />
            <SubName>
              {interpolatePlaceholders(
                localize(cache.translate('hereWeAre.aboutEmployee')),
                name
              )}
            </SubName>
          </StyledActionLink>
        )}
      </PeepTextContainer>
    </PeepContainer>
  );
};

export default EmployeeDetails;
