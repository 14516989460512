import createTemplates from './templates';
import createRedirects from './redirects/create-redirects';
import { GraphQLCurrencyType } from '../types/apis/ssf-currency';
import createCurrencyNodes from './sources/onlinebank-currencies';
import type { GatsbyBrowser, GatsbyNode } from 'gatsby';
import { searchIndexDump } from './build/search-index-dump';
import {
  triggerPageView,
  UPDATE_GATSBY_ROUTE_EVENT,
} from '../utils/analytics-utils';
import { existsSync, renameSync } from 'fs';

export const createPages: GatsbyNode['createPages'] = async (args) => {
  await createTemplates(args);
  await createRedirects(args);
};

export const createSchemaCustomization: GatsbyNode['createSchemaCustomization'] =
  ({ actions }) => {
    const { createTypes } = actions;
    createTypes(GraphQLCurrencyType);
  };

export const sourceNodes: GatsbyNode['sourceNodes'] = async (context) => {
  createCurrencyNodes(context);
};

export const onCreateWebpackConfig: GatsbyNode['onCreateWebpackConfig'] =
  async (context) => {
    const { actions } = context;
    actions.setWebpackConfig({
      resolve: {
        fallback: {
          path: false,
          fs: false,
        },
      },
    });
  };

export const onPostBuild: GatsbyNode['onPostBuild'] = async (context) => {
  await searchIndexDump(context);
  if (existsSync('./public/sitemap-0.xml')) {
    renameSync('./public/sitemap-0.xml', './public/sitemap.xml');
  }
};

export const onRouteUpdate: GatsbyBrowser['onRouteUpdate'] = () => {
  triggerPageView();
  document.dispatchEvent(new CustomEvent(UPDATE_GATSBY_ROUTE_EVENT));
};
