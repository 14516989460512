import React from 'react';
import {
  GatsbyImage,
  GatsbyImageProps,
  IArtDirectedImage,
  withArtDirection,
} from 'gatsby-plugin-image';
import { GatsbyImageDataArgs, getGatsbyImageData } from 'gatsby-source-sanity';
import { SanityImageExtras } from '@ssfrepo/ssf-sanity-utils';
import { useSanityContentLocalizer } from '@ssfrepo/ssf-react-components';
import styled from 'styled-components';
import RibbonText from './ribbon-text';
import { useSiteMetaData } from '/src/hooks/use-site-metadata';

const DecorationAnchor = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
`;

export const StyledGatsbyImage = styled(GatsbyImage)<{
  $altImages?: SanityGatsbyAlternativeImage[] | undefined;
}>`
  ${(props) =>
    props.$altImages && props.$altImages.length
      ? props.$altImages.map(
          (img) =>
            `@media ${img.mediaQuery} { aspect-ratio: ${img.args?.aspectRatio}; }`
        )
      : ''}
`;

export type SanityGatsbyAlternativeImage = {
  image: SanityImageExtras;
  args?: GatsbyImageDataArgs;
  mediaQuery: string;
};

export type SanityGatsbyImageProps = {
  className?: string;
  imageExtras: SanityImageExtras | null | undefined;
  width?: number;
  height?: number;
  imageDataArgs?: GatsbyImageDataArgs;
  /**
   * Aspect ratio should be supplied
   * */
  alternativeImages?: SanityGatsbyAlternativeImage[];
  fullWidth?: boolean;
  loading?: 'lazy' | 'eager';
};

const SanityGatsbyImage = (props: SanityGatsbyImageProps) => {
  const {
    className,
    width,
    height,
    imageExtras,
    imageDataArgs = {},
    alternativeImages,
    fullWidth,
    loading = 'lazy',
  } = props;

  const localize = useSanityContentLocalizer();
  const { projectId, dataset } = useSiteMetaData();
  if (!imageExtras || !imageExtras.asset || !projectId || !dataset) {
    return null;
  }
  const sanityEnv = { projectId, dataset };
  const { altText, ribbonText, ribbonLayout, asset } = imageExtras;

  const alt = localize(altText);
  const ribbon = localize(ribbonText);

  const _imageDataArgs: GatsbyImageDataArgs = {
    width,
    height,
    layout:
      typeof fullWidth === 'boolean' && fullWidth ? 'fullWidth' : 'constrained',
    ...imageDataArgs,
  };
  let image =
    getGatsbyImageData(imageExtras, _imageDataArgs, sanityEnv) || undefined;

  if (!image) {
    return null;
  }
  if (alternativeImages && alternativeImages.length) {
    const altImages = alternativeImages.reduce<IArtDirectedImage[]>(
      (acc, here) => {
        const _image = getGatsbyImageData(
          here.image,
          here.args || {},
          sanityEnv
        );
        if (!_image) return acc;

        return [...acc, { media: here.mediaQuery, image: _image }];
      },
      []
    );

    image = withArtDirection(image, altImages);
  }
  const imageProps: Partial<GatsbyImageProps> = {
    image,
    loading,
    className,
    alt: alt || asset.altText || '',
  };

  if (ribbon) {
    return (
      <DecorationAnchor>
        <StyledGatsbyImage $altImages={alternativeImages} {...imageProps} />
        <RibbonText text={ribbon} ribbonLayout={ribbonLayout} />
      </DecorationAnchor>
    );
  }

  return <StyledGatsbyImage $altImages={alternativeImages} {...imageProps} />;
};

export default SanityGatsbyImage;
