import { RichTableTheme } from 'styled-components';
import { COLORS } from '@ssfrepo/ssf-react-components';

export const richTableDarkThemeImpl: RichTableTheme = {
  fg: COLORS.ssfBlue100,
  header: COLORS.ssfBlue100,
  headerBg: COLORS.ssfBlue900,
  rowOddbg: COLORS.ssfBlue1000,
  rowEvenBg: COLORS.ssfBlue900,
  border: COLORS.ssfBlue900,
  styledLink: COLORS.ssfBlue100,
};
