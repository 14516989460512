import { ContactUsTheme } from 'styled-components';
import { COLORS } from '@ssfrepo/ssf-react-components';

export const contactUsDarkThemeImpl: ContactUsTheme = {
  officeListHeader: COLORS.ssfBlue100,
  officeButtonFg: COLORS.ssfBlue100,
  searchHeader: COLORS.ssfBlue100,
  searchInputBg: COLORS.ssfBlue1000,
  searchInputButtonFg: COLORS.ssfBlue100,
  searchInputButtonBg: COLORS.ssfBlue1000,
  searchInputButtonFocusBg: 'transparent',
  searchResultTitle: COLORS.ssfBlue100,
  employeeRouteButtonFg: COLORS.ssfBlue100,
  employeeContactInfoLink: COLORS.ssfBlue100,
  employeeRouteButtonBg: COLORS.ssfBlue900,
  employeeRouteButtonBorder: COLORS.ssfBlue500,
  employeeRouteButtonBorderHover: COLORS.ssfBlue400,
  employeeRouteButtonBorderFocus: COLORS.ssfBlue400,
  categoryTitle: COLORS.ssfBlue100,
};
