import { CampaignArticleTheme, css, DefaultTheme } from 'styled-components';
import { lightChatBoxThemeImpl } from './chat-light-impl';
import { lightBigLinkThemeImpl } from './big-link-light-impl';
import { lightBreadcrumbThemeImpl } from './breadcrumb-light-impl';
import { lightOpenMenuButtonThemeImpl } from '../../../components/layout/menu/open-menu-button/theme/open-menu-button-light-impl';
import { lightMenuGroupExpanderThemeImpl } from '../../../components/layout/menu/menu-group-expander/theme/menu-group-expander-light-impl';
import { lightWpPostFrontSectionThemeImpl } from '../../../domains/meirennbank/meirennbank-post-picker/theme/wp-post-front-section-light-impl';
import { lightWpPostCardThemeImpl } from '../../../domains/meirennbank/wp-post-card/theme/wp-post-card-light-impl';
import {
  lightSSFScrollTertiaryThemeImpl,
  lightSSFScrollThemeImpl,
} from './ssf-scroll-light-impl';
import { lightPieceSectionClusterThemeImpl } from '/src/components/content/sections/theme/piece-section-cluster-light-impl';
import { lightFactsBoxThemeImpl } from '/src/components/content/facts-box/theme/facts-box-light-impl';
import { CalculatorStyledThemeLightImpl } from '/src/components/content/calculators/theme/calculator-styled-light-impl';
import { faqThemeLightImpl } from '/src/domains/faq-page/theme/faq-light-impl';
import { linkListThemeLightImpl } from '/src/components/content/link-list/theme/link-list-light-impl';
import {
  COLORS,
  lightBlockContentThemeImpl,
  lightFormsThemeImpl,
} from '@ssfrepo/ssf-react-components';
import { lightCommonThemeGatsbyImpl } from './common-light-impl';
import { lightButtonThemeGatsbyImpl } from './button-light-impl';
import { SideButtonLightThemeImpl } from '/src/components/layout/side-buttons/theme/side-button-light-theme-impl';
import { employeeSetLightThemeImpl } from '/src/components/content/employee-set/theme/employee-set-light-theme-impl';
import { productPriceLightThemeImpl } from '/src/domains/pricing/theme/product-price-light-theme-impl';
import { stepByStepLightThemeImpl } from '/src/components/content/step-by-step/theme/step-by-step-light-theme-impl';
import { contactUsLightThemeImpl } from '/src/domains/contact-us/components/theme/contact-us-light-theme-impl';
import { searchLightThemeImpl } from '/src/domains/search/components/theme/search-light.impl';
import { richTableLightThemeImpl } from '/src/components/content/rich-table/theme/rich-table-light-theme-impl';
import { audioPlayerLightThemeImpl } from '/src/components/content/kongleriket/theme/audio-player-light-theme-impl';
import { frendeWebshopLightThemeImpl } from '/src/templates/template-theming/frende/frende-theme';
import { lightExpandableStepByStepThemeImpl } from '../../../components/content/expandable-step-by-step/theme/expandable-step-by-step-light-impl';

const themeId = 'light';

export const LightBodyStyles = css`
  .${themeId} {
    --common-layout-main-bg: ${COLORS.ssfBackground};
    --common-layout-topbar-bg: ${COLORS.ssfGrey50};
    --common-layout-logo-image: url('/images/logo-light-mode.svg');
    --article-block-paragraph-color: ${COLORS.ssfGrey500};
    --common-layout-topbar-box-shadow: none;
    --common-layout-topbar-border-bottom: 1px solid ${COLORS.ssfGreyLight};
  }
`;

export const lightModeTheme: DefaultTheme = {
  themeId,
  buttons: lightButtonThemeGatsbyImpl,
  blockContent: lightBlockContentThemeImpl,
  common: lightCommonThemeGatsbyImpl,
  forms: lightFormsThemeImpl,
  chat: lightChatBoxThemeImpl,
  bigLink: lightBigLinkThemeImpl,
  breadcrumb: lightBreadcrumbThemeImpl,
  openMenuButton: lightOpenMenuButtonThemeImpl,
  menuGroupExpander: lightMenuGroupExpanderThemeImpl,
  wpPostFrontSection: lightWpPostFrontSectionThemeImpl,
  wpPostCard: lightWpPostCardThemeImpl,
  ssfScroll: lightSSFScrollThemeImpl,
  ssfScrollTertiary: lightSSFScrollTertiaryThemeImpl,
  campaignArticle: {} as CampaignArticleTheme,
  pieceSectionCluster: lightPieceSectionClusterThemeImpl,
  factsBox: lightFactsBoxThemeImpl,
  calculatorStyled: CalculatorStyledThemeLightImpl,
  faq: faqThemeLightImpl,
  linkList: linkListThemeLightImpl,
  sideButton: SideButtonLightThemeImpl,
  employeeSet: employeeSetLightThemeImpl,
  productPrice: productPriceLightThemeImpl,
  stepByStep: stepByStepLightThemeImpl,
  contactUs: contactUsLightThemeImpl,
  search: searchLightThemeImpl,
  richTable: richTableLightThemeImpl,
  audioPlayer: audioPlayerLightThemeImpl,
  frendeWebshop: frendeWebshopLightThemeImpl,
  expandableStepByStep: lightExpandableStepByStepThemeImpl,
};
