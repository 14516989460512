import { css, FlattenSimpleInterpolation } from 'styled-components';
import { mediaBreakPoints } from '/src/sass/styled-constants';

export const fontFamilies = {
  light: 'AtlasGrotesk-Light',
  regular: 'AtlasGrotesk-Regular',
  medium: 'AtlasGrotesk-Medium',
  regularItalic: 'AtlasGrotesk-RegularItalic, Roboto',
};

export interface FontSizes {
  h1: FlattenSimpleInterpolation;
  h2: FlattenSimpleInterpolation;
  h3: FlattenSimpleInterpolation;
  h4: FlattenSimpleInterpolation;
  ingress: FlattenSimpleInterpolation;
  normal: FlattenSimpleInterpolation;
  small: FlattenSimpleInterpolation;
}

export const fontSizes: FontSizes = {
  h1: css`
    font-size: 32px;
    line-height: 48px;
  `,
  h2: css`
    font-size: 30px;
    line-height: 45px;
  `,
  h3: css`
    font-size: 24px;
    line-height: 36px;
  `,
  h4: css`
    font-size: 18px;
    line-height: 27px;
  `,
  ingress: css`
    font-size: 20px;
    line-height: 30px;
  `,
  normal: css`
    font-size: 16px;
    line-height: 24px;
  `,
  small: css`
    font-size: 14px;
    line-height: 21px;
  `,
};

const _mobileh2 = css`
  font-size: 20px;
  line-height: 30px;
`;
export const _mobileNormal = css`
  font-size: 16px;
  line-height: 24px;
`;
export const fontSizesMobile: FontSizes = {
  h1: css`
    font-size: 24px;
    line-height: 36px;
  `,
  h2: _mobileh2,
  h3: _mobileh2,
  h4: _mobileNormal,
  ingress: _mobileNormal,
  normal: _mobileNormal,
  small: css`
    font-size: 12px;
    line-height: 18px;
  `,
};

export const fontHeaderOneish = css`
  font-family: ${fontFamilies.light};
  ${fontSizes.h1}
  @media screen and (max-width: ${mediaBreakPoints.sm}) {
    ${fontSizesMobile.h1}
  }
`;

export const fontHeaderTwoish = css`
  font-family: ${fontFamilies.light};
  ${fontSizes.h2}
  @media screen and (max-width: ${mediaBreakPoints.sm}) {
    ${fontSizesMobile.h2}
  }
`;

export const fontHeaderThreeish = css`
  font-family: ${fontFamilies.medium};
  font-weight: 500;
  ${fontSizes.h3}
  @media screen and (max-width: ${mediaBreakPoints.sm}) {
    ${fontSizesMobile.h3}
  }
`;

export const fontHeaderFourish = css`
  font-family: ${fontFamilies.medium};
  font-weight: 500;
  ${fontSizes.h4}
  @media screen and (max-width: ${mediaBreakPoints.sm}) {
    ${fontSizesMobile.h4}
  }
`;

export const fontHeaderFourishAsRegular = css`
  font-family: ${fontFamilies.regular};
  ${fontSizes.h4}
  @media screen and (max-width: ${mediaBreakPoints.sm}) {
    ${fontSizesMobile.h4}
  }
`;

export const fontHeaderFourishAsLight = css`
  font-family: ${fontFamilies.light};
  ${fontSizes[18]}
`;

export const fontIngressAsRegular = css`
  font-family: ${fontFamilies.regular};
  ${fontSizes.ingress}
  @media screen and (max-width: ${mediaBreakPoints.sm}) {
    ${fontSizesMobile.ingress}
  }
`;

export const fontIngressAsLight = css`
  font-family: ${fontFamilies.light};
  ${fontSizes.ingress}
  @media screen and (max-width: ${mediaBreakPoints.sm}) {
    ${fontSizesMobile.ingress}
  }
`;

export const fontIngressAsMedium = css`
  font-family: ${fontFamilies.medium};
  font-weight: 500;
  ${fontSizes.ingress}
  @media screen and (max-width: ${mediaBreakPoints.sm}) {
    ${fontSizesMobile.ingress}
  }
`;

export const fontNormalish = css`
  font-family: ${fontFamilies.regular};
  ${fontSizes.normal}
  @media screen and (max-width: ${mediaBreakPoints.sm}) {
    ${fontSizesMobile.normal}
  }
`;

export const fontNormalishAsMedium = css`
  font-family: ${fontFamilies.medium};
  font-weight: 500;
  ${fontSizes.normal}
  @media screen and (max-width: ${mediaBreakPoints.sm}) {
    ${fontSizesMobile.normal}
  }
`;

export const fontSmallish = css`
  font-family: ${fontFamilies.regular};
  ${fontSizes.small}
  @media screen and (max-width: ${mediaBreakPoints.sm}) {
    ${fontSizesMobile.small}
  }
`;

export const fontSmallishAsMedium = css`
  font-family: ${fontFamilies.medium};
  font-weight: 500;
  ${fontSizes.small}
  @media screen and (max-width: ${mediaBreakPoints.sm}) {
    ${fontSizesMobile.small}
  }
`;

export const fontNormalishAsItalicRegular = css`
  font-family: ${fontFamilies.regularItalic};
  ${fontSizes.normal};

  @media screen and (max-width: ${mediaBreakPoints.sm}) {
    ${fontSizesMobile.normal}
  }
`;
