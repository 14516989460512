import { css } from 'styled-components';
import { COLORS } from '@ssfrepo/ssf-react-components';

export const ModalArrowConnector = css`
  bottom: 100%;
  border: solid transparent;
  content: '';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: transparent;
  border-bottom-color: ${({ theme }) =>
    theme.search.modalBgAndAfterBottomBorder};
  border-width: 15px;
  margin-left: -15px;
`;
