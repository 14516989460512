import { shadows, mediaBreakPoints } from '/src/sass/styled-constants';
import { ButtonTheme, css } from 'styled-components';
import { COLORS } from '@ssfrepo/ssf-react-components';

const DisabledButtonStyles = css`
  background-color: ${COLORS.ssfWhite};
  border: 1px solid ${COLORS.ssfGrey300};
  color: ${COLORS.ssfGrey300};
  cursor: not-allowed;

  :hover,
  :focus-visible {
    border: 1px solid ${COLORS.ssfGrey300};
    box-shadow: ${shadows.ssfButtonShadow};
  }
`;

export const pmThemeImpl: ButtonTheme = {
  primary: {
    bg: COLORS.ssfRedBrown,
    fg: COLORS.ssfWhite,
    hoverBg: COLORS.ssfDarkPrimaryText,
    hoverFg: COLORS.ssfWhite,
    disabledStyles: DisabledButtonStyles,
  },
  secondary: {
    bg: COLORS.ssfWhite,
    fg: COLORS.ssfRedBrown,
    hoverBg: COLORS.ssfSandyBackground,
    hoverFg: COLORS.ssfRedBrown,
    disabledStyles: DisabledButtonStyles,
    supplemental: css`
      border: 1px solid ${COLORS.ssfRedBrown};
    `,
  },
  tertiary: {
    bg: COLORS.ssfSandyBackground,
    fg: COLORS.ssfRedBrown,
    hoverBg: COLORS.ssfWhite,
    hoverFg: COLORS.ssfRedBrown,
    disabledStyles: DisabledButtonStyles,
  },
  quaternary: {
    bg: COLORS.kongleriketDarkGreen,
    fg: COLORS.ssfWhite,
    hoverBg: COLORS.ssfGreen500,
    hoverFg: COLORS.ssfWhite,
    disabledStyles: DisabledButtonStyles,
  },
  close: {
    color: COLORS.ssfWhite,
  },
  icon: {
    buttonColor: COLORS.ssfWhite,
    plainColor: 'rgba(0, 0, 0, 0.54)',
  },
  searchButton: {
    fg: COLORS.ssfBlue900,
    hoverBg: COLORS.ssfBlue100,
    stylingWithMediaBreakPoints: css`
      background: none;
      border: 1px solid ${COLORS.ssfBlue900};
      @media (max-width: ${mediaBreakPoints.sideMenu}) {
        border: 1px solid ${COLORS.ssfWhite};
      }
    `,
  },
  tabButton: {
    fg: COLORS.ssfBlue500,
    borderButtonSelected: COLORS.ssfBlue400,
    borderButtonNonSelected: COLORS.ssfBlue400,
  },
  readMoreButton: {
    spanColor: COLORS.ssfBlue400,
    fg: COLORS.ssfBlue400,
  },
  collapsible: {
    divider: '#b0c5d6',
    text: COLORS.ssfBlue400,
  },
};
