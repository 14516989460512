import { ExpendableStepByStepTheme } from 'styled-components';
import { COLORS } from '@ssfrepo/ssf-react-components';

export const darkExpandableStepByStepThemeImpl: ExpendableStepByStepTheme = {
  bg: 'none',
  iconBg: COLORS.ssfBlue1000,
  iconColor: COLORS.ssfBlue300,
  hoverEffect: COLORS.ssfBlue100,
  text: COLORS.ssfWhite,
  closeButton: COLORS.ssfWhite,
};
