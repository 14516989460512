import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { mediaBreakPoints } from '/src/sass/styled-constants';
import { SanityMenuGrouping } from '/src/types/sanity/menu/menu-grouping';
import { getMappedComponentKey } from '/src/utils/gatsby-utils';
import ConditionalLink from '/src/components/core/conditional-link';
import { MenuLink } from '../../styled';
import { useSanityContentLocalizer } from '@ssfrepo/ssf-react-components';
import { MenuGroupTitle } from '../styled';
import FontIcon from '@ssfrepo/ssf-react-components/lib/buttons/font-icon';
import { useLocation } from '@reach/router';

const MenuGroupHeader = styled.div<{ $isExpanded }>`
  position: relative;
  align-items: center;
  display: flex;
  justify-content: space-between;
  min-height: 58px;
  padding: 5px 20px;
  outline-style: none;
  background: transparent;
  cursor: pointer;
  :focus-visible {
    outline: 2px solid orange;
  }
  @media (max-width: ${mediaBreakPoints.sm}) {
    padding: 0px 10px;
    min-height: 0px;
  }
  ${(props) =>
    props.$isExpanded
      ? `border-bottom: solid 1px ${props.theme.menuGroupExpander.active};
         `
      : ''}
`;

const ExpanderIcon = styled(FontIcon)`
  color: ${(p) => p.theme.menuGroupExpander.fg} !important;
  font-size: 20px;
  padding: 5px;
`;

const MenuGroup = styled.div`
  box-sizing: border-box;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 5px;
`;

const StyledMenuGroupTitle = styled(MenuGroupTitle)`
  color: ${(p) => p.theme.menuGroupExpander.fg};
  margin: 10px 10px 10px 20px;
`;

const MenuListContainer = styled.div``;

const MenuGroupList = styled.div``;

const MenuGroupLink = styled(ConditionalLink)<{ $isActive }>`
  display: block;
  padding: 15px 15px 15px 40px;
  color: ${(p) => p.theme.menuGroupExpander.fg} !important;
  &:hover {
    text-decoration: none;
  }
  ${MenuLink}

  :first-child {
    margin-top: 10px;
  }
  ${(props) =>
    props.$isActive
      ? `background: ${props.theme.menuGroupExpander.active};`
      : ''};
`;

type Props = {
  className?: string;
  menuGroupings: SanityMenuGrouping[];
  onNavigation: () => void;
};

const MenuGroupExpander = (props: Props) => {
  const { menuGroupings, onNavigation } = props;
  const localize = useSanityContentLocalizer();
  const [expanded, setExpanded] = useState('');
  const currentLocation = useLocation();

  useEffect(() => {
    menuGroupings.map((menuGrouping, menuGroupIdx) => {
      const activeLink = menuGrouping.links.find(
        (link) => link?.slug?.current === currentLocation.pathname
      );
      if (activeLink && activeLink.slug && activeLink.slug.current) {
        setExpanded(getMappedComponentKey(menuGrouping, menuGroupIdx));
      }
    });
  }, [menuGroupings, currentLocation]);

  const localStoredExpandedKey = window.localStorage.getItem('lastExpandedKey');

  return (
    <MenuListContainer>
      {menuGroupings.map((menuGrouping, menuGroupIdx) => {
        const key = getMappedComponentKey(menuGrouping, menuGroupIdx);
        return (
          <MenuGroup
            key={key}
            tabIndex={0}
            onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
              if (event.key === 'Enter') {
                expanded !== key ? setExpanded(key) : setExpanded('');
                localStoredExpandedKey !== key
                  ? window.localStorage.setItem('lastExpandedKey', key)
                  : window.localStorage.setItem('lastExpandedKey', '');
              }
            }}
          >
            <MenuGroupHeader
              $isExpanded={localStoredExpandedKey === key}
              onClick={() => {
                expanded !== key ? setExpanded(key) : setExpanded('');
                localStoredExpandedKey !== key
                  ? window.localStorage.setItem('lastExpandedKey', key)
                  : window.localStorage.setItem('lastExpandedKey', '');
              }}
            >
              <StyledMenuGroupTitle>
                {localize(menuGrouping.title)}
              </StyledMenuGroupTitle>
              <ExpanderIcon
                iconClassName={
                  localStoredExpandedKey === key
                    ? 'ssf-arrow-up'
                    : 'ssf-arrow-down'
                }
              ></ExpanderIcon>
            </MenuGroupHeader>
            {localStoredExpandedKey === key && (
              <MenuGroupList>
                {menuGrouping.links.map((link, _idx) => (
                  <MenuGroupLink
                    key={getMappedComponentKey(link, _idx)}
                    link={link}
                    $isActive={currentLocation.pathname === link?.slug?.current}
                    onClick={() => {
                      onNavigation();
                    }}
                  >
                    {localize(link.title)}
                  </MenuGroupLink>
                ))}
              </MenuGroupList>
            )}
          </MenuGroup>
        );
      })}
    </MenuListContainer>
  );
};

export default MenuGroupExpander;
