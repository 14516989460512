import React, { MouseEventHandler } from 'react';
import styled from 'styled-components';
import { shadows, mediaBreakPoints } from '/src/sass/styled-constants';
import FontIcon from '@ssfrepo/ssf-react-components/lib/buttons/font-icon';
import { fontNormalishAsMedium } from '/src/sass/typography/typography-styled';

const StyledButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  min-width: 107px;
  min-height: 45px;
  padding: 5px 10px;
  background: ${(p) => p.theme.openMenuButton.bg};
  color: ${(p) => p.theme.openMenuButton.fg};
  border: 1px solid ${(p) => p.theme.openMenuButton.border};
  border-radius: 25px;
  box-shadow: ${shadows.ssfButtonShadow};
  :hover {
    background: ${(p) => p.theme.openMenuButton.hover};
  }

  @media (max-width: ${mediaBreakPoints.sm}) {
    min-width: 95px;
  }
`;

const ButtonText = styled.span`
  display: inline-block;
  margin-left: 5px;
  ${fontNormalishAsMedium}
`;

const StyledFontIcon = styled(FontIcon)`
  color: ${(p) => p.theme.openMenuButton.fg} !important;
`;

type Props = {
  isMenuOpen: boolean;
  menuLabel?: string;
  onClick: MouseEventHandler<HTMLButtonElement>;
};

const OpenMenuButton = (props: Props) => {
  const { isMenuOpen, menuLabel, onClick } = props;

  return (
    <StyledButton onClick={onClick}>
      <StyledFontIcon>{isMenuOpen ? 'cancel' : 'menu'}</StyledFontIcon>
      {!!menuLabel && <ButtonText>{menuLabel}</ButtonText>}
    </StyledButton>
  );
};

export default OpenMenuButton;
