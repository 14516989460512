import { SanityTranslations } from '/src/types/sanity/core/sanity-translation';
import {
  SanityLocaleString,
  SanitySingletonWorldCountries,
  SanityWorldCountry,
} from '@ssfrepo/ssf-sanity-utils';
import { SanitySingletonSeo } from '/src/types/sanity/core/sanity-seo';
import { SsfCurrencyNode } from '/src/types/apis/ssf-currency';
import { mapTranslations } from '/src/types/sanity/core/sanity-translation';
import get from 'lodash.get';
import { edgesToFlatNodeArray } from '/src/utils/graphql-utils';
import { SanityFooter } from '/src/types/sanity/footer';
import { SanitySingletonMenuGroupings } from '/src/types/sanity/menu/menu-grouping';
import { SanityTopBar } from '/src/types/sanity/menu/topbar';
import { SanitySingletonCurrencySetup } from '../types/sanity/banking/currency-setup';
import { graphql, useStaticQuery } from 'gatsby';
import { SanityChatButton } from '../types/sanity/content/chat-button';

export class TemplateAgnosticCache {
  constructor(
    private _translations: SanityTranslations = {},
    private _seo?: SanitySingletonSeo,
    private _singletonWorldCountries?: SanitySingletonWorldCountries,
    private _currencies?: SsfCurrencyNode[],
    private _footer?: SanityFooter,
    private _topbar?: SanityTopBar,
    private _menuGroupings?: SanitySingletonMenuGroupings,
    private _currencySetup?: SanitySingletonCurrencySetup,
    private singletonChatButton?: SanityChatButton
  ) {}

  public Chatbutton(): SanityChatButton | undefined {
    return this.singletonChatButton;
  }

  public footer(): SanityFooter | undefined {
    return this._footer;
  }

  public topBar(): SanityTopBar | undefined {
    return this._topbar;
  }

  public menuGroupings(): SanitySingletonMenuGroupings | undefined {
    return this._menuGroupings;
  }

  public translate(key: string): SanityLocaleString {
    if (!this._translations[key]) {
      if (process.env.NODE_ENV === 'production') {
        return { nn: '', en: '' };
      }
      return { nn: key, en: key };
    }

    return this._translations[key];
  }

  public seo(): SanitySingletonSeo | undefined {
    return this._seo;
  }

  public countries(): SanityWorldCountry[] {
    if (!this._singletonWorldCountries) {
      throw new Error('World Countries needs to be set in sanity.');
    }
    return this._singletonWorldCountries.countries;
  }

  public currencies(): SsfCurrencyNode[] {
    if (!this._currencies) {
      throw new Error('Ssf Currencies not available');
    }
    return this._currencies;
  }

  public currencySetup(): SanitySingletonCurrencySetup {
    if (!this._currencySetup) {
      throw new Error('Currency setup not available');
    }

    return this._currencySetup;
  }
}

export const useTemplateAgnosticCacheFactory = (): TemplateAgnosticCache => {
  const cacheData = useStaticQuery(graphql`
    {
      ...AgnosticCacheData
    }
  `);
  // Data keys are from the graphql fragment AgnosticCacheData
  return new TemplateAgnosticCache(
    mapTranslations(get(cacheData, 'translations')),
    get(cacheData, 'seo'),
    get(cacheData, 'singletonWorldCountries'),
    edgesToFlatNodeArray(get(cacheData, 'currencies')),
    get(cacheData, 'footer'),
    get(cacheData, 'topBar'),
    get(cacheData, 'menuGroupings'),
    get(cacheData, 'currencySetup'),
    get(cacheData, 'singletonChatButton')
  );
};
