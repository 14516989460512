import isEqual from 'lodash.isequal';
import React, { useEffect, useRef } from 'react';
import { BehaviorSubject, Subject, timer } from 'rxjs';
import { debounceTime, filter, map, take, tap } from 'rxjs/operators';
import styled from 'styled-components';
import { SanityVideo } from '/src/types/sanity/content/video';
import { getMappedComponentKey } from '/src/utils/gatsby-utils';

const CastContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
`;

type Props = {
  className?: string;
  media: SanityVideo;
};

const Cast = (props: Props) => {
  const { media } = props;
  const { dacastContentId } = media;
  const containerRef = useRef<HTMLDivElement>(null);

  const { current } = containerRef;

  useEffect(() => {
    if (
      typeof window === 'undefined' ||
      !current ||
      !media ||
      !media.dacastContentId
    ) {
      return;
    }
    let castPlayer: any | undefined = undefined;
    const allowResize = new BehaviorSubject<boolean>(true);
    const previousSizes = new BehaviorSubject<
      { width: number; height: number } | undefined
    >(undefined);
    const resizeEvent = new Subject<any>();

    const getSizes = () => {
      const width = current?.clientWidth || 800;
      const sizes = {
        width,
        height: width * 0.75,
      };
      return sizes;
    };
    const handleResize = () => {
      resizeEvent.next(1);
    };

    const switchAllowResize = (newVal: boolean) => () => {
      if (newVal) {
        timer(3000).subscribe(() => {
          allowResize.next(newVal);
        });
      } else {
        allowResize.next(newVal);
      }
    };

    const initialize = (castSizes) => {
      // @ts-ignore
      const myPlayer = (window as any).dacast(
        media.dacastContentId,
        getMappedComponentKey(media, 0),
        {
          ...castSizes,
        }
      );
      myPlayer.on('fullscreen_enter', switchAllowResize(false));
      myPlayer.on('fullscreen_exit', switchAllowResize(true));
      return myPlayer;
    };

    const resizeSub = resizeEvent
      .pipe(
        filter(() => allowResize.getValue()),
        debounceTime(2000),
        filter(() => allowResize.getValue()),
        map(getSizes),
        filter((sizes) => {
          const prev = previousSizes.getValue();
          return !prev || !isEqual(sizes, prev);
        }),
        tap({
          next: () => {
            try {
              if (castPlayer) castPlayer.dispose();

              castPlayer = undefined;
            } catch (e) {
              console.error(e);
            }
          },
        }),
        debounceTime(3000),
        tap({
          next: (castSizes) => {
            castPlayer = initialize(castSizes);
            previousSizes.next(castSizes);
          },
        })
      )
      .subscribe();

    const initSub = timer(1000, 20000)
      .pipe(
        filter(() => {
          return typeof (window as any).dacast === 'function' && !!current;
        }),
        take(1),
        tap({
          next: () => {
            const sizes = getSizes();
            castPlayer = initialize(sizes);
            previousSizes.next(sizes);
            window.addEventListener('resize', handleResize);
          },
        })
      )
      .subscribe();

    return () => {
      resizeSub.unsubscribe();

      window.removeEventListener('resize', handleResize);
      initSub.unsubscribe();
    };
  }, [media, current]);

  return (
    <CastContainer ref={containerRef} id={getMappedComponentKey(media, 0)}>
      <br />
      <script
        src={`https://player.dacast.com/js/player.js?contentId=${dacastContentId} `}
        type='text/javascript'
      />

    </CastContainer>
  );
};

export default Cast;
