import React from 'react';
import styled from 'styled-components';
import { useSanityContentLocalizer } from '@ssfrepo/ssf-react-components';
import ConditionalLink from '/src/components/core/conditional-link';
import ButtonLikeDiv from '/src/components/buttons/button-like-div';
import get from 'lodash.get';
import { sanityLinkType } from '/src/types/sanity/core/sanity-routing';
import { SanityLink, SanityLocaleString } from '@ssfrepo/ssf-sanity-utils';
import { ButtonArityColorScheme } from '@ssfrepo/ssf-react-components';

const ConditionalLinkMargin = styled(ConditionalLink)`
  margin-left: 10px;
`;

type Props = {
  className?: string;
  secondary?: boolean;
  quaternary?: boolean;
  hulkmode?: boolean;
  hideLeftIcon?: boolean;
  canBecomeTextOnly?: boolean;
  allowRightIcon?: boolean;
  link: SanityLink;
  caption?: SanityLocaleString;
  colorScheme?: ButtonArityColorScheme;
};

/**
 * Default button appearance using SSF sanitys external/internal links.
 */
const DefaultButtonLink = (props: Props) => {
  const {
    link,
    className = '',
    secondary = false,
    quaternary = false,
    colorScheme,
    hideLeftIcon = false,
    canBecomeTextOnly = false,
    caption = {},
    allowRightIcon = false,
  } = props;

  const localize = useSanityContentLocalizer();
  if (!link) {
    return null;
  }
  const iconType = sanityLinkType(link);
  const iconClassName = !hideLeftIcon ? get(link, 'icon.icon') : '';

  let rightIconClassName = '';
  if (allowRightIcon) {
    switch (iconType) {
      case 'external':
        rightIconClassName = 'ssf-logout_exit_door';
        break;
      case 'file':
        rightIconClassName = 'ssf-download_computer_files2';
        break;
      case 'internal':
        rightIconClassName = get(link, 'icon.icon', '');
        break;
      default:
        break;
    }
  }

  const text = localize(caption) || localize(link.title);
  let _colorScheme = colorScheme;
  if (_colorScheme === undefined && secondary) {
    _colorScheme = 'secondary';
  }
  if (_colorScheme === undefined && quaternary) {
    _colorScheme = 'quaternary';
  }
  if (
    (_colorScheme === undefined && !secondary && !quaternary) ||
    _colorScheme === undefined
  ) {
    _colorScheme = 'primary';
  }

  return (
    <ConditionalLinkMargin className={className} link={link}>
      <ButtonLikeDiv
        iconClassName={iconClassName}
        rightIconClassName={rightIconClassName}
        text={text}
        colorScheme={_colorScheme}
        canBecomeTextOnly={canBecomeTextOnly}
      />
    </ConditionalLinkMargin>
  );
};

export default DefaultButtonLink;
