import { searchableGenericPage } from './documents/searchable-generic-page';
import { PageSearchDocument, SsfFuseSearchIndex } from '/src/types/core/search';
import {
  querySanityExposeErrors,
  SanityClientConfig,
} from '@ssfrepo/ssf-sanity-utils';
import Fuse from 'fuse.js';
import { searchablePieceSectionPage } from './documents/searchable-piece-section-page';
import { searchableFaq } from './documents/searchable-faq';
import { searchableEmployee } from './documents/searchable-employee';
import { searchableOffice } from './documents/searchable-office';

export const fuseOptions = {
  keys: [
    {
      name: 'title',
      weight: 3,
    },
    {
      name: 'subtitles',
      weight: 1,
    },
    {
      name: 'searchKeywords',
      weight: 4,
    },
  ],
  ignoreLocation: true,
  threshold: 0.4,
};

export type PageSearchDocImplResult =
  | 'error'
  | {
      nnDocuments: PageSearchDocument[];
      enDocuments: PageSearchDocument[];
    };

export const queryDocs = async <T>(
  sanityConfig: SanityClientConfig,
  groqQuery: string
): Promise<'error' | T> => {
  return querySanityExposeErrors<T>(groqQuery, sanityConfig).catch((err) => {
    if (err) {
      console.error(JSON.stringify(err, null, 2));
    }
    return 'error';
  });
};

export const constructSearchIndex = async (
  sanityConfig: SanityClientConfig
): Promise<SsfFuseSearchIndex | 'error'> => {
  const queryPromises: Promise<PageSearchDocImplResult>[] = [];

  queryPromises.push(searchableGenericPage(sanityConfig));
  queryPromises.push(searchablePieceSectionPage(sanityConfig));
  queryPromises.push(searchableFaq(sanityConfig));
  queryPromises.push(searchableEmployee(sanityConfig));
  queryPromises.push(searchableOffice(sanityConfig));

  const res = await Promise.all(queryPromises);
  const nnDocuments = res.reduce<PageSearchDocument[]>(
    (acc, here) => (here !== 'error' ? [...acc, ...here.nnDocuments] : acc),
    []
  );
  const enDocuments = res.reduce<PageSearchDocument[]>(
    (acc, here) => (here !== 'error' ? [...acc, ...here.enDocuments] : acc),
    []
  );

  const searchData: SsfFuseSearchIndex = {
    fuse: {
      nn: {
        index: null,
        documents: nnDocuments,
      },
      en: {
        index: null,
        documents: enDocuments,
      },
    },
  };

  searchData.fuse.nn.index = Fuse.createIndex<PageSearchDocument>(
    fuseOptions.keys,
    searchData.fuse.nn.documents
  );
  searchData.fuse.en.index = Fuse.createIndex<PageSearchDocument>(
    fuseOptions.keys,
    searchData.fuse.en.documents
  );
  return searchData;
};
