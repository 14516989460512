import slimObjectHash from 'slim-object-hash';

export const getMappedComponentKey = (object: any, idx: number) => {
  return `${slimObjectHash(object)}_${idx}`;
};

export const uuidv4 = () => {
  // @ts-ignore
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
};

export const bucketize = <T>(arr: T[], bucketSize: number): T[][] => {
  if (!arr || !arr.length) {
    return [];
  }
  const result: T[][] = [[]];
  let arrIdx = 0;
  let bucketIdx = 0;
  while (arrIdx < arr.length) {
    if (result[bucketIdx].length >= bucketSize) {
      bucketIdx++;
      result[bucketIdx] = [];
    }
    result[bucketIdx].push(arr[arrIdx]);

    arrIdx++;
  }
  return result;
};
