import {
  ButtonArityColorScheme,
  ButtonFontIcon,
  ButtonText,
  ThemedButtonContainer,
} from '@ssfrepo/ssf-react-components';
import React from 'react';
import { mediaBreakPoints } from '/src/sass/styled-constants';
import styled, { useTheme } from 'styled-components';

export const ButtonLikeDivContainer = styled.div<{
  $arity: ButtonArityColorScheme;
}>`
  ${(p) => ThemedButtonContainer({ disabled: false, $arity: p.$arity })}
`;

const ScaleToTextOnlyIcon = styled(ButtonFontIcon)`
  @media (max-width: ${mediaBreakPoints.sm}) {
    display: none;
  }
`;

type Props = {
  className?: string;
  iconClassName?: string;
  rightIconClassName?: string;
  text: string;
  colorScheme: ButtonArityColorScheme;
  canBecomeTextOnly?: boolean;
};

const ButtonLikeDiv = (props: Props) => {
  const {
    colorScheme,
    iconClassName,
    rightIconClassName,
    text,
    className,

    canBecomeTextOnly,
  } = props;

  const OurIcon = canBecomeTextOnly ? ScaleToTextOnlyIcon : ButtonFontIcon;
  const theme = useTheme();
  const scheme = theme.buttons[colorScheme];

  return (
    <ButtonLikeDivContainer $arity={colorScheme} className={className}>
      {iconClassName && (
        <OurIcon $iconColor={scheme.fg} iconClassName={iconClassName} />
      )}
      <ButtonText>{text}</ButtonText>
      {rightIconClassName && (
        <OurIcon $iconColor={scheme.fg} iconClassName={rightIconClassName} />
      )}
    </ButtonLikeDivContainer>
  );
};

export default ButtonLikeDiv;
