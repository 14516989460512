import {
  SanityClientConfig,
  SanityLink,
  SanityLocaleBlockContent,
  SanityLocaleString,
} from '@ssfrepo/ssf-sanity-utils';
import groq from 'groq';
import { PageSearchDocImplResult, queryDocs } from '..';
import { extractIngresslessBlockContentBodySummary } from './block-content-extractors';
import { PageSearchDocument } from '/src/types/core/search';

const path = (faq: FaqDocument, faqPages: FaqPageDocument[]): string | null => {
  if (!faq || !faq._id || !faq.faqCategories || !faq.faqCategories.length) {
    return null;
  }
  const firstCategory = faq.faqCategories.find((fc) => !!fc);
  if (!firstCategory) {
    return null;
  }

  let pageSlug = '';
  faqPages.forEach((p) => {
    if (!p.faqCategoryGroups) {
      return;
    }
    p.faqCategoryGroups.forEach((group) => {
      if (group && group._id === firstCategory.group._id) {
        pageSlug = p.route.slug?.current || '';
        return;
      }
    });
  });
  if (!pageSlug) {
    return null;
  }
  const slug = `${pageSlug}/${firstCategory.group.value}/${firstCategory.value}`;
  return `${slug}?faq=${faq._id}`;
};

const description = (doc: FaqDocument, language: string): string[] => {
  const _description: string[] = [];
  const blocks = doc.richAnswer?.[language] || [];
  _description.push(...extractIngresslessBlockContentBodySummary(blocks));
  return _description;
};

interface FaqCategoryGroupDocument {
  _id: string;
  value: string;
}

interface FaqCategoryDocument {
  value: string;
  group: FaqCategoryGroupDocument;
}

interface FaqDocument {
  _id: string;
  richAnswer: SanityLocaleBlockContent;
  question: SanityLocaleString;
  faqCategories: (FaqCategoryDocument | null)[] | null;
}

interface FaqPageDocument {
  route: SanityLink;
  faqCategoryGroups: (FaqCategoryGroupDocument | null)[] | null;
}

interface GroqQueryFaqResult {
  faqs: FaqDocument[];
  faqPages: FaqPageDocument[];
}

const GROQ_QUERY_FAQ = groq`
{
    'faqs': *[_type == 'faq']{
        _id,
        richAnswer,
        question,
        faqCategories[]->{
            value,
            group->{
              _id,
              value
            }
        }
    },
    'faqPages': *[_type == 'faqPage']{
        route->{
            slug
        },
        faqCategoryGroups[]->{
            _id,
            value
        }
    }
}
`;

export const searchableFaq = async (
  sanityConfig: SanityClientConfig
): Promise<PageSearchDocImplResult> => {
  const queryResult = await queryDocs<GroqQueryFaqResult>(
    sanityConfig,
    GROQ_QUERY_FAQ
  );

  if (queryResult === 'error') {
    console.error('Query failed for searchableFaq');
    return 'error';
  }

  const nnDocuments: PageSearchDocument[] = [];
  const enDocuments: PageSearchDocument[] = [];
  const { faqs, faqPages } = queryResult;
  faqs.forEach((faq) => {
    const _path = path(faq, faqPages);
    if (!_path) {
      return;
    }

    const nn: PageSearchDocument = {
      title: faq.question?.nn || '',
      path: _path,
      subtitles: [],
      description: description(faq, 'nn'),
      searchKeywords: [],
      hideInternalSearch: false,
    };
    if (nn.title) nnDocuments.push(nn);

    const en = {
      ...nn,
      title: faq.question?.en || '',
      description: description(faq, 'en'),
    };
    if (en.title) enDocuments.push(en);
  });

  return {
    nnDocuments,
    enDocuments,
  };
};
