import { SearchTheme } from 'styled-components';
import { COLORS } from '@ssfrepo/ssf-react-components';

export const searchLightThemeImpl: SearchTheme = {
  modalBgAndAfterBottomBorder: COLORS.ssfMenuBackgroundDesktop,
  searchInputAndModalButtonBg: COLORS.ssfGrey100,
  searchInputAndModalButtonFg: COLORS.ssfBlue500,
  searchInputAndModalButtonBorder: COLORS.ssfBlue400,
  searchInputCaretColor: COLORS.ssfBlue500,
  searchInputPlaceholderColor: COLORS.ssfGrey300,
  searchResultHeader: COLORS.ssfBlue500,
  searchResultParagraph: COLORS.ssfGrey500,
  searchResultLocationParagraph: COLORS.ssfGrey400,
};
