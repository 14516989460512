import styled, { css } from 'styled-components';
import { mediaBreakPoints } from '/src/sass/styled-constants';
import FontIcon from '@ssfrepo/ssf-react-components/lib/buttons/font-icon';
import { SanityBigLinkSetLayout } from '/src/types/sanity/core/sanity-big-link';

export const BigLinksContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;

  :not(:first-child) {
    margin-top: 20px;
    @media (max-width: ${mediaBreakPoints.sm}) {
      margin-top: 10px;
    }
  }
`;

export const BigLinkSetContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  flex-grow: 1;
`;

export const WrappedBoxDefaultMargins = css`
  :not(:first-child) {
    margin-left: 20px;
  }
  @media (max-width: ${mediaBreakPoints.sm}) {
    :not(:first-child) {
      margin-left: 10px;
    }
  }
`;

export const WrappedBoxDefaultSizing = css`
  box-sizing: border-box;
  text-decoration: none;
  text-align: center;
  width: 220px;
  min-height: 115px;
  flex-shrink: 0;
  padding: 10px;
  @media (max-width: ${mediaBreakPoints.sm}) {
    width: 165px;
    padding: 10px 5px 5px 5px;
  }
  @media (max-width: ${mediaBreakPoints.galaxy4}) {
    width: 150px;
  }
`;

export const BigLinkIcon = styled(FontIcon)<{
  $styleVariant: SanityBigLinkSetLayout;
}>`
  color: ${(p) => p.theme.bigLink[p.$styleVariant].fg} !important;
  font-size: ${(p) => p.theme.bigLink[p.$styleVariant].iconsize} !important;
`;

export const HeaderStyles = css<{
  $styleVariant: SanityBigLinkSetLayout;
}>`
  color: ${(p) => p.theme.bigLink[p.$styleVariant].header} !important;
  white-space: normal;
`;
