import React from 'react';
import get from 'lodash.get';
import PriceLine from '/src/domains/pricing/price-line';
import { SanityProduct } from '@ssfrepo/ssf-sanity-utils';

export const PriceReference = (props) => {
  const product: SanityProduct = get(props, 'mark.reference');
  if (!product) {
    return <span>--</span>;
  }

  return <PriceLine product={product} />;
};
